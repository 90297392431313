import React, { useState, useEffect, useRef, useCallback } from 'react';
//import colorLogo from './assets/color.png';
//import darkLogo from './assets/dark.png';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import * as microsoftTeams from "@microsoft/teams-js";


// Constants
const SPEECH_KEY = '9qBhM45nuEmfzTDDTyJFSl0j7e8f317z9z3BUxTrbhD1qL4H8dFMJQQJ99AJAC5RqLJXJ3w3AAAYACOGfJHi';
const SPEECH_REGION = 'westeurope';
const TRANSLATOR_KEY = 'AAzgUJTaz9kanUktd6RBCeLugLFAwcV450bH2Ho2xsq4potwPzhaJQQJ99AJAC5RqLJXJ3w3AAAbACOG4sRG';
const TRANSLATOR_REGION = 'westeurope';
const API_BASE_URL = 'https://cscvoiceapi.azurewebsites.net';
const DISPLAY_DURATION = 30000;
const DEFAULT_USER_NAME = 'Misafir Kullanıci';

// Sektör ve Departman Bilgileri 
const INDUSTRY_SECTORS_TRANSLATIONS = {
  'tr-TR': {
    makeChoice: 'Seçim Yapınız',
    technology: 'Teknoloji',
    finance: 'Finans',
    healthcare: 'Sağlık',
    education: 'Eğitim',
    manufacturing: 'Üretim',
    retail: 'Perakende',
    consulting: 'Danışmanlık',
    logistics: 'Lojistik'
  },
  'en-US': {
    makeChoice: 'Make a choice',
    technology: 'Technology',
    finance: 'Finance',
    healthcare: 'Healthcare',
    education: 'Education',
    manufacturing: 'Manufacturing',
    retail: 'Retail',
    consulting: 'Consulting',
    logistics: 'Logistics'
  },
  'fr-FR': {
    makeChoice: 'Faites un choix',
    technology: 'Technologie',
    finance: 'Finance',
    healthcare: 'Santé',
    education: 'Éducation',
    manufacturing: 'Fabrication',
    retail: 'Commerce de détail',
    consulting: 'Conseil',
    logistics: 'Logistique'
  },
  'de-DE': {
    makeChoice: 'Treffen Sie eine Wahl',
    technology: 'Technologie',
    finance: 'Finanzen',
    healthcare: 'Gesundheitswesen',
    education: 'Bildung',
    manufacturing: 'Produktion',
    retail: 'Einzelhandel',
    consulting: 'Beratung',
    logistics: 'Logistik'
  },
  'es-ES': {
    makeChoice: 'Haga una elección',
    technology: 'Tecnología',
    finance: 'Finanzas',
    healthcare: 'Salud',
    education: 'Educación',
    manufacturing: 'Fabricación',
    retail: 'Comercio minorista',
    consulting: 'Consultoría',
    logistics: 'Logística'
  },
  'it-IT': {
    makeChoice: 'Fai una scelta',
    technology: 'Tecnologia',
    finance: 'Finanza',
    healthcare: 'Sanità',
    education: 'Istruzione',
    manufacturing: 'Produzione',
    retail: 'Vendita al dettaglio',
    consulting: 'Consulenza',
    logistics: 'Logistica'
  },
  'ru-RU': {
    makeChoice: 'Сделайте выбор',
    technology: 'Технологии',
    finance: 'Финансы',
    healthcare: 'Здравоохранение',
    education: 'Образование',
    manufacturing: 'Производство',
    retail: 'Розничная торговля',
    consulting: 'Консалтинг',
    logistics: 'Логистика'
  },
  'zh-CN': {
    makeChoice: '做出选择',
    technology: '技术',
    finance: '金融',
    healthcare: '医疗保健',
    education: '教育',
    manufacturing: '制造业',
    retail: '零售',
    consulting: '咨询',
    logistics: '物流'
  },
  'ja-JP': {
    makeChoice: '選択してください',
    technology: 'テクノロジー',
    finance: '金融',
    healthcare: '医療',
    education: '教育',
    manufacturing: '製造',
    retail: '小売',
    consulting: 'コンサルティング',
    logistics: '物流'
  },
  'ar-SA': {
    makeChoice: 'قم بالاختيار',
    technology: 'تقنية',
    finance: 'مالية',
    healthcare: 'رعاية صحية',
    education: 'تعليم',
    manufacturing: 'تصنيع',
    retail: 'تجزئة',
    consulting: 'استشارات',
    logistics: 'خدمات لوجستية'
  },
  'pt-PT': {
    makeChoice: 'Faça uma escolha',
    technology: 'Tecnologia',
    finance: 'Finanças',
    healthcare: 'Saúde',
    education: 'Educação',
    manufacturing: 'Fabricação',
    retail: 'Varejo',
    consulting: 'Consultoria',
    logistics: 'Logística'
  },
  'el-GR': {
    makeChoice: 'Κάντε μια επιλογή',
    technology: 'Τεχνολογία',
    finance: 'Οικονομικά',
    healthcare: 'Υγειονομική περίθαλψη',
    education: 'Εκπαίδευση',
    manufacturing: 'Παραγωγή',
    retail: 'Λιανική',
    consulting: 'Συμβουλευτική',
    logistics: 'Εφοδιαστική'
  },
  'he-IL': {
    makeChoice: 'בחר אפשרות',
    technology: 'טכנולוגיה',
    finance: 'פיננסים',
    healthcare: 'בריאות',
    education: 'חינוך',
    manufacturing: 'ייצור',
    retail: 'קמעונאות',
    consulting: 'ייעוץ',
    logistics: 'לוגיסטיקה'
  },
  'bg-BG': {
    makeChoice: 'Направете избор',
    technology: 'Технологии',
    finance: 'Финанси',
    healthcare: 'Здравеопазване',
    education: 'Образование',
    manufacturing: 'Производство',
    retail: 'Търговия на дребно',
    consulting: 'Консултиране',
    logistics: 'Логистика'
  },
  'cs-CZ': {
    makeChoice: 'Proveďte výběr',
    technology: 'Technologie',
    finance: 'Finance',
    healthcare: 'Zdravotnictví',
    education: 'Vzdělávání',
    manufacturing: 'Výroba',
    retail: 'Maloobchod',
    consulting: 'Poradenství',
    logistics: 'Logistika'
  },
  'da-DK': {
    makeChoice: 'Foretag et valg',
    technology: 'Teknologi',
    finance: 'Finans',
    healthcare: 'Sundhedspleje',
    education: 'Uddannelse',
    manufacturing: 'Fremstilling',
    retail: 'Detail',
    consulting: 'Rådgivning',
    logistics: 'Logistik'
  },
  'fi-FI': {
    makeChoice: 'Tee valinta',
    technology: 'Teknologia',
    finance: 'Rahoitus',
    healthcare: 'Terveydenhuolto',
    education: 'Koulutus',
    manufacturing: 'Valmistus',
    retail: 'Vähittäiskauppa',
    consulting: 'Konsultointi',
    logistics: 'Logistiikka'
  },
  'hu-HU': {
    makeChoice: 'Válasszon',
    technology: 'Technológia',
    finance: 'Pénzügy',
    healthcare: 'Egészségügy',
    education: 'Oktatás',
    manufacturing: 'Gyártás',
    retail: 'Kiskereskedelem',
    consulting: 'Tanácsadás',
    logistics: 'Logisztika'
  },
  'ko-KR': {
    makeChoice: '선택하세요',
    technology: '기술',
    finance: '금융',
    healthcare: '의료',
    education: '교육',
    manufacturing: '제조',
    retail: '소매',
    consulting: '컨설팅',
    logistics: '물류'
  },
  'ms-MY': {
    makeChoice: 'Buat pilihan',
    technology: 'Teknologi',
    finance: 'Kewangan',
    healthcare: 'Penjagaan Kesihatan',
    education: 'Pendidikan',
    manufacturing: 'Pembuatan',
    retail: 'Peruncitan',
    consulting: 'Perundingan',
    logistics: 'Logistik'
  },
  'pl-PL': {
    makeChoice: 'Dokonaj wyboru',
    technology: 'Technologia',
    finance: 'Finanse',
    healthcare: 'Ochrona zdrowia',
    education: 'Edukacja',
    manufacturing: 'Produkcja',
    retail: 'Handel detaliczny',
    consulting: 'Doradztwo',
    logistics: 'Logistyka'
  },
  'ro-RO': {
    makeChoice: 'Faceți o alegere',
    technology: 'Tehnologie',
    finance: 'Finanțe',
    healthcare: 'Sănătate',
    education: 'Educație',
    manufacturing: 'Producție',
    retail: 'Comerț cu amănuntul',
    consulting: 'Consultanță',
    logistics: 'Logistică'
  },
  'sv-SE': {
    makeChoice: 'Gör ett val',
    technology: 'Teknologi',
    finance: 'Finans',
    healthcare: 'Sjukvård',
    education: 'Utbildning',
    manufacturing: 'Tillverkning',
    retail: 'Detaljhandel',
    consulting: 'Konsulting',
    logistics: 'Logistik'
  },
  'th-TH': {
    makeChoice: 'เลือกตัวเลือก',
    technology: 'เทคโนโลยี',
    finance: 'การเงิน',
    healthcare: 'การดูแลสุขภาพ',
    education: 'การศึกษา',
    manufacturing: 'การผลิต',
    retail: 'ค้าปลีก',
    consulting: 'ที่ปรึกษา',
    logistics: 'โลจิสติกส์'
  },
  'uk-UA': {
    makeChoice: 'Зробіть вибір',
    technology: 'Технології',
    finance: 'Фінанси',
    healthcare: 'Охорона здоров\'я',
    education: 'Освіта',
    manufacturing: 'Виробництво',
    retail: 'Роздрібна торгівля',
    consulting: 'Консалтинг',
    logistics: 'Логістика'
  },
  'vi-VN': {
    makeChoice: 'Hãy lựa chọn',
    technology: 'Công nghệ',
    finance: 'Tài chính',
    healthcare: 'Chăm sóc sức khỏe',
    education: 'Giáo dục',
    manufacturing: 'Sản xuất',
    retail: 'Bán lẻ',
    consulting: 'Tư vấn',
    logistics: 'Hậu cần'
  }
};

const getLocalizedIndustrySectors = (language) => {
  const translations = INDUSTRY_SECTORS_TRANSLATIONS[language] || INDUSTRY_SECTORS_TRANSLATIONS['en-US'];
  
  return [
    { id: 'technology', name: translations.makeChoice, icon: '💻' },
    { id: 'technology', name: translations.technology, icon: '💻' },
    { id: 'finance', name: translations.finance, icon: '💰' },
    { id: 'healthcare', name: translations.healthcare, icon: '🏥' },
    { id: 'education', name: translations.education, icon: '🎓' },
    { id: 'manufacturing', name: translations.manufacturing, icon: '🏭' },
    { id: 'retail', name: translations.retail, icon: '🛒' },
    { id: 'consulting', name: translations.consulting, icon: '📊' },
    { id: 'logistics', name: translations.logistics, icon: '🚚' }
  ];
};

const getIndustrySectorInfo = (industryId, language) => {
  const sectors = getLocalizedIndustrySectors(language);
  const sector = sectors.find(s => s.id === industryId);
  return {
    icon: sector?.icon || '🏢',
    name: sector?.name || industryId
  };
};

const DEPARTMENTS_TRANSLATIONS = {
  'tr-TR': {
    makeChoice: 'Seçim Yapınız',
    executive: 'Yönetici',
    sales: 'Satış',
    hr: 'İK',
    it: 'BT',
    rd: 'Ar-Ge'
  },
  'en-US': {
    makeChoice: 'Make a choice',
    executive: 'Executive',
    sales: 'Sales',
    hr: 'HR',
    it: 'IT',
    rd: 'R-D'
  },
  'fr-FR': {
    makeChoice: 'Faites un choix',
    executive: 'Exécutif',
    sales: 'Ventes',
    hr: 'RH',
    it: 'IT',
    rd: 'R&D'
  },
  'de-DE': {
    makeChoice: 'Treffen Sie eine Wahl',
    executive: 'Führungskraft',
    sales: 'Vertrieb',
    hr: 'Personal',
    it: 'IT',
    rd: 'F&E'
  },
  'es-ES': {
    makeChoice: 'Haga una elección',
    executive: 'Ejecutivo',
    sales: 'Ventas',
    hr: 'RRHH',
    it: 'TI',
    rd: 'I+D'
  },
  'it-IT': {
    makeChoice: 'Fai una scelta',
    executive: 'Dirigente',
    sales: 'Vendite',
    hr: 'HR',
    it: 'IT',
    rd: 'R&S'
  },
  'ru-RU': {
    makeChoice: 'Сделайте выбор',
    executive: 'Руководитель',
    sales: 'Продажи',
    hr: 'HR',
    it: 'ИТ',
    rd: 'НИОКР'
  },
  'zh-CN': {
    makeChoice: '做出选择',
    executive: '行政',
    sales: '销售',
    hr: '人力资源',
    it: '信息技术',
    rd: '研发'
  },
  'ja-JP': {
    makeChoice: '選択してください',
    executive: '役員',
    sales: '営業',
    hr: '人事',
    it: 'IT',
    rd: '研究開発'
  },
  'ar-SA': {
    makeChoice: 'قم بالاختيار',
    executive: 'تنفيذي',
    sales: 'مبيعات',
    hr: 'موارد بشرية',
    it: 'تقنية المعلومات',
    rd: 'البحث والتطوير'
  },
  'pt-PT': {
    makeChoice: 'Faça uma escolha',
    executive: 'Executivo',
    sales: 'Vendas',
    hr: 'RH',
    it: 'TI',
    rd: 'P&D'
  },
  'el-GR': {
    makeChoice: 'Κάντε μια επιλογή',
    executive: 'Διοικητικό',
    sales: 'Πωλήσεις',
    hr: 'Ανθρώπινο Δυναμικό',
    it: 'Πληροφορική',
    rd: 'Έρευνα & Ανάπτυξη'
  },
  'he-IL': {
    makeChoice: 'בחר אפשרות',
    executive: 'הנהלה',
    sales: 'מכירות',
    hr: 'משאבי אנוש',
    it: 'מחשוב',
    rd: 'מחקר ופיתוח'
  },
  'bg-BG': {
    makeChoice: 'Направете избор',
    executive: 'Ръководство',
    sales: 'Продажби',
    hr: 'Човешки ресурси',
    it: 'ИТ',
    rd: 'НИРД'
  },
  'cs-CZ': {
    makeChoice: 'Proveďte výběr',
    executive: 'Vedení',
    sales: 'Prodej',
    hr: 'Lidské zdroje',
    it: 'IT',
    rd: 'Výzkum a vývoj'
  },
  'da-DK': {
    makeChoice: 'Foretag et valg',
    executive: 'Ledelse',
    sales: 'Salg',
    hr: 'HR',
    it: 'IT',
    rd: 'F&U'
  },
  'fi-FI': {
    makeChoice: 'Tee valinta',
    executive: 'Johto',
    sales: 'Myynti',
    hr: 'Henkilöstö',
    it: 'IT',
    rd: 'T&K'
  },
  'hu-HU': {
    makeChoice: 'Válasszon',
    executive: 'Vezetőség',
    sales: 'Értékesítés',
    hr: 'HR',
    it: 'IT',
    rd: 'K+F'
  },
  'ko-KR': {
    makeChoice: '선택하세요',
    executive: '경영진',
    sales: '영업',
    hr: '인사',
    it: 'IT',
    rd: '연구개발'
  },
  'ms-MY': {
    makeChoice: 'Buat pilihan',
    executive: 'Eksekutif',
    sales: 'Jualan',
    hr: 'Sumber Manusia',
    it: 'IT',
    rd: 'R&D'
  },
  'pl-PL': {
    makeChoice: 'Dokonaj wyboru',
    executive: 'Zarząd',
    sales: 'Sprzedaż',
    hr: 'Kadry',
    it: 'IT',
    rd: 'B+R'
  },
  'ro-RO': {
    makeChoice: 'Faceți o alegere',
    executive: 'Executiv',
    sales: 'Vânzări',
    hr: 'Resurse Umane',
    it: 'IT',
    rd: 'C&D'
  },
  'sv-SE': {
    makeChoice: 'Gör ett val',
    executive: 'Ledning',
    sales: 'Försäljning',
    hr: 'HR',
    it: 'IT',
    rd: 'FoU'
  },
  'th-TH': {
    makeChoice: 'เลือกตัวเลือก',
    executive: 'ผู้บริหาร',
    sales: 'ขาย',
    hr: 'ทรัพยากรบุคคล',
    it: 'ไอที',
    rd: 'วิจัยและพัฒนา'
  },
  'uk-UA': {
    makeChoice: 'Зробіть вибір',
    executive: 'Керівництво',
    sales: 'Продажі',
    hr: 'Кадри',
    it: 'ІТ',
    rd: 'НДДКР'
  },
  'vi-VN': {
    makeChoice: 'Hãy lựa chọn',
    executive: 'Ban điều hành',
    sales: 'Kinh doanh',
    hr: 'Nhân sự',
    it: 'CNTT',
    rd: 'R&D'
  }
};

const getLocalizedDepartments = (language) => {
  const translations = DEPARTMENTS_TRANSLATIONS[language] || DEPARTMENTS_TRANSLATIONS['en-US'];
  
  return [
    { id: 'executive', name: translations.makeChoice, icon: '👑' },
    { id: 'executive', name: translations.executive, icon: '👑' },
    { id: 'sales', name: translations.sales, icon: '🤝' },
    { id: 'hr', name: translations.hr, icon: '👥' },
    { id: 'it', name: translations.it, icon: '🖥️' },
    { id: 'rd', name: translations.rd, icon: '🔬' }
  ];
};

const getDepartmentInfo = (departmentId, language) => {
  const departments = getLocalizedDepartments(language);
  const department = departments.find(d => d.id === departmentId);
  return {
    icon: department?.icon || '👥',
    name: department?.name || departmentId
  };
};

const SUPPORTED_LANGUAGES = [
  { 
    code: 'tr-TR', 
    name: 'Türkçe', 
    shortCode: 'tr',
    icon: '🇹🇷',
    phraseList: ['merhaba', 'günaydın', 'iyi akşamlar']
  },
  { 
    code: 'en-US', 
    name: 'İngilizce', 
    shortCode: 'en',
    icon: '🇺🇸',
    phraseList: ['hello', 'good morning', 'good evening']
  },
  { 
    code: 'ru-RU', 
    name: 'Rusça', 
    shortCode: 'ru',
    icon: '🇷🇺',
    phraseList: ['привет', 'доброе утро', 'добрый вечер']
  },
  { 
    code: 'es-ES', 
    name: 'İspanyolca', 
    shortCode: 'es',
    icon: '🇪🇸',
    phraseList: ['hola', 'buenos días', 'buenas noches']
  },
  { 
    code: 'it-IT', 
    name: 'İtalyanca', 
    shortCode: 'it',
    icon: '🇮🇹',
    phraseList: ['ciao', 'buongiorno', 'buonasera']
  },
  { 
    code: 'de-DE', 
    name: 'Almanca', 
    shortCode: 'de',
    icon: '🇩🇪',
    phraseList: ['hallo', 'guten Morgen', 'guten Abend']
  },
  { 
    code: 'pt-PT', 
    name: 'Portekizce', 
    shortCode: 'pt',
    icon: '🇵🇹',
    phraseList: ['olá', 'bom dia', 'boa noite']
  },
  { 
    code: 'zh-CN', 
    name: 'Çince', 
    shortCode: 'zh',
    icon: '🇨🇳',
    phraseList: ['你好', '早上好', '晚上好']
  },
  { 
    code: 'he-IL', 
    name: 'İbranice', 
    shortCode: 'he',
    icon: '🇮🇱',
    phraseList: ['שלום', 'בוקר טוב', 'ערב טוב']
  },
  { 
    code: 'fr-FR', 
    name: 'Fransızca', 
    shortCode: 'fr',
    icon: '🇫🇷',
    phraseList: ['bonjour', 'bonsoir', 'bonne nuit']
  },
  { 
    code: 'el-GR', 
    name: 'Yunanca', 
    shortCode: 'el',
    icon: '🇬🇷',
    phraseList: ['γειά', 'καλημέρα', 'καλησπέρα']
  },
  { 
    code: 'ar-SA', 
    name: 'Arapça', 
    shortCode: 'ar',
    icon: '🇸🇦',
    phraseList: ['مرحباً', 'صباح الخير', 'مساء الخير']
  },
  { 
    code: 'bg-BG', 
    name: 'Bulgarca', 
    shortCode: 'bg',
    icon: '🇧🇬',
    phraseList: ['здравейте', 'добро утро', 'добър вечер']
  },
  { 
    code: 'cs-CZ', 
    name: 'Çekçe', 
    shortCode: 'cs',
    icon: '🇨🇿',
    phraseList: ['ahoj', 'dobré ráno', 'dobrý večer']
  },
  { 
    code: 'da-DK', 
    name: 'Danca', 
    shortCode: 'da',
    icon: '🇩🇰',
    phraseList: ['hej', 'godmorgen', 'god aften']
  },
  { 
    code: 'fi-FI', 
    name: 'Fince', 
    shortCode: 'fi',
    icon: '🇫🇮',
    phraseList: ['hei', 'hyvää huomenta', 'hyvää iltaa']
  },
  { 
    code: 'hu-HU', 
    name: 'Macarca', 
    shortCode: 'hu',
    icon: '🇭🇺',
    phraseList: ['helló', 'jó reggelt', 'jó estét']
  },
  { 
    code: 'ja-JP', 
    name: 'Japonca', 
    shortCode: 'ja',
    icon: '🇯🇵',
    phraseList: ['こんにちは', 'おはよう', 'こんばんは']
  },
  { 
    code: 'ko-KR', 
    name: 'Korece', 
    shortCode: 'ko',
    icon: '🇰🇷',
    phraseList: ['안녕하세요', '좋은 아침', '좋은 저녁']
  },
  { 
    code: 'pl-PL', 
    name: 'Lehçe', 
    shortCode: 'pl',
    icon: '🇵🇱',
    phraseList: ['cześć', 'dzień dobry', 'dobry wieczór']
  },
  { 
    code: 'ro-RO', 
    name: 'Rumence', 
    shortCode: 'ro',
    icon: '🇷🇴',
    phraseList: ['bună', 'bună dimineața', 'bună seara']
  },
  { 
    code: 'sv-SE', 
    name: 'İsveççe', 
    shortCode: 'sv',
    icon: '🇸🇪',
    phraseList: ['hej', 'god morgon', 'god kväll']
  },
  { 
    code: 'uk-UA', 
    name: 'Ukraynaca', 
    shortCode: 'uk',
    icon: '🇺🇦',
    phraseList: ['привіт', 'добрий ранок', 'добрий вечір']
  },
  { 
    code: 'vi-VN', 
    name: 'Vietnamca', 
    shortCode: 'vi',
    icon: '🇻🇳',
    phraseList: ['xin chào', 'chào buổi sáng', 'chào buổi tối']
  },
  { 
    code: 'th-TH', 
    name: 'Taylandca', 
    shortCode: 'th',
    icon: '🇹🇭',
    phraseList: ['สวัสดี', 'สวัสดีตอนเช้า', 'สวัสดีตอนเย็น']
  },
  { 
    code: 'ms-MY', 
    name: 'Malayca', 
    shortCode: 'ms',
    icon: '🇲🇾',
    phraseList: ['halo', 'selamat pagi', 'selamat petang']
  }
];

const TRANSLATIONSREPORT = {
  'ar-SA': {
  title: "تقرير الاجتماع",
  summary: "ملخص الاجتماع",
  generatedAt: "تم الإنشاء في",
  stats: {
    totalParticipants: "إجمالي المشاركين",
    totalMessages: "إجمالي الرسائل",
    totalWords: "إجمالي الكلمات",
    duration: "مدة الاجتماع",
    minutes: "دقيقة"
  },
  participants: {
    title: "تفاصيل المشاركين",
    columns: {
      name: "المشارك",
      messageCount: "عدد الرسائل",
      wordCount: "عدد الكلمات",
      participation: "نسبة المشاركة"
    }
  },
  mood: {
    title: "أجواء الاجتماع",
    overall: "الحالة العامة",
    ambiance: "أجواء الاجتماع",
    reactions: "ردود الفعل المستخدمة",
    positive: "إيجابي",
    neutral: "محايد",
    negative: "سلبي",
    perParticipant: "الحالة لكل مشارك",
    keywords: "العبارات البارزة"
  },
  footer: {
    generatedBy: "تم إنشاء هذا التقرير تلقائيًا بواسطة CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'bg-BG': {
  title: "Доклад от срещата",
  summary: "Обобщение на срещата",
  generatedAt: "Генерирано на",
  stats: {
    totalParticipants: "Общо участници",
    totalMessages: "Общо съобщения",
    totalWords: "Общо думи",
    duration: "Продължителност на срещата",
    minutes: "минути"
  },
  participants: {
    title: "Детайли за участниците",
    columns: {
      name: "Участник",
      messageCount: "Брой съобщения",
      wordCount: "Брой думи",
      participation: "Степен на участие"
    }
  },
  mood: {
    title: "Атмосфера на срещата",
    overall: "Общо настроение",
    ambiance: "Атмосфера на срещата",
    reactions: "Използвани реакции",
    positive: "Позитивно",
    neutral: "Неутрално",
    negative: "Негативно",
    perParticipant: "Настроение по участници",
    keywords: "Подчертани изрази"
  },
  footer: {
    generatedBy: "Този доклад е автоматично генериран от CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'cs-CZ': {
  title: "Zpráva ze schůzky",
  summary: "Shrnutí schůzky",
  generatedAt: "Vytvořeno",
  stats: {
    totalParticipants: "Celkem účastníků",
    totalMessages: "Celkem zpráv",
    totalWords: "Celkem slov",
    duration: "Doba trvání schůzky",
    minutes: "minut"
  },
  participants: {
    title: "Detaily účastníků",
    columns: {
      name: "Účastník",
      messageCount: "Počet zpráv",
      wordCount: "Počet slov",
      participation: "Míra účasti"
    }
  },
  mood: {
    title: "Atmosféra schůzky",
    overall: "Celková nálada",
    ambiance: "Atmosféra schůzky",
    reactions: "Použité reakce",
    positive: "Pozitivní",
    neutral: "Neutrální",
    negative: "Negativní",
    perParticipant: "Nálada podle účastníků",
    keywords: "Zvýrazněné výrazy"
  },
  footer: {
    generatedBy: "Tato zpráva byla automaticky vygenerována pomocí CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'da-DK': {
  title: "Møderefereat",
  summary: "Mødesammenfatning",
  generatedAt: "Genereret den",
  stats: {
    totalParticipants: "Samlet antal deltagere",
    totalMessages: "Samlet antal beskeder",
    totalWords: "Samlet antal ord",
    duration: "Mødets varighed",
    minutes: "minutter"
  },
  participants: {
    title: "Deltagerdetaljer",
    columns: {
      name: "Deltager",
      messageCount: "Antal beskeder",
      wordCount: "Antal ord",
      participation: "Deltagelsesgrad"
    }
  },
  mood: {
    title: "Mødeatmosfære",
    overall: "Samlet stemning",
    ambiance: "Mødeatmosfære",
    reactions: "Anvendte reaktioner",
    positive: "Positiv",
    neutral: "Neutral",
    negative: "Negativ",
    perParticipant: "Stemning pr. deltager",
    keywords: "Fremhævede udtryk"
  },
  footer: {
    generatedBy: "Denne rapport er automatisk genereret af CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'de-DE': {
  title: "Besprechungsbericht",
  summary: "Besprechungszusammenfassung",
  generatedAt: "Erstellt am",
  stats: {
    totalParticipants: "Gesamtteilnehmer",
    totalMessages: "Gesamtnachrichten",
    totalWords: "Gesamtwörter",
    duration: "Besprechungsdauer",
    minutes: "Minuten"
  },
  participants: {
    title: "Teilnehmerdetails",
    columns: {
      name: "Teilnehmer",
      messageCount: "Nachrichtenanzahl",
      wordCount: "Wortanzahl",
      participation: "Teilnahmequote"
    }
  },
  mood: {
    title: "Besprechungsatmosphäre",
    overall: "Gesamtstimmung",
    ambiance: "Besprechungsambiente",
    reactions: "Verwendete Reaktionen",
    positive: "Positiv",
    neutral: "Neutral",
    negative: "Negativ",
    perParticipant: "Stimmung pro Teilnehmer",
    keywords: "Hervorgehobene Ausdrücke"
  },
  footer: {
    generatedBy: "Dieser Bericht wurde automatisch von CSC Voice AI generiert",
    copyright: "© CSC Bilisim"
  }
},
  'el-GR': {
    title: "Αναφορά Συνάντησης",
    summary: "Περίληψη Συνάντησης",
    generatedAt: "Δημιουργήθηκε στις",
    stats: {
      totalParticipants: "Συνολικοί Συμμετέχοντες",
      totalMessages: "Συνολικά Μηνύματα",
      totalWords: "Συνολικές Λέξεις",
      duration: "Διάρκεια Συνάντησης",
      minutes: "λεπτά"
    },
    participants: {
      title: "Λεπτομέρειες Συμμετεχόντων",
      columns: {
        name: "Συμμετέχων",
        messageCount: "Αριθμός Μηνυμάτων",
        wordCount: "Αριθμός Λέξεων",
        participation: "Ποσοστό Συμμετοχής"
      }
    },
    mood: {
      title: "Ατμόσφαιρα Συνάντησης",
      overall: "Συνολική Διάθεση",
      ambiance: "Ατμόσφαιρα Συνάντησης",
      reactions: "Χρησιμοποιημένες Αντιδράσεις",
      positive: "Θετικό",
      neutral: "Ουδέτερο",
      negative: "Αρνητικό",
      perParticipant: "Διάθεση ανά Συμμετέχοντα",
      keywords: "Βασικές Εκφράσεις"
    },
    footer: {
      generatedBy: "Η αναφορά δημιουργήθηκε αυτόματα από το CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'en-US': {
    title: "Meeting Report",
    summary: "Meeting Summary",
    generatedAt: "Generated At",
    stats: {
      totalParticipants: "Total Participants",
      totalMessages: "Total Messages",
      totalWords: "Total Words",
      duration: "Meeting Duration",
      minutes: "minutes"
    },
    participants: {
      title: "Participant Details",
      columns: {
        name: "Participant",
        messageCount: "Message Count",
        wordCount: "Word Count",
        participation: "Participation Rate"
      }
    },
    mood: {
      title: "Meeting Mood",
      overall: "Overall Mood",
      ambiance: "Meeting Ambiance",
      reactions: "Used Reactions",
      positive: "Positive",
      neutral: "Neutral",
      negative: "Negative",
      perParticipant: "Mood Per Participant",
      keywords: "Highlighted Expressions"
    },
    footer: {
      generatedBy: "This report was automatically generated by CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'es-ES': {
    title: "Informe de la Reunión",
    summary: "Resumen de la Reunión",
    generatedAt: "Generado el",
    stats: {
      totalParticipants: "Total de Participantes",
      totalMessages: "Total de Mensajes",
      totalWords: "Total de Palabras",
      duration: "Duración de la Reunión",
      minutes: "minutos"
    },
    participants: {
      title: "Detalles de los Participantes",
      columns: {
        name: "Participante",
        messageCount: "Cantidad de Mensajes",
        wordCount: "Cantidad de Palabras",
        participation: "Porcentaje de Participación"
      }
    },
    mood: {
      title: "Ambiente de la Reunión",
      overall: "Estado General",
      ambiance: "Ambiente de la Reunión",
      reactions: "Reacciones Utilizadas",
      positive: "Positivo",
      neutral: "Neutral",
      negative: "Negativo",
      perParticipant: "Estado por Participante",
      keywords: "Expresiones Destacadas"
    },
    footer: {
      generatedBy: "Este informe fue generado automáticamente por CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'fi-FI': {
    title: "Kokousraportti",
    summary: "Kokouksen Yhteenveto",
    generatedAt: "Luotu",
    stats: {
      totalParticipants: "Osallistujien Määrä",
      totalMessages: "Viestien Määrä",
      totalWords: "Sanamäärä",
      duration: "Kokouksen Kesto",
      minutes: "minuuttia"
    },
    participants: {
      title: "Osallistujien Tiedot",
      columns: {
        name: "Osallistuja",
        messageCount: "Viestien Lukumäärä",
        wordCount: "Sanamäärä",
        participation: "Osallistumisaste"
      }
    },
    mood: {
      title: "Kokouksen Tunnelma",
      overall: "Yleinen Tunnelma",
      ambiance: "Kokouksen Ilmapiiri",
      reactions: "Käytetyt Reaktiot",
      positive: "Positiivinen",
      neutral: "Neutraali",
      negative: "Negatiivinen",
      perParticipant: "Tunnelma Osallistujittain",
      keywords: "Avainsanat"
    },
    footer: {
      generatedBy: "Tämä raportti luotiin automaattisesti CSC Voice AI:n avulla",
      copyright: "© CSC Bilisim"
    }
  },
  'fr-FR': {
    title: "Rapport de Réunion",
    summary: "Résumé de la Réunion",
    generatedAt: "Généré le",
    stats: {
      totalParticipants: "Participants au Total",
      totalMessages: "Messages au Total",
      totalWords: "Mots au Total",
      duration: "Durée de la Réunion",
      minutes: "minutes"
    },
    participants: {
      title: "Détails des Participants",
      columns: {
        name: "Participant",
        messageCount: "Nombre de Messages",
        wordCount: "Nombre de Mots",
        participation: "Taux de Participation"
      }
    },
    mood: {
      title: "Ambiance de la Réunion",
      overall: "Humeur Générale",
      ambiance: "Ambiance de la Réunion",
      reactions: "Réactions Utilisées",
      positive: "Positif",
      neutral: "Neutre",
      negative: "Négatif",
      perParticipant: "Humeur par Participant",
      keywords: "Expressions Clés"
    },
    footer: {
      generatedBy: "Ce rapport a été généré automatiquement par CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'he-IL': {
    title: "דו\"ח פגישה",
    summary: "סיכום פגישה",
    generatedAt: "נוצר ב",
    stats: {
      totalParticipants: "סה\"כ משתתפים",
      totalMessages: "סה\"כ הודעות",
      totalWords: "סה\"כ מילים",
      duration: "משך הפגישה",
      minutes: "דקות"
    },
    participants: {
      title: "פרטי משתתפים",
      columns: {
        name: "משתתף",
        messageCount: "מספר הודעות",
        wordCount: "מספר מילים",
        participation: "אחוז השתתפות"
      }
    },
    mood: {
      title: "אווירת הפגישה",
      overall: "מצב כללי",
      ambiance: "אווירה כללית",
      reactions: "תגובות בשימוש",
      positive: "חיובי",
      neutral: "נייטרלי",
      negative: "שלילי",
      perParticipant: "מצב לפי משתתף",
      keywords: "מילות מפתח"
    },
    footer: {
      generatedBy: "הדוח נוצר אוטומטית על ידי CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'hu-HU': {
    title: "Találkozói Jelentés",
    summary: "Találkozó Összefoglaló",
    generatedAt: "Létrehozva",
    stats: {
      totalParticipants: "Összes Résztvevő",
      totalMessages: "Összes Üzenet",
      totalWords: "Összes Szó",
      duration: "Találkozó Időtartama",
      minutes: "perc"
    },
    participants: {
      title: "Résztvevők Adatai",
      columns: {
        name: "Résztvevő",
        messageCount: "Üzenetek Száma",
        wordCount: "Szavak Száma",
        participation: "Részvételi Arány"
      }
    },
    mood: {
      title: "Találkozó Hangulata",
      overall: "Általános Hangulat",
      ambiance: "Találkozói Atmoszféra",
      reactions: "Használt Reakciók",
      positive: "Pozitív",
      neutral: "Semleges",
      negative: "Negatív",
      perParticipant: "Hangulat Résztvevőnként",
      keywords: "Kulcsszavak"
    },
    footer: {
      generatedBy: "Ezt a jelentést automatikusan készítette a CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'it-IT': {
    title: "Rapporto Riunione",
    summary: "Sommario della Riunione",
    generatedAt: "Generato il",
    stats: {
      totalParticipants: "Totale Partecipanti",
      totalMessages: "Totale Messaggi",
      totalWords: "Totale Parole",
      duration: "Durata della Riunione",
      minutes: "minuti"
    },
    participants: {
      title: "Dettagli Partecipanti",
      columns: {
        name: "Partecipante",
        messageCount: "Conteggio Messaggi",
        wordCount: "Conteggio Parole",
        participation: "Tasso di Partecipazione"
      }
    },
    mood: {
      title: "Atmosfera della Riunione",
      overall: "Umore Generale",
      ambiance: "Atmosfera della Riunione",
      reactions: "Reazioni Utilizzate",
      positive: "Positivo",
      neutral: "Neutro",
      negative: "Negativo",
      perParticipant: "Umore per Partecipante",
      keywords: "Espressioni Chiave"
    },
    footer: {
      generatedBy: "Questo rapporto è stato generato automaticamente da CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'ja-JP': {
    title: "会議レポート",
    summary: "会議の概要",
    generatedAt: "生成日",
    stats: {
      totalParticipants: "総参加者数",
      totalMessages: "総メッセージ数",
      totalWords: "総単語数",
      duration: "会議の時間",
      minutes: "分"
    },
    participants: {
      title: "参加者の詳細",
      columns: {
        name: "参加者",
        messageCount: "メッセージ数",
        wordCount: "単語数",
        participation: "参加率"
      }
    },
    mood: {
      title: "会議の雰囲気",
      overall: "全体的なムード",
      ambiance: "会議の雰囲気",
      reactions: "使用されたリアクション",
      positive: "ポジティブ",
      neutral: "ニュートラル",
      negative: "ネガティブ",
      perParticipant: "参加者ごとのムード",
      keywords: "重要な表現"
    },
    footer: {
      generatedBy: "このレポートはCSC Voice AIによって自動生成されました",
      copyright: "© CSC Bilisim"
    }
  },
  'ko-KR': {
    title: "회의 보고서",
    summary: "회의 요약",
    generatedAt: "생성일",
    stats: {
      totalParticipants: "총 참여자 수",
      totalMessages: "총 메시지 수",
      totalWords: "총 단어 수",
      duration: "회의 시간",
      minutes: "분"
    },
    participants: {
      title: "참여자 세부정보",
      columns: {
        name: "참여자",
        messageCount: "메시지 수",
        wordCount: "단어 수",
        participation: "참여율"
      }
    },
    mood: {
      title: "회의 분위기",
      overall: "전체 분위기",
      ambiance: "회의 분위기",
      reactions: "사용된 반응",
      positive: "긍정적",
      neutral: "중립적",
      negative: "부정적",
      perParticipant: "참여자별 분위기",
      keywords: "핵심 표현"
    },
    footer: {
      generatedBy: "이 보고서는 CSC Voice AI에 의해 자동 생성되었습니다",
      copyright: "© CSC Bilisim"
    }
  },
  'ms-MY': {
  title: "Laporan Mesyuarat",
  summary: "Ringkasan Mesyuarat",
  generatedAt: "Dijana pada",
  stats: {
    totalParticipants: "Jumlah Peserta",
    totalMessages: "Jumlah Mesej",
    totalWords: "Jumlah Perkataan",
    duration: "Tempoh Mesyuarat",
    minutes: "minit"
  },
  participants: {
    title: "Maklumat Peserta",
    columns: {
      name: "Peserta",
      messageCount: "Bilangan Mesej",
      wordCount: "Bilangan Perkataan",
      participation: "Kadar Penyertaan"
    }
  },
  mood: {
    title: "Suasana Mesyuarat",
    overall: "Keadaan Keseluruhan",
    ambiance: "Suasana Mesyuarat",
    reactions: "Reaksi Digunakan",
    positive: "Positif",
    neutral: "Neutral",
    negative: "Negatif",
    perParticipant: "Keadaan Mengikut Peserta",
    keywords: "Ungkapan Penting"
  },
  footer: {
    generatedBy: "Laporan ini dijana secara automatik oleh CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'pl-PL': {
  title: "Raport Spotkania",
  summary: "Podsumowanie Spotkania",
  generatedAt: "Wygenerowano dnia",
  stats: {
    totalParticipants: "Łączna liczba uczestników",
    totalMessages: "Łączna liczba wiadomości",
    totalWords: "Łączna liczba słów",
    duration: "Czas trwania spotkania",
    minutes: "minut"
  },
  participants: {
    title: "Szczegóły Uczestników",
    columns: {
      name: "Uczestnik",
      messageCount: "Liczba wiadomości",
      wordCount: "Liczba słów",
      participation: "Poziom Uczestnictwa"
    }
  },
  mood: {
    title: "Atmosfera Spotkania",
    overall: "Ogólny Nastrój",
    ambiance: "Atmosfera Spotkania",
    reactions: "Używane reakcje",
    positive: "Pozytywne",
    neutral: "Neutralne",
    negative: "Negatywne",
    perParticipant: "Nastrój według uczestnika",
    keywords: "Kluczowe frazy"
  },
  footer: {
    generatedBy: "Ten raport został wygenerowany automatycznie przez CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'pt-PT': {
  title: "Relatório da Reunião",
  summary: "Resumo da Reunião",
  generatedAt: "Gerado em",
  stats: {
    totalParticipants: "Total de Participantes",
    totalMessages: "Total de Mensagens",
    totalWords: "Total de Palavras",
    duration: "Duração da Reunião",
    minutes: "minutos"
  },
  participants: {
    title: "Detalhes dos Participantes",
    columns: {
      name: "Participante",
      messageCount: "Número de Mensagens",
      wordCount: "Número de Palavras",
      participation: "Taxa de Participação"
    }
  },
  mood: {
    title: "Clima da Reunião",
    overall: "Estado Geral",
    ambiance: "Clima da Reunião",
    reactions: "Reações Usadas",
    positive: "Positivo",
    neutral: "Neutro",
    negative: "Negativo",
    perParticipant: "Clima por Participante",
    keywords: "Frases Relevantes"
  },
  footer: {
    generatedBy: "Este relatório foi gerado automaticamente pelo CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'ro-RO': {
  title: "Raportul Întâlnirii",
  summary: "Rezumatul Întâlnirii",
  generatedAt: "Generat la",
  stats: {
    totalParticipants: "Total Participanți",
    totalMessages: "Total Mesaje",
    totalWords: "Total Cuvinte",
    duration: "Durata Întâlnirii",
    minutes: "minute"
  },
  participants: {
    title: "Detalii Participanți",
    columns: {
      name: "Participant",
      messageCount: "Număr Mesaje",
      wordCount: "Număr Cuvinte",
      participation: "Procent Participare"
    }
  },
  mood: {
    title: "Atmosfera Întâlnirii",
    overall: "Starea Generală",
    ambiance: "Atmosfera Întâlnirii",
    reactions: "Reacții Utilizate",
    positive: "Pozitiv",
    neutral: "Neutru",
    negative: "Negativ",
    perParticipant: "Starea pe Participanți",
    keywords: "Expresii Cheie"
  },
  footer: {
    generatedBy: "Acest raport a fost generat automat de CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
'ru-RU': {
  title: "Отчет о встрече",
  summary: "Сводка встречи",
  generatedAt: "Создано",
  stats: {
    totalParticipants: "Всего участников",
    totalMessages: "Всего сообщений",
    totalWords: "Всего слов",
    duration: "Длительность встречи",
    minutes: "минут"
  },
  participants: {
    title: "Детали участников",
    columns: {
      name: "Участник",
      messageCount: "Количество сообщений",
      wordCount: "Количество слов",
      participation: "Уровень участия"
    }
  },
  mood: {
    title: "Атмосфера встречи",
    overall: "Общее настроение",
    ambiance: "Атмосфера встречи",
    reactions: "Использованные реакции",
    positive: "Положительное",
    neutral: "Нейтральное",
    negative: "Отрицательное",
    perParticipant: "Настроение по участникам",
    keywords: "Ключевые фразы"
  },
  footer: {
    generatedBy: "Этот отчет был автоматически создан CSC Voice AI",
    copyright: "© CSC Bilisim"
  }
},
  'sv-SE': {
    title: "Mötesrapport",
    summary: "Mötessammanfattning",
    generatedAt: "Genererat den",
    stats: {
      totalParticipants: "Totalt antal deltagare",
      totalMessages: "Totalt antal meddelanden",
      totalWords: "Totalt antal ord",
      duration: "Mötets längd",
      minutes: "minuter"
    },
    participants: {
      title: "Deltagardetaljer",
      columns: {
        name: "Deltagare",
        messageCount: "Antal meddelanden",
        wordCount: "Antal ord",
        participation: "Deltagandenivå"
      }
    },
    mood: {
      title: "Mötesatmosfär",
      overall: "Övergripande stämning",
      ambiance: "Mötesatmosfär",
      reactions: "Använda reaktioner",
      positive: "Positiv",
      neutral: "Neutral",
      negative: "Negativ",
      perParticipant: "Stämning per deltagare",
      keywords: "Viktiga fraser"
    },
    footer: {
      generatedBy: "Den här rapporten genererades automatiskt av CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'th-TH': {
    title: "รายงานการประชุม",
    summary: "สรุปการประชุม",
    generatedAt: "สร้างเมื่อ",
    stats: {
      totalParticipants: "จำนวนผู้เข้าร่วมทั้งหมด",
      totalMessages: "จำนวนข้อความทั้งหมด",
      totalWords: "จำนวนคำทั้งหมด",
      duration: "ระยะเวลาการประชุม",
      minutes: "นาที"
    },
    participants: {
      title: "รายละเอียดผู้เข้าร่วม",
      columns: {
        name: "ผู้เข้าร่วม",
        messageCount: "จำนวนข้อความ",
        wordCount: "จำนวนคำ",
        participation: "ระดับการมีส่วนร่วม"
      }
    },
    mood: {
      title: "บรรยากาศการประชุม",
      overall: "ภาพรวมอารมณ์",
      ambiance: "บรรยากาศการประชุม",
      reactions: "ปฏิกิริยาที่ใช้",
      positive: "เชิงบวก",
      neutral: "เป็นกลาง",
      negative: "เชิงลบ",
      perParticipant: "อารมณ์ตามผู้เข้าร่วม",
      keywords: "คำสำคัญ"
    },
    footer: {
      generatedBy: "รายงานนี้สร้างขึ้นโดยอัตโนมัติโดย CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'tr-TR': {
    title: "Toplantı Raporu",
    summary: "Toplantı Özeti",
    generatedAt: "Oluşturulma Tarihi",
    stats: {
      totalParticipants: "Toplam Katılımcı",
      totalMessages: "Toplam Mesaj",
      totalWords: "Toplam Kelime",
      duration: "Toplantı Süresi",
      minutes: "dakika"
    },
    participants: {
      title: "Katılımcı Detayları",
      columns: {
        name: "Katılımcı",
        messageCount: "Mesaj Sayısı",
        wordCount: "Kelime Sayısı",
        participation: "Katılım Oranı"
      }
    },
    mood: {
      title: "Toplantı Atmosferi",
      overall: "Genel Durum",
      ambiance: "Toplantı Atmosferi",
      reactions: "Kullanılan Tepkiler",
      positive: "Pozitif",
      neutral: "Nötr",
      negative: "Negatif",
      perParticipant: "Katılımcıya Göre Durum",
      keywords: "Anahtar İfadeler"
    },
    footer: {
      generatedBy: "Bu rapor CSC Voice AI tarafından otomatik olarak oluşturulmuştur",
      copyright: "© CSC Bilisim"
    }
  },
  'uk-UA': {
    title: "Звіт про зустріч",
    summary: "Резюме зустрічі",
    generatedAt: "Згенеровано",
    stats: {
      totalParticipants: "Загальна кількість учасників",
      totalMessages: "Загальна кількість повідомлень",
      totalWords: "Загальна кількість слів",
      duration: "Тривалість зустрічі",
      minutes: "хвилин"
    },
    participants: {
      title: "Деталі учасників",
      columns: {
        name: "Учасник",
        messageCount: "Кількість повідомлень",
        wordCount: "Кількість слів",
        participation: "Рівень участі"
      }
    },
    mood: {
      title: "Атмосфера зустрічі",
      overall: "Загальний настрій",
      ambiance: "Атмосфера зустрічі",
      reactions: "Використані реакції",
      positive: "Позитивний",
      neutral: "Нейтральний",
      negative: "Негативний",
      perParticipant: "Настрій за учасниками",
      keywords: "Ключові фрази"
    },
    footer: {
      generatedBy: "Цей звіт був автоматично згенерований за допомогою CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'vi-VN': {
    title: "Báo Cáo Cuộc Họp",
    summary: "Tóm Tắt Cuộc Họp",
    generatedAt: "Tạo vào",
    stats: {
      totalParticipants: "Tổng số người tham dự",
      totalMessages: "Tổng số tin nhắn",
      totalWords: "Tổng số từ",
      duration: "Thời lượng cuộc họp",
      minutes: "phút"
    },
    participants: {
      title: "Chi Tiết Người Tham Dự",
      columns: {
        name: "Người tham dự",
        messageCount: "Số lượng tin nhắn",
        wordCount: "Số lượng từ",
        participation: "Tỷ lệ tham gia"
      }
    },
    mood: {
      title: "Không Khí Cuộc Họp",
      overall: "Tình Hình Chung",
      ambiance: "Không Khí Cuộc Họp",
      reactions: "Phản ứng đã sử dụng",
      positive: "Tích cực",
      neutral: "Trung lập",
      negative: "Tiêu cực",
      perParticipant: "Tình hình theo từng người tham dự",
      keywords: "Cụm từ nổi bật"
    },
    footer: {
      generatedBy: "Báo cáo này được tạo tự động bởi CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'zh-CN': {
    title: "会议报告",
    summary: "会议摘要",
    generatedAt: "生成于",
    stats: {
      totalParticipants: "总参与者",
      totalMessages: "总消息数",
      totalWords: "总字数",
      duration: "会议时长",
      minutes: "分钟"
    },
    participants: {
      title: "参与者详情",
      columns: {
        name: "参与者",
        messageCount: "消息数量",
        wordCount: "单词数量",
        participation: "参与度"
      }
    },
    mood: {
      title: "会议氛围",
      overall: "整体情绪",
      ambiance: "会议氛围",
      reactions: "使用的反应",
      positive: "积极",
      neutral: "中立",
      negative: "消极",
      perParticipant: "每个参与者的情绪",
      keywords: "重点词语"
    },
    footer: {
      generatedBy: "此报告由 CSC Voice AI 自动生成",
      copyright: "© CSC Bilisim"
    }
  }
};

// UI metinleri için çeviriler
const UI_TEXTS = {
  'tr-TR': {
    aiGenerated: "Bu içerikler yapay zeka tarafından oluşturulacaktır",
    noMessages: "Henüz çevrilmiş mesaj yok",
    messagesWillAppear: "\"Dinle\" düğmesine bastığınızda ve konuşma başladığında çeviriler burada görünecektir",
    listenButton: "Dinle",
    stopButton: "Durdur",
    createReportButton: "AI Raporu Oluştur",
    micPermissionButton: "Mikrofon İzni",
    reportButton: "Bildir",
    submitReportButton: "Raporu Gönder",
    cancelButton: "İptal",
    meetingLimit: "Toplantı Limiti",
    upgradePackage: "Paketinizi Yükseltin",
    limitExceeded: "Limit Aşıldı",
    remaining: "Kalan",
    reportLimit: "Rapor Limiti",
    reportLimitExceeded: "Rapor limiti aşıldı",
    reportGenerationError: "Rapor oluşturma hatası",
    micPermissionAlert: "Mikrofon yetkileriyle ilgili sıkıntı yaşıyorsunuz, lütfen üst tarafındaki uygulama ayarları bölümünden \"Ayarlar\" butonuna basarak ilgili klavuz ile gerekli değişiklikleri yapınız.",
    translatingMessage: "konuşması çevriliyor...",
    realTimeMeeting: "Gerçek Zamanlı Toplantı Çevirisi",
    makeChoice: "Seçim Yapınız",
    voiceDisabled: "Ses Devre Dışı",
    voiceEnabled: "Ses Aktif",
    testFor: "Test İçin",
    packages: {
      trial: "Deneme",
      basic: "Temel",
      professional: "Profesyonel",
      enterprise: "Kurumsal",
    }
  },
  'en-US': {
    aiGenerated: "This content will be generated by AI",
    noMessages: "There is no translated message yet",
    messagesWillAppear: "The translated messages will appear here when the \"Listen\" button and conversation starts",
    listenButton: "Listen",
    stopButton: "Stop",
    createReportButton: "Generate AI Meeting Report",
    micPermissionButton: "Microphone Permission",
    reportButton: "Report",
    submitReportButton: "Submit Report",
    cancelButton: "Cancel",
    meetingLimit: "Meeting Limit",
    upgradePackage: "Upgrade Your Package",
    limitExceeded: "Limit Exceeded",
    remaining: "Remaining",
    reportLimit: "Report Limit",
    reportLimitExceeded: "Report limit exceeded",
    reportGenerationError: "Report generation error",
    micPermissionAlert: "You are experiencing issues with microphone permissions, please click the \"Settings\" button in the application settings section above and make the necessary changes following the relevant guide.",
    translatingMessage: "speech is being translated...",
    realTimeMeeting: "Real-time Meeting Translation",
    makeChoice: "Make a Choice",
    voiceDisabled: "Voice Disabled",
    voiceEnabled: "Voice Enabled",
    testFor: "Test For",
    packages: {
      trial: "Trial",
      basic: "Basic",
      professional: "Professional",
      enterprise: "Enterprise"
    }
  },
  'fr-FR': {
    aiGenerated: "Ce contenu sera généré par l'intelligence artificielle",
    noMessages: "Il n'y a pas encore de message traduit",
    messagesWillAppear: "Les messages traduits apparaîtront ici lorsque vous appuierez sur le bouton \"Écouter\" et que la conversation commencera",
    listenButton: "Écouter",
    stopButton: "Arrêter",
    createReportButton: "AI Créer un Rapport",
    micPermissionButton: "Permission du Microphone",
    reportButton: "Signaler",
    submitReportButton: "Soumettre le Rapport",
    cancelButton: "Annuler",
    meetingLimit: "Limite de Réunion",
    upgradePackage: "Améliorez Votre Forfait",
    limitExceeded: "Limite Dépassée",
    remaining: "Restant",
    reportLimit: "Limite de Rapport",
    reportLimitExceeded: "Limite de rapport dépassée",
    reportGenerationError: "Erreur de génération de rapport",
    micPermissionAlert: "Vous rencontrez des problèmes avec les autorisations du microphone, veuillez cliquer sur le bouton \"Paramètres\" dans la section des paramètres de l'application ci-dessus et effectuer les modifications nécessaires en suivant le guide correspondant.",
    translatingMessage: "discours en cours de traduction...",
    realTimeMeeting: "Traduction de Réunion en Temps Réel",
    makeChoice: "Faites un Choix",
    voiceDisabled: "Voix Désactivée",
    voiceEnabled: "Voix Activée",
    testFor: "Test Pour",
    packages: {
      trial: "Essai",
      basic: "Base",
      professional: "Professionnel",
      enterprise: "Entreprise"
    }
  },
  'de-DE': {
    aiGenerated: "Diese Inhalte werden von künstlicher Intelligenz erstellt",
    noMessages: "Es gibt noch keine übersetzte Nachricht",
    messagesWillAppear: "Die übersetzten Nachrichten erscheinen hier, wenn Sie auf die Schaltfläche \"Zuhören\" klicken und das Gespräch beginnt",
    listenButton: "Zuhören",
    stopButton: "Stopp",
    createReportButton: "AI Bericht Erstellen",
    micPermissionButton: "Mikrofonberechtigung",
    reportButton: "Melden",
    submitReportButton: "Bericht Einreichen",
    cancelButton: "Abbrechen",
    meetingLimit: "Besprechungslimit",
    upgradePackage: "Paket Upgraden",
    limitExceeded: "Limit Überschritten",
    remaining: "Verbleibend",
    reportLimit: "Berichtslimit",
    reportLimitExceeded: "Berichtslimit überschritten",
    reportGenerationError: "Fehler bei der Berichtserstellung",
    micPermissionAlert: "Sie haben Probleme mit den Mikrofonberechtigungen. Bitte klicken Sie oben im Bereich Anwendungseinstellungen auf die Schaltfläche \"Einstellungen\" und nehmen Sie die erforderlichen Änderungen gemäß der entsprechenden Anleitung vor.",
    translatingMessage: "Rede wird übersetzt...",
    realTimeMeeting: "Echtzeit-Besprechungsübersetzung",
    makeChoice: "Treffen Sie eine Wahl",
    voiceDisabled: "Stimme Deaktiviert",
    voiceEnabled: "Stimme Aktiviert",
    testFor: "Test Für",
    packages: {
      trial: "Testversion",
      basic: "Basis",
      professional: "Professionell",
      enterprise: "Unternehmen"
    }
  },
  'es-ES': {
    aiGenerated: "Este contenido será generado por inteligencia artificial",
    noMessages: "No hay mensajes traducidos todavía",
    messagesWillAppear: "Los mensajes traducidos aparecerán aquí cuando pulse el botón \"Escuchar\" y comience la conversación",
    listenButton: "Escuchar",
    stopButton: "Detener",
    createReportButton: "AI Crear Informe",
    micPermissionButton: "Permiso de Micrófono",
    reportButton: "Informar",
    submitReportButton: "Enviar Informe",
    cancelButton: "Cancelar",
    meetingLimit: "Límite de Reuniones",
    upgradePackage: "Actualizar Su Paquete",
    limitExceeded: "Límite Excedido",
    remaining: "Restante",
    reportLimit: "Límite de Informe",
    reportLimitExceeded: "Límite de informe excedido",
    reportGenerationError: "Error de generación de informe",
    micPermissionAlert: "Está experimentando problemas con los permisos del micrófono. Haga clic en el botón \"Configuración\" en la sección de configuración de la aplicación arriba y realice los cambios necesarios siguiendo la guía correspondiente.",
    translatingMessage: "discurso está siendo traducido...",
    realTimeMeeting: "Traducción de Reunión en Tiempo Real",
    makeChoice: "Haga una Elección",
    voiceDisabled: "Voz Desactivada",
    voiceEnabled: "Voz Activada",
    testFor: "Prueba Para",
    packages: {
      trial: "Prueba",
      basic: "Básico",
      professional: "Profesional",
      enterprise: "Empresarial"
    }
  },
  'it-IT': {
    aiGenerated: "Questo contenuto sarà generato dall'intelligenza artificiale",
    noMessages: "Non ci sono ancora messaggi tradotti",
    messagesWillAppear: "I messaggi tradotti appariranno qui quando si preme il pulsante \"Ascolta\" e inizia la conversazione",
    listenButton: "Ascolta",
    stopButton: "Ferma",
    createReportButton: "AI Crea Rapporto",
    micPermissionButton: "Permesso Microfono",
    reportButton: "Segnala",
    submitReportButton: "Invia Rapporto",
    cancelButton: "Annulla",
    meetingLimit: "Limite Riunioni",
    upgradePackage: "Aggiorna il Tuo Pacchetto",
    limitExceeded: "Limite Superato",
    remaining: "Rimanente",
    reportLimit: "Limite Rapporto",
    reportLimitExceeded: "Limite rapporto superato",
    reportGenerationError: "Errore di generazione del rapporto",
    micPermissionAlert: "Stai riscontrando problemi con le autorizzazioni del microfono. Fai clic sul pulsante \"Impostazioni\" nella sezione delle impostazioni dell'applicazione sopra e apporta le modifiche necessarie seguendo la guida.",
    translatingMessage: "discorso in traduzione...",
    realTimeMeeting: "Traduzione Riunione in Tempo Reale",
    makeChoice: "Fai una Scelta",
    voiceDisabled: "Voce Disattivata",
    voiceEnabled: "Voce Attivata",
    testFor: "Test Per",
    packages: {
      trial: "Prova",
      basic: "Base",
      professional: "Professionale",
      enterprise: "Aziendale"
    }
  },
  'ru-RU': {
    aiGenerated: "Этот контент будет создан искусственным интеллектом",
    noMessages: "Переведенных сообщений пока нет",
    messagesWillAppear: "Переведенные сообщения появятся здесь, когда вы нажмете кнопку \"Слушать\" и начнется разговор",
    listenButton: "Слушать",
    stopButton: "Стоп",
    createReportButton: "AI Создать Отчет",
    micPermissionButton: "Разрешение Микрофона",
    reportButton: "Сообщить",
    submitReportButton: "Отправить Отчет",
    cancelButton: "Отмена",
    meetingLimit: "Лимит Встреч",
    upgradePackage: "Обновить Пакет",
    limitExceeded: "Лимит Превышен",
    remaining: "Осталось",
    reportLimit: "Лимит Отчетов",
    reportLimitExceeded: "Лимит отчетов превышен",
    reportGenerationError: "Ошибка создания отчета",
    micPermissionAlert: "У вас возникли проблемы с разрешениями микрофона. Пожалуйста, нажмите кнопку \"Настройки\" в разделе настроек приложения выше и внесите необходимые изменения, следуя соответствующему руководству.",
    translatingMessage: "речь переводится...",
    realTimeMeeting: "Перевод Встречи в Реальном Времени",
    makeChoice: "Сделайте Выбор",
    voiceDisabled: "Голос Отключен",
    voiceEnabled: "Голос Включен",
    testFor: "Тест Для",
    packages: {
      trial: "Пробный",
      basic: "Базовый",
      professional: "Профессиональный",
      enterprise: "Корпоративный"
    }
  },
  'zh-CN': {
    aiGenerated: "这些内容将由人工智能生成",
    noMessages: "尚无翻译消息",
    messagesWillAppear: "当您点击'收听'按钮并开始对话时，翻译的消息将显示在这里",
    listenButton: "收听",
    stopButton: "停止",
    createReportButton: "创建报告",
    micPermissionButton: "麦克风权限",
    reportButton: "举报",
    submitReportButton: "提交报告",
    cancelButton: "取消",
    meetingLimit: "会议限制",
    upgradePackage: "升级您的套餐",
    limitExceeded: "超出限制",
    remaining: "剩余",
    reportLimit: "报告限制",
    reportLimitExceeded: "报告限制已超出",
    reportGenerationError: "报告生成错误",
    micPermissionAlert: "您遇到麦克风权限问题。请点击上方应用程序设置部分中的'设置'按钮，并按照相关指南进行必要的更改。",
    translatingMessage: "正在翻译讲话...",
    realTimeMeeting: "实时会议翻译",
    makeChoice: "做出选择",
    voiceDisabled: "语音已禁用",
    voiceEnabled: "语音已启用",
    testFor: "测试用",
    packages: {
      trial: "试用版",
      basic: "基础版",
      professional: "专业版",
      enterprise: "企业版"
    }
  },
  'ja-JP': {
    aiGenerated: "このコンテンツは人工知能によって生成されます",
    noMessages: "まだ翻訳されたメッセージはありません",
    messagesWillAppear: "「聴く」ボタンを押して会話が始まると、ここに翻訳されたメッセージが表示されます",
    listenButton: "聴く",
    stopButton: "停止",
    createReportButton: "レポート作成",
    micPermissionButton: "マイク許可",
    reportButton: "報告",
    submitReportButton: "レポート提出",
    cancelButton: "キャンセル",
    meetingLimit: "会議の制限",
    upgradePackage: "パッケージをアップグレード",
    limitExceeded: "制限超過",
    remaining: "残り",
    reportLimit: "レポート制限",
    reportLimitExceeded: "レポート制限を超過しました",
    reportGenerationError: "レポート生成エラー",
    micPermissionAlert: "マイクの権限に問題が発生しています。上部のアプリケーション設定セクションにある「設定」ボタンをクリックし、関連するガイドに従って必要な変更を行ってください。",
    translatingMessage: "スピーチを翻訳中...",
    realTimeMeeting: "リアルタイム会議翻訳",
    makeChoice: "選択してください",
    voiceDisabled: "音声無効",
    voiceEnabled: "音声有効",
    testFor: "テスト用",
    packages: {
      trial: "トライアル",
      basic: "ベーシック",
      professional: "プロフェッショナル",
      enterprise: "エンタープライズ"
    }
  },
  'ar-SA': {
    aiGenerated: "سيتم إنشاء هذا المحتوى بواسطة الذكاء الاصطناعي",
    noMessages: "لا توجد رسائل مترجمة بعد",
    messagesWillAppear: "ستظهر الرسائل المترجمة هنا عند الضغط على زر \"استماع\" وبدء المحادثة",
    listenButton: "استماع",
    stopButton: "توقف",
    createReportButton: "إنشاء تقرير",
    micPermissionButton: "إذن الميكروفون",
    reportButton: "إبلاغ",
    submitReportButton: "إرسال التقرير",
    cancelButton: "إلغاء",
    meetingLimit: "حد الاجتماعات",
    upgradePackage: "ترقية الباقة",
    limitExceeded: "تم تجاوز الحد",
    remaining: "متبقي",
    reportLimit: "حد التقارير",
    reportLimitExceeded: "تم تجاوز حد التقارير",
    reportGenerationError: "خطأ في إنشاء التقرير",
    micPermissionAlert: "تواجه مشكلة في أذونات الميكروفون، يرجى النقر على زر \"الإعدادات\" في قسم إعدادات التطبيق أعلاه وإجراء التغييرات اللازمة باتباع الدليل ذي الصلة.",
    translatingMessage: "جاري ترجمة الكلام...",
    realTimeMeeting: "ترجمة الاجتماعات في الوقت الفعلي",
    makeChoice: "قم بالاختيار",
    voiceDisabled: "الصوت معطل",
    voiceEnabled: "الصوت مفعل",
    testFor: "اختبار ل",
    packages: {
      trial: "تجريبي",
      basic: "أساسي",
      professional: "احترافي",
      enterprise: "مؤسسي"
    }
  },
  'pt-PT': {
    aiGenerated: "Este conteúdo será gerado por inteligência artificial",
    noMessages: "Ainda não há mensagem traduzida",
    messagesWillAppear: "As mensagens traduzidas aparecerão aqui quando você pressionar o botão \"Ouvir\" e a conversa começar",
    listenButton: "Ouvir",
    stopButton: "Parar",
    createReportButton: "AI Criar Relatório",
    micPermissionButton: "Permissão do Microfone",
    reportButton: "Reportar",
    submitReportButton: "Enviar Relatório",
    cancelButton: "Cancelar",
    meetingLimit: "Limite de Reuniões",
    upgradePackage: "Atualize Seu Pacote",
    limitExceeded: "Limite Excedido",
    remaining: "Restante",
    reportLimit: "Limite de Relatório",
    reportLimitExceeded: "Limite de relatório excedido",
    reportGenerationError: "Erro de geração de relatório",
    micPermissionAlert: "Está a ter problemas com as permissões do microfone. Por favor, clique no botão \"Configurações\" na seção de configurações da aplicação acima e faça as alterações necessárias seguindo o guia relevante.",
    translatingMessage: "discurso está sendo traduzido...",
    realTimeMeeting: "Tradução de Reunião em Tempo Real",
    makeChoice: "Faça uma Escolha",
    voiceDisabled: "Voz Desativada",
    voiceEnabled: "Voz Ativada",
    testFor: "Teste Para",
    packages: {
      trial: "Experimental",
      basic: "Básico",
      professional: "Profissional",
      enterprise: "Empresarial"
    }
  },
  'el-GR': {
    aiGenerated: "Αυτό το περιεχόμενο θα δημιουργηθεί από τεχνητή νοημοσύνη",
    noMessages: "Δεν υπάρχει ακόμα μεταφρασμένο μήνυμα",
    messagesWillAppear: "Τα μεταφρασμένα μηνύματα θα εμφανιστούν εδώ όταν πατήσετε το κουμπί \"Ακρόαση\" και ξεκινήσει η συνομιλία",
    listenButton: "Ακρόαση",
    stopButton: "Διακοπή",
    createReportButton: "Δημιουργία Αναφοράς",
    micPermissionButton: "Άδεια Μικροφώνου",
    reportButton: "Αναφορά",
    submitReportButton: "Υποβολή Αναφοράς",
    cancelButton: "Ακύρωση",
    meetingLimit: "Όριο Συναντήσεων",
    upgradePackage: "Αναβαθμίστε το Πακέτο Σας",
    limitExceeded: "Υπέρβαση Ορίου",
    remaining: "Υπόλοιπο",
    reportLimit: "Όριο Αναφορών",
    reportLimitExceeded: "Υπέρβαση ορίου αναφορών",
    reportGenerationError: "Σφάλμα δημιουργίας αναφοράς",
    micPermissionAlert: "Αντιμετωπίζετε προβλήματα με τα δικαιώματα του μικροφώνου. Παρακαλώ κάντε κλικ στο κουμπί \"Ρυθμίσεις\" στην ενότητα ρυθμίσεων της εφαρμογής παραπάνω και κάντε τις απαραίτητες αλλαγές ακολουθώντας τον σχετικό οδηγό.",
    translatingMessage: "η ομιλία μεταφράζεται...",
    realTimeMeeting: "Μετάφραση Συνάντησης σε Πραγματικό Χρόνο",
    makeChoice: "Κάντε μια Επιλογή",
    voiceDisabled: "Φωνή Απενεργοποιημένη",
    voiceEnabled: "Φωνή Ενεργοποιημένη",
    testFor: "Δοκιμή Για",
    packages: {
      trial: "Δοκιμαστικό",
      basic: "Βασικό",
      professional: "Επαγγελματικό",
      enterprise: "Επιχειρηματικό"
    }
  },
  'he-IL': {
    aiGenerated: "תוכן זה ייווצר על ידי בינה מלאכותית",
    noMessages: "אין עדיין הודעה מתורגמת",
    messagesWillAppear: "ההודעות המתורגמות יופיעו כאן כאשר תלחץ על כפתור \"האזנה\" והשיחה תתחיל",
    listenButton: "האזנה",
    stopButton: "עצור",
    createReportButton: "צור דוח",
    micPermissionButton: "הרשאת מיקרופון",
    reportButton: "דווח",
    submitReportButton: "שלח דוח",
    cancelButton: "ביטול",
    meetingLimit: "מגבלת פגישות",
    upgradePackage: "שדרג את החבילה שלך",
    limitExceeded: "חריגה ממגבלה",
    remaining: "נותרו",
    reportLimit: "מגבלת דוחות",
    reportLimitExceeded: "חריגה ממגבלת דוחות",
    reportGenerationError: "שגיאה ביצירת דוח",
    micPermissionAlert: "אתה נתקל בבעיות עם הרשאות המיקרופון. אנא לחץ על כפתור \"הגדרות\" בחלק הגדרות היישום למעלה ובצע את השינויים הנדרשים בהתאם למדריך הרלוונטי.",
    translatingMessage: "התרגום מתבצע...",
    realTimeMeeting: "תרגום פגישה בזמן אמת",
    makeChoice: "בחר אפשרות",
    voiceDisabled: "קול מושבת",
    voiceEnabled: "קול מופעל",
    testFor: "בדיקה עבור",
    packages: {
      trial: "ניסיון",
      basic: "בסיסי",
      professional: "מקצועי",
      enterprise: "ארגוני"
    }
  },
  'bg-BG': {
    aiGenerated: "Това съдържание ще бъде генерирано от изкуствен интелект",
    noMessages: "Все още няма преведено съобщение",
    messagesWillAppear: "Преведените съобщения ще се появят тук, когато натиснете бутона \"Слушай\" и разговорът започне",
    listenButton: "Слушай",
    stopButton: "Спри",
    createReportButton: "Създай Отчет",
    micPermissionButton: "Разрешение за Микрофон",
    reportButton: "Докладвай",
    submitReportButton: "Изпрати Отчет",
    cancelButton: "Отказ",
    meetingLimit: "Лимит за Срещи",
    upgradePackage: "Надградете Вашия Пакет",
    limitExceeded: "Надвишен Лимит",
    remaining: "Оставащи",
    reportLimit: "Лимит за Отчети",
    reportLimitExceeded: "Надвишен лимит за отчети",
    reportGenerationError: "Грешка при създаване на отчет",
    micPermissionAlert: "Имате проблеми с разрешенията на микрофона. Моля, кликнете върху бутона \"Настройки\" в секцията с настройки на приложението по-горе и направете необходимите промени, следвайки съответното ръководство.",
    translatingMessage: "речта се превежда...",
    realTimeMeeting: "Превод на Срещи в Реално Време",
    makeChoice: "Направете Избор",
    voiceDisabled: "Гласът е Изключен",
    voiceEnabled: "Гласът е Включен",
    testFor: "Тест За",
    packages: {
      trial: "Пробен",
      basic: "Основен",
      professional: "Професионален",
      enterprise: "Корпоративен"
    }
  },
  'cs-CZ': {
    aiGenerated: "Tento obsah bude generován umělou inteligencí",
    noMessages: "Zatím není žádná přeložená zpráva",
    messagesWillAppear: "Přeložené zprávy se zde zobrazí, když stisknete tlačítko \"Poslouchat\" a začne konverzace",
    listenButton: "Poslouchat",
    stopButton: "Zastavit",
    createReportButton: "Vytvořit Zprávu",
    micPermissionButton: "Povolení Mikrofonu",
    reportButton: "Nahlásit",
    submitReportButton: "Odeslat Zprávu",
    cancelButton: "Zrušit",
    meetingLimit: "Limit Schůzek",
    upgradePackage: "Aktualizujte Váš Balíček",
    limitExceeded: "Překročen Limit",
    remaining: "Zbývající",
    reportLimit: "Limit Zpráv",
    reportLimitExceeded: "Limit zpráv překročen",
    reportGenerationError: "Chyba generování zprávy",
    micPermissionAlert: "Máte potíže s oprávněními mikrofonu. Klikněte prosím na tlačítko \"Nastavení\" v sekci nastavení aplikace výše a proveďte potřebné změny podle příslušného průvodce.",
    translatingMessage: "probíhá překlad řeči...",
    realTimeMeeting: "Překlad Schůzky v Reálném Čase",
    makeChoice: "Proveďte Výběr",
    voiceDisabled: "Hlas Vypnut",
    voiceEnabled: "Hlas Zapnut",
    testFor: "Test Pro",
    packages: {
      trial: "Zkušební",
      basic: "Základní",
      professional: "Profesionální",
      enterprise: "Podnikový"
    }
  },
  'da-DK': {
    aiGenerated: "Dette indhold vil blive genereret af kunstig intelligens",
    noMessages: "Der er endnu ingen oversat besked",
    messagesWillAppear: "De oversatte beskeder vises her, når du trykker på knappen \"Lyt\" og samtalen starter",
    listenButton: "Lyt",
    stopButton: "Stop",
    createReportButton: "AI Opret Rapport",
    micPermissionButton: "Mikrofontilladelse",
    reportButton: "Rapporter",
    submitReportButton: "Indsend Rapport",
    cancelButton: "Annuller",
    meetingLimit: "Mødelimit",
    upgradePackage: "Opgrader Din Pakke",
    limitExceeded: "Grænse Overskredet",
    remaining: "Resterende",
    reportLimit: "Rapportgrænse",
    reportLimitExceeded: "Rapportgrænse overskredet",
    reportGenerationError: "Fejl ved rapportgenerering",
    micPermissionAlert: "Du oplever problemer med mikrofontilladelser. Klik venligst på \"Indstillinger\" knappen i applikationsindstillingerne ovenfor og foretag de nødvendige ændringer ved at følge den relevante vejledning.",
    translatingMessage: "tale bliver oversat...",
    realTimeMeeting: "Realtidsoversættelse af Møde",
    makeChoice: "Foretag et Valg",
    voiceDisabled: "Stemme Deaktiveret",
    voiceEnabled: "Stemme Aktiveret",
    testFor: "Test For",
    packages: {
      trial: "Prøve",
      basic: "Basis",
      professional: "Professionel",
      enterprise: "Virksomhed"
    }
  },
  'fi-FI': {
    aiGenerated: "Tämä sisältö tuotetaan tekoälyllä",
    noMessages: "Käännettyjä viestejä ei ole vielä",
    messagesWillAppear: "Käännetyt viestit näkyvät täällä, kun painat \"Kuuntele\"-painiketta ja keskustelu alkaa",
    listenButton: "Kuuntele",
    stopButton: "Pysäytä",
    createReportButton: "Luo Raportti",
    micPermissionButton: "Mikrofonin Lupa",
    reportButton: "Ilmoita",
    submitReportButton: "Lähetä Raportti",
    cancelButton: "Peruuta",
    meetingLimit: "Kokouksen Raja",
    upgradePackage: "Päivitä Pakettisi",
    limitExceeded: "Raja Ylitetty",
    remaining: "Jäljellä",
    reportLimit: "Raporttiraja",
    reportLimitExceeded: "Raporttiraja ylitetty",
    reportGenerationError: "Raportin luomisvirhe",
    micPermissionAlert: "Sinulla on ongelmia mikrofonin käyttöoikeuksien kanssa. Napsauta yllä olevan sovellusasetusten osion \"Asetukset\"-painiketta ja tee tarvittavat muutokset asianmukaista ohjetta noudattaen.",
    translatingMessage: "puhetta käännetään...",
    realTimeMeeting: "Reaaliaikainen Kokousten Käännös",
    makeChoice: "Tee Valinta",
    voiceDisabled: "Ääni Pois Käytöstä",
    voiceEnabled: "Ääni Käytössä",
    testFor: "Testi",
    packages: {
      trial: "Kokeilu",
      basic: "Perus",
      professional: "Ammattilainen",
      enterprise: "Yritys"
    }
  },
  'hu-HU': {
    aiGenerated: "Ezt a tartalmat mesterséges intelligencia fogja generálni",
    noMessages: "Még nincs lefordított üzenet",
    messagesWillAppear: "A lefordított üzenetek itt jelennek meg, amikor megnyomja a \"Hallgatás\" gombot és elindul a beszélgetés",
    listenButton: "Hallgatás",
    stopButton: "Leállítás",
    createReportButton: "AI Jelentés Készítése",
    micPermissionButton: "Mikrofon Engedély",
    reportButton: "Jelentés",
    submitReportButton: "Jelentés Beküldése",
    cancelButton: "Mégse",
    meetingLimit: "Találkozó Korlát",
    upgradePackage: "Csomag Frissítése",
    limitExceeded: "Korlát Túllépve",
    remaining: "Fennmaradó",
    reportLimit: "Jelentés Korlát",
    reportLimitExceeded: "Jelentés korlát túllépve",
    reportGenerationError: "Jelentés készítési hiba",
    micPermissionAlert: "Problémák vannak a mikrofon engedélyeivel. Kérjük, kattintson a \"Beállítások\" gombra a fenti alkalmazásbeállítások részben, és végezze el a szükséges módosításokat a vonatkozó útmutató szerint.",
    translatingMessage: "beszéd fordítása folyamatban...",
    realTimeMeeting: "Valós Idejű Értekezlet Fordítás",
    makeChoice: "Válasszon",
    voiceDisabled: "Hang Kikapcsolva",
    voiceEnabled: "Hang Bekapcsolva",
    testFor: "Teszt",
    packages: {
      trial: "Próba",
      basic: "Alap",
      professional: "Professzionális",
      enterprise: "Vállalati"
    }
  },
  'ko-KR': {
    aiGenerated: "이 콘텐츠는 인공지능에 의해 생성될 것입니다",
    noMessages: "아직 번역된 메시지가 없습니다",
    messagesWillAppear: "\"듣기\" 버튼을 누르고 대화가 시작되면 번역된 메시지가 여기에 표시됩니다",
    listenButton: "듣기",
    stopButton: "중지",
    createReportButton: "보고서 생성",
    micPermissionButton: "마이크 권한",
    reportButton: "신고",
    submitReportButton: "보고서 제출",
    cancelButton: "취소",
    meetingLimit: "회의 제한",
    upgradePackage: "패키지 업그레이드",
    limitExceeded: "제한 초과",
    remaining: "남은",
    reportLimit: "보고서 제한",
    reportLimitExceeded: "보고서 제한 초과",
    reportGenerationError: "보고서 생성 오류",
    micPermissionAlert: "마이크 권한에 문제가 있습니다. 위의 애플리케이션 설정 섹션에서 \"설정\" 버튼을 클릭하고 관련 가이드에 따라 필요한 변경을 수행하십시오.",
    translatingMessage: "음성을 번역하고 있습니다...",
    realTimeMeeting: "실시간 회의 통역",
    makeChoice: "선택하세요",
    voiceDisabled: "음성 비활성화",
    voiceEnabled: "음성 활성화",
    testFor: "테스트용",
    packages: {
      trial: "체험판",
      basic: "기본",
      professional: "전문가",
      enterprise: "기업용"
    }
  },
  'ms-MY': {
    aiGenerated: "Kandungan ini akan dihasilkan oleh kecerdasan buatan",
    noMessages: "Belum ada mesej yang diterjemahkan",
    messagesWillAppear: "Mesej yang diterjemahkan akan muncul di sini apabila anda menekan butang \"Dengar\" dan perbualan bermula",
    listenButton: "Dengar",
    stopButton: "Henti",
    createReportButton: "AI Cipta Laporan",
    micPermissionButton: "Kebenaran Mikrofon",
    reportButton: "Laporkan",
    submitReportButton: "Hantar Laporan",
    cancelButton: "Batal",
    meetingLimit: "Had Mesyuarat",
    upgradePackage: "Tingkatkan Pakej Anda",
    limitExceeded: "Had Melebihi",
    remaining: "Baki",
    reportLimit: "Had Laporan",
    reportLimitExceeded: "Had laporan melebihi",
    reportGenerationError: "Ralat penjanaan laporan",
    micPermissionAlert: "Anda mengalami masalah dengan kebenaran mikrofon. Sila klik butang \"Tetapan\" di bahagian tetapan aplikasi di atas dan buat perubahan yang diperlukan mengikut panduan yang berkaitan.",
    translatingMessage: "ucapan sedang diterjemahkan...",
    realTimeMeeting: "Terjemahan Mesyuarat Masa Nyata",
    makeChoice: "Buat Pilihan",
    voiceDisabled: "Suara Dimatikan",
    voiceEnabled: "Suara Dihidupkan",
    testFor: "Ujian Untuk",
    packages: {
      trial: "Percubaan",
      basic: "Asas",
      professional: "Profesional",
      enterprise: "Perusahaan"
    }
  },
  'pl-PL': {
    aiGenerated: "Ta treść zostanie wygenerowana przez sztuczną inteligencję",
    noMessages: "Nie ma jeszcze przetłumaczonej wiadomości",
    messagesWillAppear: "Przetłumaczone wiadomości pojawią się tutaj po naciśnięciu przycisku \"Słuchaj\" i rozpoczęciu rozmowy",
    listenButton: "Słuchaj",
    stopButton: "Stop",
    createReportButton: "AI Utwórz Raport",
    micPermissionButton: "Zezwolenie na Mikrofon",
    reportButton: "Zgłoś",
    submitReportButton: "Wyślij Raport",
    cancelButton: "Anuluj",
    meetingLimit: "Limit Spotkań",
    upgradePackage: "Ulepsz Swój Pakiet",
    limitExceeded: "Limit Przekroczony",
    remaining: "Pozostało",
    reportLimit: "Limit Raportów",
    reportLimitExceeded: "Limit raportów przekroczony",
    reportGenerationError: "Błąd generowania raportu",
    micPermissionAlert: "Występują problemy z uprawnieniami mikrofonu. Kliknij przycisk \"Ustawienia\" w sekcji ustawień aplikacji powyżej i wprowadź niezbędne zmiany zgodnie z odpowiednim przewodnikiem.",
    translatingMessage: "mowa jest tłumaczona...",
    realTimeMeeting: "Tłumaczenie Spotkania w Czasie Rzeczywistym",
    makeChoice: "Dokonaj Wyboru",
    voiceDisabled: "Głos Wyłączony",
    voiceEnabled: "Głos Włączony",
    testFor: "Test Dla",
    packages: {
      trial: "Próbny",
      basic: "Podstawowy",
      professional: "Profesjonalny",
      enterprise: "Korporacyjny"
    }
  },
  'ro-RO': {
    aiGenerated: "Acest conținut va fi generat de inteligența artificială",
    noMessages: "Nu există încă un mesaj tradus",
    messagesWillAppear: "Mesajele traduse vor apărea aici când apăsați butonul \"Ascultă\" și începe conversația",
    listenButton: "Ascultă",
    stopButton: "Oprește",
    createReportButton: "AI Creează Raport",
    micPermissionButton: "Permisiune Microfon",
    reportButton: "Raportează",
    submitReportButton: "Trimite Raport",
    cancelButton: "Anulează",
    meetingLimit: "Limită Întâlniri",
    upgradePackage: "Actualizați Pachetul",
    limitExceeded: "Limită Depășită",
    remaining: "Rămas",
    reportLimit: "Limită Rapoarte",
    reportLimitExceeded: "Limită rapoarte depășită",
    reportGenerationError: "Eroare generare raport",
    micPermissionAlert: "Întâmpinați probleme cu permisiunile microfonului. Vă rugăm să faceți clic pe butonul \"Setări\" din secțiunea de setări a aplicației de mai sus și să efectuați modificările necesare urmând ghidul relevant.",
    translatingMessage: "discursul este tradus...",
    realTimeMeeting: "Traducere Întâlnire în Timp Real",
    makeChoice: "Faceți o Alegere",
    voiceDisabled: "Voce Dezactivată",
    voiceEnabled: "Voce Activată",
    testFor: "Test Pentru",
    packages: {
      trial: "Testare",
      basic: "De Bază",
      professional: "Profesional",
      enterprise: "Întreprindere"
    }
  },
  'sv-SE': {
    aiGenerated: "Detta innehåll kommer att genereras av artificiell intelligens",
    noMessages: "Det finns ännu inget översatt meddelande",
    messagesWillAppear: "De översatta meddelandena visas här när du trycker på knappen \"Lyssna\" och konversationen startar",
    listenButton: "Lyssna",
    stopButton: "Stoppa",
    createReportButton: "AI Skapa Rapport",
    micPermissionButton: "Mikrofontillstånd",
    reportButton: "Rapportera",
    submitReportButton: "Skicka Rapport",
    cancelButton: "Avbryt",
    meetingLimit: "Möteslimit",
    upgradePackage: "Uppgradera Ditt Paket",
    limitExceeded: "Gräns Överskridits",
    remaining: "Återstående",
    reportLimit: "Rapportgräns",
    reportLimitExceeded: "Rapportgräns överskridits",
    reportGenerationError: "Fel vid rapportgenerering",
    micPermissionAlert: "Du har problem med mikrofonbehörigheter. Klicka på knappen \"Inställningar\" i applikationsinställningarna ovan och gör nödvändiga ändringar enligt relevant guide.",
    translatingMessage: "tal översätts...",
    realTimeMeeting: "Realtidsöversättning av Möte",
    makeChoice: "Gör ett Val",
    voiceDisabled: "Röst Inaktiverad",
    voiceEnabled: "Röst Aktiverad",
    testFor: "Test För",
    packages: {
      trial: "Prov",
      basic: "Grund",
      professional: "Professionell",
      enterprise: "Företag"
    }
  },
  'th-TH': {
    aiGenerated: "เนื้อหานี้จะถูกสร้างโดยปัญญาประดิษฐ์",
    noMessages: "ยังไม่มีข้อความที่แปลแล้ว",
    messagesWillAppear: "ข้อความที่แปลแล้วจะปรากฏที่นี่เมื่อคุณกดปุ่ม \"ฟัง\" และการสนทนาเริ่มต้น",
    listenButton: "ฟัง",
    stopButton: "หยุด",
    createReportButton: "สร้างรายงาน",
    micPermissionButton: "การอนุญาตไมโครโฟน",
    reportButton: "รายงาน",
    submitReportButton: "ส่งรายงาน",
    cancelButton: "ยกเลิก",
    meetingLimit: "ขีดจำกัดการประชุม",
    upgradePackage: "อัปเกรดแพ็กเกจของคุณ",
    limitExceeded: "เกินขีดจำกัด",
    remaining: "คงเหลือ",
    reportLimit: "ขีดจำกัดรายงาน",
    reportLimitExceeded: "เกินขีดจำกัดรายงาน",
    reportGenerationError: "ข้อผิดพลาดในการสร้างรายงาน",
    micPermissionAlert: "คุณกำลังประสบปัญหาเกี่ยวกับสิทธิ์การใช้ไมโครโฟน โปรดคลิกปุ่ม \"การตั้งค่า\" ในส่วนการตั้งค่าแอปพลิเคชันด้านบนและทำการเปลี่ยนแปลงที่จำเป็นตามคำแนะนำที่เกี่ยวข้อง",
    translatingMessage: "กำลังแปลคำพูด...",
    realTimeMeeting: "การแปลการประชุมแบบเรียลไทม์",
    makeChoice: "เลือกตัวเลือก",
    voiceDisabled: "ปิดเสียง",
    voiceEnabled: "เปิดเสียง",
    testFor: "ทดสอบสำหรับ",
    packages: {
      trial: "ทดลองใช้",
      basic: "พื้นฐาน",
      professional: "มืออาชีพ",
      enterprise: "องค์กร"
    }
  },
  'uk-UA': {
    aiGenerated: "Цей вміст буде створено штучним інтелектом",
    noMessages: "Перекладених повідомлень ще немає",
    messagesWillAppear: "Перекладені повідомлення з'являться тут, коли ви натиснете кнопку \"Слухати\" і почнеться розмова",
    listenButton: "Слухати",
    stopButton: "Зупинити",
    createReportButton: "Створити Звіт",
    micPermissionButton: "Дозвіл Мікрофону",
    reportButton: "Повідомити",
    submitReportButton: "Надіслати Звіт",
    cancelButton: "Скасувати",
    meetingLimit: "Ліміт Зустрічей",
    upgradePackage: "Оновіть Ваш Пакет",
    limitExceeded: "Ліміт Перевищено",
    remaining: "Залишилось",
    reportLimit: "Ліміт Звітів",
    reportLimitExceeded: "Ліміт звітів перевищено",
    reportGenerationError: "Помилка створення звіту",
    micPermissionAlert: "У вас виникли проблеми з дозволами мікрофона. Будь ласка, натисніть кнопку \"Налаштування\" в розділі налаштувань програми вище та внесіть необхідні зміни, дотримуючись відповідного посібника.",
    translatingMessage: "мова перекладається...",
    realTimeMeeting: "Переклад Зустрічі в Реальному Часі",
    makeChoice: "Зробіть Вибір",
    voiceDisabled: "Голос Вимкнено",
    voiceEnabled: "Голос Увімкнено",
    testFor: "Тест Для",
    packages: {
      trial: "Пробний",
      basic: "Базовий",
      professional: "Професійний",
      enterprise: "Корпоративний"
    }
  },
  'vi-VN': {
    aiGenerated: "Nội dung này sẽ được tạo bởi trí tuệ nhân tạo",
    noMessages: "Chưa có tin nhắn được dịch",
    messagesWillAppear: "Các tin nhắn được dịch sẽ xuất hiện ở đây khi bạn nhấn nút \"Nghe\" và cuộc trò chuyện bắt đầu",
    listenButton: "Nghe",
    stopButton: "Dừng",
    createReportButton: "Tạo Báo Cáo",
    micPermissionButton: "Quyền Micrô",
    reportButton: "Báo Cáo",
    submitReportButton: "Gửi Báo Cáo",
    cancelButton: "Hủy",
    meetingLimit: "Giới Hạn Cuộc Họp",
    upgradePackage: "Nâng Cấp Gói Của Bạn",
    limitExceeded: "Vượt Quá Giới Hạn",
    remaining: "Còn Lại",
    reportLimit: "Giới Hạn Báo Cáo",
    reportLimitExceeded: "Đã vượt quá giới hạn báo cáo",
    reportGenerationError: "Lỗi tạo báo cáo",
    micPermissionAlert: "Bạn đang gặp vấn đề với quyền truy cập micrô. Vui lòng nhấp vào nút \"Cài đặt\" trong phần cài đặt ứng dụng ở trên và thực hiện các thay đổi cần thiết theo hướng dẫn liên quan.",
    translatingMessage: 'đang dịch bài phát biểu...',
    realTimeMeeting: "Dịch Cuộc Họp Thời Gian Thực",
    makeChoice: "Hãy Lựa Chọn",
    voiceDisabled: "Đã Tắt Tiếng",
    voiceEnabled: "Đã Bật Tiếng",
    testFor: "Kiểm Tra Cho",
    packages: {
      trial: "Dùng Thử",
      basic: "Cơ Bản",
      professional: "Chuyên Nghiệp",
      enterprise: "Doanh Nghiệp"
    }
  }
};

// Varsayılan Türkçe metinleri
const defaultTexts = {
  aiGenerated: "Bu içerikler yapay zeka tarafından oluşturulacaktır",
  noMessages: "Henüz çevrilmiş mesaj yok",
  messagesWillAppear: "\"Dinle\" düğmesine bastığınızda ve konuşma başladığında çeviriler burada görünecektir",
  listenButton: "Dinle",
  stopButton: "Durdur",
  createReportButton: "Rapor Oluştur",
  micPermissionButton: "Mikrofon İzni",
  reportButton: "Bildir",
  submitReportButton: "Raporu Gönder",
  cancelButton: "İptal",
  meetingLimit: "Toplantı Limiti",
  upgradePackage: "Paketinizi Yükseltin",
  limitExceeded: "Limit Aşıldı",
  remaining: "Kalan",
  reportLimit: "Rapor Limiti",
  reportLimitExceeded: "Rapor limiti aşıldı",
  reportGenerationError: "Rapor oluşturma hatası",
  micPermissionAlert: "Mikrofon yetkileriyle ilgili sıkıntı yaşıyorsunuz, lütfen üst tarafındaki uygulama ayarları bölümünden \"Ayarlar\" butonuna basarak ilgili klavuz ile gerekli değişiklikleri yapınız.",
  translatingMessage: "konuşması çevriliyor...",
  realTimeMeeting: "Gerçek Zamanlı Toplantı Çevirisi",
  makeChoice: "Seçim Yapınız",
  voiceDisabled: "Ses Devre Dışı",
  voiceEnabled: "Ses Aktif",
  testFor: "Test İçin",
  packages: {
    trial: "Deneme",
    basic: "Temel",
    professional: "Profesyonel",
    enterprise: "Kurumsal",
  }
};

// Yardımcı fonksiyon: İsimden ilk adı alma
const getFirstName = (fullName) => {
  return fullName.split(' ')[0];
};

// Sleep fonksiyonu
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));


// Dile göre UI metinlerini alma fonksiyonu
function getUITexts(language) {
  return UI_TEXTS[language] || defaultTexts;
}

// Bu fonksiyonu component dışına ekleyin
const debugSubscriptionStatus = (result) => {
  console.group('Subscription Status Debug');
  console.log('Success:', result.success);
  console.log('Can Use Meeting:', result.canUseMeeting);
  
  if (result.subscription) {
    console.log('Package:', result.subscription.packageName);
    console.log('Limit:', result.subscription.meetingLimit);
    console.log('Used:', result.subscription.meetingsUsed);
    console.log('Remaining:', result.subscription.remainingMeetings);
  }
  
  console.log('Error Exists:', !!result.error);
  if (result.error) console.log('Error:', result.error);
  console.groupEnd();
};

export function SpeechToTextComponent() {

  //loglama sistemi
  if (typeof window !== 'undefined') {
    const originalConsoleError = window.console.error;
    const originalNotifyFailure = microsoftTeams.app.notifyFailure;
   
    window.console.error = async function(...args) {
      originalConsoleError.apply(window.console, args);
      
      try {
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType || 'unknown';
        const teamContext = context?.page?.frameContext;
        
        await fetch(`${API_BASE_URL}/api/logs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            message: args.join(' '),
            level: 'error',
            timestamp: new Date().toISOString(),
            clientType: clientType,
            type: 'error', 
            endpoint: window.location.pathname,
            status: 500,
            method: 'POST',
            headers: {
              'user-agent': clientType === 'android' || clientType === 'ios' || clientType === 'mobile' ? 
                'Teams-Mobile' : 'Teams-Desktop',
              'content-type': 'application/json'
            },
            body: {
              userId: userInfo?.id || 'unknown',
              meetingId: meetingInfo?.id || 'unknown',
              errorContext: JSON.stringify({
                error: {
                  message: args.join(' '),
                  code: 500
                },
                context: {
                  location: window.location.href,
                  userAgent: navigator?.userAgent,
                  timestamp: new Date().toISOString(),
                  teamsContext: {
                    clientType: clientType,
                    frameContext: teamContext,
                    hostClientType: context?.app?.host?.name
                  },
                  applicationState: {
                    isListening,
                    micPermission,
                    isTeamsClient,
                    speechServiceReady,
                    isMeetingActive,
                    selectedLanguage,
                    initializeTeams,
                    apiCall,
                    logError,
                    checkTeamsContext,
                    checkSpeechService,
                    translateText,
                    handleLanguageChange,
                    checkMicrophonePermission,
                    fetchNewMessages,
                    displayNextMessage,
                    cleanupRecognizers,
                    stopListening,
                    processRecognitionResult,
                    initializeSpeechRecognition,
                    startListening,
                    handleReportClick,
                    handleReportSubmit,
                    generateMeetingSummary
                  }
                }
              })
            }
          })
        });
      } catch (err) {
        originalConsoleError.apply(window.console, ['Log gönderimi başarısız:', err]);
      }
    };
   
    microsoftTeams.app.notifyFailure = async function(failureInfo) {
      originalNotifyFailure.apply(microsoftTeams.app, [failureInfo]);
      
      try {
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType || 'unknown';
        const teamContext = context?.page?.frameContext;
        
        await fetch(`${API_BASE_URL}/api/logs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            message: `Teams Failure: ${failureInfo.message}`,
            level: 'error',
            timestamp: new Date().toISOString(),
            clientType,
            type: 'error',
            endpoint: window.location.pathname,
            status: 500,
            method: 'POST',
            headers: {
              'user-agent': clientType === 'android' || clientType === 'ios' || clientType === 'mobile' ? 
                'Teams-Mobile' : 'Teams-Desktop',
              'content-type': 'application/json'
            },
            body: {
              userId: userInfo?.id || 'unknown',
              meetingId: meetingInfo?.id || 'unknown',
              errorContext: JSON.stringify({
                error: {
                  message: failureInfo.message,
                  code: 500,
                  teamsFailureReason: failureInfo.reason
                },
                context: {
                  location: window.location.href,
                  userAgent: navigator?.userAgent,
                  timestamp: new Date().toISOString(),
                  teamsContext: {
                    clientType,
                    frameContext: teamContext,
                    hostClientType: context?.app?.host?.name
                  },
                  applicationState: {
                    isListening,
                    micPermission,
                    isTeamsClient,
                    speechServiceReady,
                    isMeetingActive,
                    selectedLanguage,
                    initializeTeams,
                    apiCall,
                    logError,
                    checkTeamsContext,
                    checkSpeechService,
                    translateText,
                    handleLanguageChange,
                    checkMicrophonePermission,
                    fetchNewMessages,
                    displayNextMessage,
                    cleanupRecognizers,
                    stopListening,
                    processRecognitionResult,
                    initializeSpeechRecognition,
                    startListening,
                    handleReportClick,
                    handleReportSubmit,
                    generateMeetingSummary
                  }
                }
              })
            }
          })
        });
      } catch (err) {
        originalConsoleError.apply(window.console, ['Teams failure log gönderimi başarısız:', err]);
      }
    };
   }

  // State tanımlamaları
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState('');
  const [micPermission, setMicPermission] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('tr-TR');
  const [userName, setUserName] = useState(DEFAULT_USER_NAME);
  const [userInfo, setUserInfo] = useState(null);
  const [meetingInfo, setMeetingInfo] = useState(null);
  const [isMeetingActive, setIsMeetingActive] = useState(true);
  const [isTeamsClient, setIsTeamsClient] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [speechServiceReady, setSpeechServiceReady] = useState(false);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [lastFetchedMessageId, setLastFetchedMessageId] = useState(0);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [isMessagePollingActive, setIsMessagePollingActive] = useState(false);
  const [setIsMobileClient] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reportReason, setReportReason] = useState('');
  const [reportDescription, setReportDescription] = useState('');
  const [theme, setTheme] = useState('default');
  const [selectedIndustry, setSelectedIndustry] = useState('technology'); // Yeni: Seçili sektör
  const [selectedDepartment, setSelectedDepartment] = useState('it'); // Yeni: Seçili departman
  const [subscriptionInfo, setSubscriptionInfo] = useState(null); // Yeni: Abonelik bilgileri
  const [isLimitExceeded, setIsLimitExceeded] = useState(false); // Yeni: Limit aşıldı mı?
  const [reportInfo, setReportInfo] = useState(null); //yenı: rapor bilgileri
  const [isReportLimitExceeded, setIsReportLimitExceeded] = useState(false); //yenı: rapor limiti aşıldı mı?
  const [isSpeaking, setIsSpeaking] = useState(false); // Yeni: Konuşma durumu
  const [audioEnabled, setAudioEnabled] = useState(false); // Yeni: Sesli yanıt etkin mi?
  const [translatingUser, setTranslatingUser] = useState(null); // Yeni: Konuşma çevriliyor.
  const [setMeetingParticipants] = useState([]); // yeni katılımcıları geçme
  const [currentSpeaker, setCurrentSpeaker] = useState(null); //Yeni son konuşmacıyı çekme fonksiyonu
  const [encryptionData, setEncryptionData] = useState(null); //şifreleme için
  const [sectors, setSectors] = useState(getLocalizedIndustrySectors(selectedLanguage));
  const [departments, setDepartments] = useState(getLocalizedDepartments(selectedLanguage));



  // Refs
  const recognizers = useRef({});
  const isProcessing = useRef(false);
  const isTeamsInitialized = useRef(false);
  const isTeamsMeetingInitialized = useRef(true);
  const messageQueueRef = useRef([]);
  const displayTimeoutRef = useRef(null);
  const currentMeetingDataRef = useRef(null);
  const isMeetingActiveRef = useRef(true);
  const initializationTimeout = useRef(null);
  const messagePollingInterval = useRef(null);
  const isTtsSpeakingRef = useRef(false);


  // Dil değiştiğinde güncelleme:
  useEffect(() => {
    setSectors(getLocalizedIndustrySectors(selectedLanguage));
    setDepartments(getLocalizedDepartments(selectedLanguage));
  }, [selectedLanguage]);


  // Şifreleme fonksiyonu - toplantıya özel anahtar kullanır
  const encryptText = useCallback((text) => {
    try {
      if (!text || !encryptionData) return text;
      
      // Basit bir şifreleme için, text'i base64'e çeviriyoruz
      // Gerçek uygulamada daha güvenli bir yöntem kullanmalısınız
      const base64Text = window.btoa(unescape(encodeURIComponent(text)));
      
      // Şifreleme anahtarı bilgisini ekliyoruz (kimlik doğrulama için)
      const encryptedFormat = `ENCRYPTED:${encryptionData.key.substring(0, 5)}:${base64Text}`;
      
      return encryptedFormat;
    } catch (error) {
      console.error('Şifreleme hatası:', error);
      return text; // Hata durumunda orijinal metni döndür
    }
  }, [encryptionData]);

// Şifrelenmiş metni çözme fonksiyonu - toplantıya özel anahtar kullanır
const decryptText = useCallback((encryptedText) => {
  try {
    if (!encryptedText || !encryptionData) return encryptedText;
    
    // Şifrelenmiş formatı kontrol et
    if (!encryptedText.startsWith('ENCRYPTED:')) {
      return encryptedText; // Şifrelenmiş formatta değilse olduğu gibi döndür
    }
    
    // Format: ENCRYPTED:KEYHASH:BASE64TEXT
    const parts = encryptedText.split(':');
    if (parts.length !== 3) {
      return encryptedText;
    }
    
    // Şifreyi çöz (base64'ten normale)
    const base64Text = parts[2];
    const decodedText = decodeURIComponent(escape(window.atob(base64Text)));
    
    return decodedText;
  } catch (error) {
    console.error('Şifre çözme hatası:', error);
    return encryptedText; // Hata durumunda şifreli metni döndür
  }
}, [encryptionData]);


  useEffect(() => {
  const initializeTheme = async () => {
    try {
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      setTheme(context.app.theme || 'default');

      // Tema değişikliğini dinle
      microsoftTeams.app.registerOnThemeChangeHandler((newTheme) => {
        setTheme(newTheme);
      });
    } catch (err) {
      console.error('Theme initialization error:', err);
    }
  };

  initializeTheme();
}, []);

const themeColors = {
  default: {
    background: '#f8f9fa',
    cardBg: '#ffffff',
    text: '#333333',
    border: '#dee2e6',
    navBg: '#ffffff',
    primary: '#0078D4',
    messageHeader: 'rgba(0, 120, 212, 0.1)',
    muted: '#6c757d',
    shadow: 'rgba(0, 0, 0, 0.1)',
    modalBg: '#ffffff'
  },
  dark: {
    background: '#201F1F',
    cardBg: '#2D2C2C',
    text: '#ffffff',
    border: '#484644',
    navBg: '#2D2C2C',
    primary: '#6264A7',
    messageHeader: 'rgba(98, 100, 167, 0.2)', // Daha görünür hale getirildi
    muted: '#E0E0E0', // Daha görünür/beyaza yakın yapıldı
    shadow: 'rgba(0, 0, 0, 0.25)',
    modalBg: '#2D2C2C'
  },
  contrast: {
    background: '#000000',
    cardBg: '#000000',
    text: '#ffffff',
    border: '#ffffff',
    navBg: '#000000',
    primary: '#ffffff',
    messageHeader: 'rgba(255, 255, 255, 0.2)', // Daha görünür hale getirildi
    muted: '#ffffff',
    shadow: 'rgba(255, 255, 255, 0.25)',
    modalBg: '#000000'
  }
};
  

 // API çağrı yardımcı fonksiyonu
  const apiCall = async (endpoint, options = {}) => {
    try {
      console.log(`API çağrısı yapılıyor: ${endpoint}`, options);

      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        ...options,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json; charset=UTF-8',
          'Accept-Charset': 'UTF-8',
          ...options.headers
        }
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      console.log(`API yanıtı alındı: ${endpoint}`, data);
      return data;
    } catch (error) {
      console.error(`API Hatası (${endpoint}):`, error);
      throw error;
    }
  };

  
  // Error Durumlarını api ile kaydetme
  const logError = useCallback(async (error, context = {}) => {
    try {
      let clientType = 'unknown';
      let teamContext = null;
  
      try {
        await microsoftTeams.app.initialize();
        teamContext = await microsoftTeams.app.getContext();
        clientType = teamContext?.app?.host?.clientType || 'unknown';
      } catch (e) {
        console.warn('Teams context could not be retrieved for error logging:', e);
      }
  
      const errorLog = {
        type: 'error',  // Her zaman error olarak işaretle
        endpoint: window.location.pathname,
        method: context?.method || 'POST',
        headers: {
          'user-agent': clientType === 'android' || clientType === 'ios' ? 
            'Teams-Mobile' : 'Teams-Desktop',
          'content-type': 'application/json'
        },
        body: {
          userId: userInfo?.id || 'unknown',
          meetingId: meetingInfo?.id || 'unknown',
          errorContext: JSON.stringify({
            error: {
              message: error?.message,
              stack: error?.stack,
              name: error?.name,
              code: error?.code
            },
            context: {
              ...context,
              location: window.location.href,
              userAgent: navigator?.userAgent,
              timestamp: new Date().toISOString(),
              teamsContext: teamContext ? {
                clientType: teamContext?.app?.host?.clientType,
                frameContext: teamContext?.page?.frameContext,
                hostClientType: teamContext?.app?.host?.name
              } : null,
              applicationState: {
                isListening,
                micPermission,
                isTeamsClient,
                speechServiceReady,
                isMeetingActive,
                selectedLanguage
              }
            }
          })
        },
        status: error?.status || 500,
        clientType: clientType,
        error: error?.message || 'Unknown error',
        severity: context?.severity || 'error',
        componentName: context?.componentName || 'unknown',
        functionName: context?.functionName || 'unknown'
      };
  
      console.log('Sending error log:', errorLog);
  
      await apiCall('/api/logs', {
        method: 'POST',
        body: JSON.stringify(errorLog)
      });
    } catch (err) {
      console.error('Failed to send error log:', err);
    }
  }, [userInfo, meetingInfo, isListening, micPermission, isTeamsClient, speechServiceReady, isMeetingActive, selectedLanguage]);

  // toplantı katılımcılarını alma fonksiyonu
  const getMeetingParticipants = async () => {
    try {
      if (!meetingInfo?.id) {
        return;
      }
  
      const response = await apiCall(`/api/participants?meetingId=${encodeURIComponent(meetingInfo.id)}`, {
        method: 'GET'
      });
  
      if (response.success && response.participants) {
        setMeetingParticipants(response.participants);
      }
  
    } catch (error) {
      console.error('Error getting meeting participants:', error.message);
    }
  };
  
  // useEffect'i basitleştirelim
  useEffect(() => {
    if (meetingInfo?.id) {
      getMeetingParticipants();
    }
  }, [meetingInfo?.id]);

  // Dile göre uygun sesi seç
  const voiceMap = {
    'tr-TR': { female: 'tr-TR-EmelNeural', male: 'tr-TR-AhmetNeural' },
    'en-US': { female: 'en-US-JennyNeural', male: 'en-US-GuyNeural' },
    'fr-FR': { female: 'fr-FR-DeniseNeural', male: 'fr-FR-HenriNeural' },
    'de-DE': { female: 'de-DE-KatjaNeural', male: 'de-DE-ConradNeural' },
    'es-ES': { female: 'es-ES-ElviraNeural', male: 'es-ES-AlvaroNeural' },
    'it-IT': { female: 'it-IT-ElsaNeural', male: 'it-IT-DanteNeural' },
    'ru-RU': { female: 'ru-RU-SvetlanaNeural', male: 'ru-RU-DmitryNeural' },
    'zh-CN': { female: 'zh-CN-XiaoxiaoNeural', male: 'zh-CN-YunxiNeural' },
    'ja-JP': { female: 'ja-JP-NanamiNeural', male: 'ja-JP-KeitaNeural' },
    'ar-SA': { female: 'ar-SA-ZariyahNeural', male: 'ar-SA-HamedNeural' },
    'pt-PT': { female: 'pt-PT-RaquelNeural', male: 'pt-PT-DuarteNeural' },
    'el-GR': { female: 'el-GR-AthinaNeural', male: 'el-GR-NestorasNeural' },
    'he-IL': { female: 'he-IL-HilaNeural', male: 'he-IL-AvriNeural' },
    'bg-BG': { female: 'bg-BG-KalinaNeural', male: 'bg-BG-BorisNeural' },
    'cs-CZ': { female: 'cs-CZ-VlastaNeural', male: 'cs-CZ-AntoninNeural' },
    'da-DK': { female: 'da-DK-ChristelNeural', male: 'da-DK-JeppeNeural' },
    'fi-FI': { female: 'fi-FI-SelmaNeural', male: 'fi-FI-HarriNeural' },
    'hu-HU': { female: 'hu-HU-NoemiNeural', male: 'hu-HU-TamasNeural' },
    'ko-KR': { female: 'ko-KR-SunHiNeural', male: 'ko-KR-InJoonNeural' },
    'ms-MY': { female: 'ms-MY-YasminNeural', male: 'ms-MY-OsmanNeural' },
    'pl-PL': { female: 'pl-PL-AgnieszkaNeural', male: 'pl-PL-MarekNeural' },
    'ro-RO': { female: 'ro-RO-AlinaNeural', male: 'ro-RO-EmilNeural' },
    'sv-SE': { female: 'sv-SE-SofieNeural', male: 'sv-SE-MattiasNeural' },
    'th-TH': { female: 'th-TH-PremwadeeNeural', male: 'th-TH-NiwatNeural' },
    'uk-UA': { female: 'uk-UA-PolinaNeural', male: 'uk-UA-OstapNeural' },
    'vi-VN': { female: 'vi-VN-HoaiMyNeural', male: 'vi-VN-NamMinhNeural' }
  };

  // Cache için yardımcı sınıf
class GenderCache {
  constructor(capacity = 100) {
    this.capacity = capacity;
    this.cache = new Map();
  }

  get(name) {
    return this.cache.get(name);
  }

  set(name, gender) {
    if (this.cache.size >= this.capacity) {
      const firstKey = this.cache.keys().next().value;
      this.cache.delete(firstKey);
    }
    this.cache.set(name, gender);
  }
}


  // Text to Speech fonksiyonu
  const textToSpeech = async (text, language, speakerGender = 'female', speakerName) => {
    try {
      // Ses tanımayı durdur
      const currentRecognizer = recognizers.current[selectedLanguage];
      if (currentRecognizer && isListening) {
        await new Promise(resolve => {
          currentRecognizer.stopContinuousRecognitionAsync(
            () => {
              console.log('TTS başlarken ses tanıma durduruldu');
              resolve();
            },
            (error) => {
              console.warn('Ses tanıma durdurma hatası:', error);
              resolve();
            }
          );
        });
      }
      
      // Konuşma başlıyor
      isTtsSpeakingRef.current = true;
      setIsSpeaking(true);
      
      const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
      speechConfig.speechSynthesisLanguage = language;
      
      // Cinsiyet kontrolü
      if (!['male', 'female'].includes(speakerGender)) {
        speakerGender = 'female';
      }
      
      // Ses seçimi
      speechConfig.speechSynthesisVoiceName = voiceMap[language][speakerGender];
      
      const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();
      const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
  
      // İlk olarak konuşmacı adını seslendir
      if (speakerName) {
        const firstName = getFirstName(speakerName);
        const introText = getIntroText(firstName, language); // Dile göre intro metni
        
        await new Promise((resolve, reject) => {
          synthesizer.speakTextAsync(
            introText,
            result => {
              if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                resolve();
              } else {
                reject(new Error('Intro speech synthesis failed'));
              }
            },
            error => {
              reject(error);
            }
          );
        });
  
        // 1 saniye bekle
        await sleep(1000);
      }
  
      // Ana metni seslendir
      const result = await new Promise((resolve, reject) => {
        synthesizer.speakTextAsync(
          text,
          result => {
            if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
              setIsSpeaking(false);
              synthesizer.close();
              resolve(result);
            } else {
              synthesizer.close();
              reject(new Error('Main speech synthesis failed'));
            }
          },
          error => {
            console.error('Speech synthesis error:', error);
            setIsSpeaking(false);
            synthesizer.close();
            reject(error);
          }
        );
      });
      
      // TTS bitiminden sonra kısa bir gecikme ekle (yankı sönümleme için)
      await new Promise(resolve => setTimeout(resolve, 800));
      isTtsSpeakingRef.current = false;
      
      // Eğer hala dinleme modundaysak, ses tanımayı tekrar başlat
      if (isListening && currentRecognizer) {
        currentRecognizer.startContinuousRecognitionAsync(
          () => console.log('TTS bittikten sonra ses tanıma yeniden başlatıldı'),
          (error) => console.warn('Ses tanıma yeniden başlatma hatası:', error)
        );
      }
      
      return result;
    } catch (error) {
      console.error('Text to speech error:', error);
      setIsSpeaking(false);
      isTtsSpeakingRef.current = false;
      
      // Hata durumunda da ses tanımayı yeniden başlatmayı dene
      const currentRecognizer = recognizers.current[selectedLanguage];
      if (isListening && currentRecognizer) {
        try {
          await new Promise(resolve => {
            currentRecognizer.startContinuousRecognitionAsync(
              () => {
                console.log('Hata sonrası ses tanıma yeniden başlatıldı');
                resolve();
              },
              (error) => {
                console.warn('Hata sonrası ses tanıma başlatma hatası:', error);
                resolve();
              }
            );
          });
        } catch (recognizerError) {
          console.error('Recognizer başlatma hatası:', recognizerError);
        }
      }
      
      throw error;
    }
  };

  // Son konuşmacıyı getiren fonksiyon
  const getLastSpeaker = useCallback(async () => {
    try {
      const currentMeeting = currentMeetingDataRef.current;
      if (!currentMeeting?.id) {
        console.log('Meeting ID yok, son konuşmacı getirilemedi');
        return;
      }
  
      console.log('Son konuşmacı alınıyor, meeting ID:', currentMeeting.id);
  
      // Mevcut speech-records endpoint'ini kullan
      const response = await apiCall(`/api/speech-records?meetingId=${currentMeeting.id}`, {
        method: 'GET'
      });
      
      console.log('Son konuşmacı API yanıtı:', response);
  
      if (response.success && response.record) {
        console.log('Son konuşmacı bulundu:', response.record.userName);
        setCurrentSpeaker(response.record.userName);
      }
    } catch (error) {
      console.error('Son konuşmacı bilgisi alınamadı:', error);
    }
  }, []);
  
  // useEffect ile translatingUser değiştiğinde son konuşmacıyı al
  useEffect(() => {
    if (translatingUser) {
      getLastSpeaker();
    } else {
      setCurrentSpeaker(null);
    }
  }, [translatingUser, getLastSpeaker]);

  // Dile göre intro metni oluşturma
const getIntroText = (firstName, language) => {
  const introTexts = {
    'tr-TR': `${firstName} diyor ki`,
    'en-US': `${firstName} says`,
    'fr-FR': `${firstName} dit`,
    'de-DE': `${firstName} sagt`,
    'es-ES': `${firstName} dice`,
    'it-IT': `${firstName} dice`,
    'ru-RU': `${firstName} говорит`,
    'zh-CN': `${firstName}说`,
    'ja-JP': `${firstName}が言います`,
    'ar-SA': `${firstName} يقول`,
    'pt-PT': `${firstName} diz`,
    'el-GR': `${firstName} λέει`,
    'he-IL': `${firstName} אומר`,
    'bg-BG': `${firstName} казва`,
    'cs-CZ': `${firstName} říká`,
    'da-DK': `${firstName} siger`,
    'fi-FI': `${firstName} sanoo`,
    'hu-HU': `${firstName} mondja`,
    'ko-KR': `${firstName}이(가) 말합니다`,
    'ms-MY': `${firstName} berkata`,
    'pl-PL': `${firstName} mówi`,
    'ro-RO': `${firstName} spune`,
    'sv-SE': `${firstName} säger`,
    'th-TH': `${firstName} พูดว่า`,
    'uk-UA': `${firstName} каже`,
    'vi-VN': `${firstName} nói`
  };

  return introTexts[language] || introTexts['en-US']; // Desteklenmeyen diller için İngilizce kullan
};

  // Gender tespiti için yeni fonksiyon
  const detectGender = async () => {
    try {
        // processRecognitionResult'ta kullandığımız gibi userInfo'dan userName'i alalım
        const userName = userInfo?.displayName || userInfo?.userPrincipalName || 'Unknown User';
        
        console.log('Starting gender detection for Teams user:', userName);

        const response = await apiCall('/api/gender-detection', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: userName
            })
        });

        if (response.success && response.gender) {
            return response.gender;
        }
        
        return 'female';

    } catch (error) {
        console.error('Gender detection error:', error);
        return 'female';
    }
  };

  // Cache instance'ı
  const genderCache = new GenderCache();



  // Yeni handler fonksiyonları - sektör ve departmanlar için
    const handleIndustryChange = async (e) => {
      const newIndustry = e.target.value;
      setSelectedIndustry(newIndustry);
      
      try {
        if (meetingInfo?.id && userInfo?.id) {
          await apiCall('/api/user-preferences', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              meetingId: meetingInfo.id,
              userId: userInfo.id,
              industry: newIndustry
            })
          });
          
          console.log('Sektör tercihi güncellendi:', newIndustry);
        }
      } catch (error) {
        console.error('Sektör değiştirme hatası:', error);
        setError('Sektör tercihi kaydedilemedi: ' + error.message);
      }
    };

    const handleDepartmentChange = async (e) => {
      const newDepartment = e.target.value;
      setSelectedDepartment(newDepartment);
      
      try {
        if (meetingInfo?.id && userInfo?.id) {
          await apiCall('/api/user-preferences', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              meetingId: meetingInfo.id,
              userId: userInfo.id,
              department: newDepartment
            })
          });
          
          console.log('Departman tercihi güncellendi:', newDepartment);
        }
      } catch (error) {
        console.error('Departman değiştirme hatası:', error);
        setError('Departman tercihi kaydedilemedi: ' + error.message);
      }
    };



    // Teams kontrolü
  const checkTeamsContext = useCallback(async () => {
    try {
      console.log('Teams context kontrolü başlatılıyor...');
              
        // Teams başlatma
        await microsoftTeams.app.initialize();
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType;
        const frameContext = context?.page?.frameContext;
        
        console.log('Teams Context Details:', {
            clientType,
            frameContext,
            context
        });

        // Web client için özel kontrol
        if (clientType === "web" && frameContext === "sidePanel") {
            console.log('Teams Web Client tespit edildi');
            setIsTeamsClient(true);
            return true;
        }
        // Desktop/Mobile client kontrolü
        else if (clientType === "desktop" || clientType === "android" || clientType === "bios" || clientType === "mobile") {
            console.log('Teams Desktop/Mobile Client tespit edildi');
            setIsTeamsClient(true);
            return true;
        }

        // Context var ama tip bilinmiyorsa da true döndür
        if (context) {
            console.log('Teams context bulundu (tip bilinmiyor)');
            setIsTeamsClient(true);
            return true;
        }

        console.warn('Teams context bulunamadı');
        setIsTeamsClient(false);
        return false;

    } catch (err) {
        console.error('Teams context hatası:', err);
        setError(`Teams context hatası: ${err.message}. Lütfen Teams uygulamasında olduğunuzdan emin olun.`);
        logError(err, 'Teams context hatası');
        setIsTeamsClient(false);
        return false;
    }
}, []);

  // Speech servis kontrolü
  const checkSpeechService = useCallback(async () => {
    try {
      console.log('Speech servis kontrolü başlatılıyor...');
      const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
      if (speechConfig) {
        console.log('Speech servis hazır');
        setSpeechServiceReady(true);
        return true;
      }
      return false;
    } catch (err) {
      console.error('Speech servis hatası:', err);
      setSpeechServiceReady(false);
      return false;
    }
  }, []);

  // Çeviri fonksiyonu
  const translateText = useCallback(async (text, fromLang, toLang) => {
    if (!text?.trim()) return '';
    try {
      console.log('Çeviri başlatılıyor:', { text, fromLang, toLang });
      const response = await fetch(
        `https://${TRANSLATOR_REGION}.api.cognitive.microsoft.com/translator/text/v3.0/translate?api-version=3.0&from=${fromLang}&to=${toLang}`,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': TRANSLATOR_KEY,
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Region': TRANSLATOR_REGION
          },
          body: JSON.stringify([{ Text: text }])
        }
      );

      const data = await response.json();
      console.log('Çeviri tamamlandı:', data);
      return data[0]?.translations[0]?.text || 'Çeviri başarısız';
    } catch (error) {
      console.warn('Çeviri hatası:', error);
      return 'Çeviri başarısız';
    }
  }, []);

  // Dil değişikliği handler'ı
  const handleLanguageChange = async (e) => {
    if (isListening) return;
    
    const newLanguage = e.target.value;
    console.log('Dil değiştiriliyor:', { newLanguage, meetingInfo, userInfo });
  
    try {
      if (meetingInfo?.id && userInfo?.id) {
        await apiCall('/api/user-preferences', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            meetingId: meetingInfo.id,
            userId: userInfo.id,
            languageId: newLanguage
          })
        });
  
        setSelectedLanguage(newLanguage);
        
        if (isMessagePollingActive) {
          setLastFetchedMessageId(0);
          setDisplayedMessages([]);
          messageQueueRef.current = [];
          await fetchNewMessages();
        }
  
        console.log('Dil tercihi güncellendi:', newLanguage);
      } else {
        console.error('Meeting veya user bilgisi eksik:', { meetingInfo, userInfo });
      }
    } catch (error) {
      console.error('Dil değiştirme hatası:', error);
      setError('Dil tercihi kaydedilemedi: ' + error.message);
    }
  };

  // Mikrofon kontrolü
  const checkMicrophonePermission = useCallback(async () => {
    try {

        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
                
                stream.getTracks().forEach(track => track.stop());
                setMicPermission(true);
                setError('');
                
        // Başlangıç loglama
        logError(new Error('Checking microphone permission...'), {
            functionName: 'checkMicrophonePermission',
            details: { currentPermission: micPermission }
        });
        
        await microsoftTeams.app.initialize();
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType;
        const frameContext = context?.page?.frameContext;
        const isTeamsWeb = clientType === "web";
        const isIOS = clientType === "ios";
        
        // Platform tespiti logu
        logError(new Error('Platform detection'), {
            functionName: 'checkMicrophonePermission',
            status: 'info',
            details: {
                clientType,
                isTeamsWeb,
                isIOS,
                frameContext,
                userAgent: navigator.userAgent
            }
        });
 
        // iOS için özel kontrol
        if (isIOS && frameContext === "sidePanel") {
          try {
              // Önce izin durumunu kontrol et
              setMicPermission(true);
              const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
              
              logError(new Error('Checking IOS and sidePanel microphone permission status'), {
                  functionName: 'checkMicrophonePermission',
                  status: 'info',
                  details: { 
                      initialPermissionState: permissionStatus.state,
                      platform: 'iOS'
                  }
              });
      
              if (permissionStatus.state === 'granted') {
                  // Burayı ekliyoruz - state güncelleme
                  setMicPermission(true);
                  setError('');
                  
                  logError(new Error('Using existing microphone permission'), {
                      functionName: 'checkMicrophonePermission',
                      status: 'success',
                      details: { 
                          permissionState: 'granted',
                          fromCache: true
                      }
                  });
                  
                  return true;
              }
      
              // İzin yoksa veya prompt durumundaysa getUserMedia ile iste
              const stream = await navigator.mediaDevices.getUserMedia({audio: true});
              
              stream.getTracks().forEach(track => track.stop());
              setMicPermission(true);
              setError('');
              
              logError(new Error('iOS microphone permission granted'), {
                  functionName: 'checkMicrophonePermission',
                  status: 'success'
              });
              
              return true;
      
          } catch (browserError) {
              console.error('iOS Browser API hatası:', browserError);
              setMicPermission(false);
              setError('Mikrofon izni reddedildi: ' + browserError.message);
              
              logError(browserError, {
                  functionName: 'checkMicrophonePermission',
                  status: 'error',
                  details: { context: 'iOS browser API' }
              });
              
              throw browserError;
          }
      }
        // iOS ve diğer context'ler için Teams API kullanımı
        else if (isIOS) {
            try {
                const mediaPermission = await microsoftTeams.media.requestPermission("microphone");
                console.log('Teams iOS media permission:', mediaPermission);
                
                if (!mediaPermission) {
                    throw new Error('Teams media permission denied');
                }
 
                const mediaInput = {
                    mediaType: microsoftTeams.media.MediaType.Audio,
                    maxMediaCount: 1,
                    audioProps: {
                        source: microsoftTeams.media.Source.Microphone,
                    }
                };
 
                return new Promise((resolve, reject) => {
                    microsoftTeams.media.selectMedia(mediaInput, (error, result) => {
                        if (error) {
                            console.error('iOS media selection error:', error);
                            setMicPermission(false);
                            setError('iOS mikrofon erişimi hatası: ' + error.message);
                            reject(error);
                            return;
                        }
 
                        if (result && result.length > 0) {
                            console.log('iOS media selection successful:', result);
                            setMicPermission(true);
                            setError('');
                            resolve(true);
                        } else {
                            const noMediaError = new Error('Mikrofon seçilmedi');
                            setMicPermission(false);
                            setError(noMediaError.message);
                            reject(noMediaError);
                        }
                    });
                });
 
            } catch (teamsError) {
                console.error('Teams iOS API hatası:', teamsError);
                setMicPermission(false);
                setError('Teams iOS API hatası: ' + teamsError.message);
                
                logError(teamsError, {
                    functionName: 'checkMicrophonePermission',
                    status: 'error',
                    details: { context: 'Teams iOS API' }
                });
                
                throw teamsError;
            }
        }
        // Web/Desktop için mevcut işleyiş
        else {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                        autoGainControl: true
                    }
                });
                
                stream.getTracks().forEach(track => track.stop());
                setMicPermission(true);
                setError('');
                
                logError(new Error('Web/Desktop microphone permission granted'), {
                    functionName: 'checkMicrophonePermission',
                    status: 'success',
                    details: { platform: isTeamsWeb ? 'web' : 'desktop' }
                });
                
                return true;
 
            } catch (error) {
                console.error('Mikrofon erişim hatası:', error);
                setMicPermission(false);
                setError('Mikrofon izni reddedildi: ' + error.message);
                
                logError(error, {
                    functionName: 'checkMicrophonePermission',
                    status: 'error',
                    details: { platform: isTeamsWeb ? 'web' : 'desktop' }
                });
                
                throw error;
            }
        }
 
    } catch (error) {
        console.error('Mikrofon izni genel hata:', error);
        setMicPermission(false);
        setError(error.message);
        
        logError(error, {
            functionName: 'checkMicrophonePermission',
            status: 'error',
            phase: 'general'
        });
        
        return false;
    }
 }, []);
  

  // Yeni mesajları getirme fonksiyonu
  const fetchNewMessages = useCallback(async () => {
    if (!meetingInfo?.id || !userInfo?.id) {
      console.log('Meeting veya user bilgisi eksik:', { meetingInfo, userInfo });
      return;
    }

    try {
      const response = await apiCall(`/api/messages?meetingId=${meetingInfo.id}&userId=${userInfo.id}&lastMessageId=${lastFetchedMessageId}`);
      
      console.log('Fetched messages:', response);

      // Eğer şifreleme anahtarlarımız yoksa, toplantı bilgilerini getir
      if (!encryptionData && response.success) {
        const meetingResponse = await apiCall(`/api/meeting-details?meetingId=${meetingInfo.id}`);
        
        if (meetingResponse.success && meetingResponse.meeting) {
          setEncryptionData({
            key: meetingResponse.meeting.encryptionKey,
            iv: meetingResponse.meeting.encryptionIV
          });
        }
      }

      if (response.success && response.messages?.length > 0) {
        messageQueueRef.current.push(...response.messages);
        setLastFetchedMessageId(response.metadata.lastMessageId);
      }
    } catch (error) {
      console.error('Message fetch error:', error);
    }
  }, [meetingInfo?.id, userInfo?.id, lastFetchedMessageId]);

  // Mesajları gösterme fonksiyonu
  const displayNextMessage = useCallback(async () => {
    if (messageQueueRef.current.length === 0) {
      fetchNewMessages();
      return;
    }
  
    const messages = messageQueueRef.current;
    messageQueueRef.current = [];
  
    for (const message of messages) {
      try {
        let gender = genderCache.get(message.userName);
        
        if (!gender) {
          gender = await detectGender(message.userName);
          genderCache.set(message.userName, gender);
        }
  
        setDisplayedMessages(prev => {
          const allMessages = [...prev];
          
          if (!allMessages.some(m => m.id === message.id)) {

            // Kaynak metni ve çevirileri çöz - encryptionData varsa
              const decryptedSourceText = encryptionData && message.sourceText 
              ? decryptText(message.sourceText) 
              : message.sourceText;
              
            // Çevirileri şifresi çöz
            const decryptedTranslations = message.translations.map(t => ({
              ...t,
              text: encryptionData ? decryptText(t.text) : t.text
            }));
  
  
            const enhancedMessage = {
              ...message,
              userGender: gender,
              sourceText: decryptedSourceText,
              translations: decryptedTranslations
            };
            
            allMessages.push(enhancedMessage);
            
            if (audioEnabled && message.userId !== userInfo?.id) {
              const translation = message.translations.find(
                t => t.languageId === selectedLanguage
              );
              
              if (translation) {
                // Çözülmüş çevirileri kullan
                const decryptedTranslation = decryptedTranslations.find(
                  t => t.languageId === selectedLanguage
                );
                
                if (decryptedTranslation) {
                  textToSpeech(
                    decryptedTranslation.text,
                    selectedLanguage,
                    gender,
                    message.userName
                  ).catch(error => console.error('TTS error:', error));
                }
              }
            }
          }
  
          return allMessages.sort((a, b) => 
            new Date(b.createdAt) - new Date(a.createdAt)
          );
        });
  
      } catch (error) {
        console.error('Message processing error:', error);
        
        setDisplayedMessages(prev => {
          const allMessages = [...prev];
          if (!allMessages.some(m => m.id === message.id)) {
            allMessages.push({
              ...message,
              userGender: 'female'
            });
          }
          return allMessages.sort((a, b) => 
            new Date(b.createdAt) - new Date(a.createdAt)
          );
        });
      }
    }
  
    displayTimeoutRef.current = setTimeout(() => {
      displayNextMessage();
    }, DISPLAY_DURATION);
  }, [fetchNewMessages, audioEnabled, selectedLanguage, userInfo?.id, decryptText, encryptionData]);
  

  // Speech Recognition temizleme
  const cleanupRecognizers = useCallback(() => {
    console.log('Recognizer\'lar temizleniyor...');
    Object.values(recognizers.current).forEach(recognizer => {
      if (recognizer) {
        try {
          recognizer.stopContinuousRecognitionAsync(
            () => {
              recognizer.close();
              console.log('Recognizer başarıyla temizlendi');
            },
            (err) => console.warn('Recognizer temizleme uyarısı:', err)
          );
        } catch (err) {
          console.warn('Recognizer temizleme hatası:', err);
        }
      }
    });
    recognizers.current = {};
  }, []);

  // Dinlemeyi durdur
  const stopListening = useCallback(async () => {
    try {
      if (!isListening) return;
      console.log('Dinleme durduruluyor...');
  
      const stopPromises = Object.values(recognizers.current).map(recognizer => {
        return new Promise((resolve) => {
          if (!recognizer) {
            resolve();
            return;
          }
          recognizer.stopContinuousRecognitionAsync(
            () => {
              console.log('Recognizer durduruldu');
              resolve();
            },
            (error) => {
              console.warn('Recognizer durdurma uyarısı:', error);
              resolve();
            }
          );
        });
      });
  
      await Promise.all(stopPromises);
      setIsListening(false);
      setIsMessagePollingActive(false);
  
      if (messagePollingInterval.current) {
        clearInterval(messagePollingInterval.current);
        messagePollingInterval.current = null;
      }
  
      console.log('Dinleme durduruldu');
      
    } catch (err) {
      console.error('Dinlemeyi durdurma hatası:', err);
      setIsListening(false);
      setIsMessagePollingActive(false);
    }
  }, [isListening]);

  // Konuşma işleme
  const processRecognitionResult = useCallback(async (lang, transcript, confidence) => {
  // Çeviri başlangıcında kullanıcıyı set et
  setTranslatingUser(userInfo?.displayName || userInfo?.userPrincipalName || 'Unknown User');
   
      logError(new Error('Starting speech processing'), {
        functionName: 'processRecognitionResult',
        status: 'info',
        details: {
            lang,
            transcript,
            confidence
        }
      });

    if (!transcript?.trim() || isProcessing.current) {
      logError(new Error('Speech processing skipped'), {
        functionName: 'processRecognitionResult',
        details: { 
          reason: !transcript?.trim() ? 'empty transcript' : 'already processing',
          transcript,
          confidence
        }
      });
      console.log('İşlem atlandı - boş metin veya işlem devam ediyor');
      return;
    }
  
    const currentMeeting = currentMeetingDataRef.current;
    if (!currentMeeting?.id) {
      console.error('Meeting bilgisi eksik');
      return;
    }
  
    isProcessing.current = true;
  
    try {
      const cleanText = transcript.trim();

      // Metni anlam kontrolünden geçir
      console.log('Metin anlam kontrolünden geçiriliyor:', cleanText);
      
      try {
        const meaningCheckResponse = await fetch(`${API_BASE_URL}/api/meaning-check`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            text: cleanText,
            language: lang.code,
            confidence: confidence
          })
        });
        
        if (!meaningCheckResponse.ok) {
          throw new Error(`Anlam kontrolü API hatası: ${meaningCheckResponse.status}`);
        }
        
        const meaningResult = await meaningCheckResponse.json();
        
        // Eğer metin anlamsız olarak değerlendirilirse, işlemi atla
        if (!meaningResult.isMeaningful) {
          console.log('Metin anlamsız olarak değerlendirildi, kaydedilmiyor:', cleanText);
          console.log('Anlam skoru:', meaningResult.meaningScore);
          console.log('Anlamsızlık nedeni:', meaningResult.reason);
          logError(new Error('Speech discarded due to lack of meaning'), {
            functionName: 'processRecognitionResult',
            status: 'info',
            details: { 
              text: cleanText,
              meaningScore: meaningResult.meaningScore,
              reason: meaningResult.reason
            }
          });
          isProcessing.current = false;
          setTranslatingUser(null);
          return;
        }
        
        console.log('Metin anlamlı olarak değerlendirildi, işlemler devam ediyor');
        
      } catch (meaningCheckError) {
        // Anlam kontrolü başarısız olursa, güvenli tarafta kalarak işlemi devam ettir
        console.error('Anlam kontrolü yapılamadı, metin yine de kaydediliyor:', meaningCheckError);
        logError(meaningCheckError, {
          functionName: 'processRecognitionResult',
          status: 'warning',
          details: { 
            phase: 'meaning_check',
            text: cleanText
          }
        });
      }

      // Metin anlamlı, normal işlemlere devam et
        console.log('Konuşma kaydediliyor:', {
        text: cleanText,
        meetingId: currentMeeting.id,
        userId: userInfo.id,
        userName: userInfo.displayName,
        lang: lang.code,
        confidence
      });

      // Metni şifrele - encryptionData varsa
      const sourceText = encryptionData 
      ? encryptText(cleanText) 
      : cleanText;
  
      const speechData = {
        meetingId: currentMeeting.id,
        userId: userInfo.id,
        userName: userInfo.displayName || userInfo.userPrincipalName || 'Unknown User',
        sourceText: sourceText,
        confidence: parseFloat(confidence),
        sourceLanguageId: lang.code,
        sourceWordCount: cleanText.split(/\s+/).length
      };
  
      const speechResponse = await apiCall('/api/speech-records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf8mb4'
        },
        body: JSON.stringify(speechData)
      });
  
      console.log('Speech kayıt yanıtı:', speechResponse);
  
      if (speechResponse.success && speechResponse.recordId) {
        await apiCall('/api/translations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf8mb4'
          },
          body: JSON.stringify({
            recordId: speechResponse.recordId,
            languageId: lang.code,
            translatedText: sourceText,
            meetingId: currentMeeting.id,
            wordCount: cleanText.split(/\s+/).length
          })
        });
  
        await Promise.all(SUPPORTED_LANGUAGES
          .filter(targetLang => targetLang.code !== lang.code)
          .map(async targetLang => {
            const translation = await translateText(
              cleanText,
              lang.shortCode,
              targetLang.shortCode
            );
  
            if (translation && translation !== 'Çeviri başarısız') {

            // Çeviriyi şifrele - encryptionData varsa
            const encryptedTranslation = encryptionData 
            ? encryptText(translation) 
            : translation;

              return apiCall('/api/translations', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json; charset=utf8mb4'
                },
                body: JSON.stringify({
                  recordId: speechResponse.recordId,
                  languageId: targetLang.code,
                  translatedText: encryptedTranslation,
                  meetingId: currentMeeting.id,
                  wordCount: translation.split(/\s+/).length
                })
              });
            }
          }));
          logError(new Error('Speech processing successful'), {
            functionName: 'processRecognitionResult',
            status: 'success',
            details: { 
              transcript, 
              confidence,
              recordId: speechResponse.recordId 
            }
          });
  
        console.log('Tüm çeviriler ve kaynak metin kaydedildi');
      }
    } catch (error) {
      console.error('İşlem hatası:', error);
      setError('Konuşma kaydedilemedi: ' + error.message);
      logError(new Error('İşlem hatası:', error), {
        functionName: 'processRecognitionResult',
        status: 'error',
        details: { transcript, confidence }
      });
      
    } finally {
      setTranslatingUser(null);
      isProcessing.current = false;
    }
  }, [userInfo, apiCall, logError, translateText, encryptText, encryptionData]);

  // Speech Recognition başlatma
  const initializeSpeechRecognition = useCallback(() => {
    return new Promise(async (resolve, reject) => {
        let clientType = null;
        let frameContext = null;
        let isIOS = false;

        try {
            // Teams context kontrolü
            await microsoftTeams.app.initialize();
            const context = await microsoftTeams.app.getContext();
            clientType = context?.app?.host?.clientType;
            frameContext = context?.page?.frameContext;
            isIOS = clientType === "ios";

            // Context kontrolü logu
            logError(new Error('Teams context check'), {
                functionName: 'initializeSpeechRecognition',
                status: 'info',
                details: {
                    clientType,
                    frameContext,
                    isIOS,
                    userAgent: navigator.userAgent
                }
            });

            // Meeting kontrolü
            if (!currentMeetingDataRef.current?.id) {
                logError(new Error('Meeting validation failed'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'error',
                    details: { currentMeeting: currentMeetingDataRef.current }
                });
                throw new Error('Meeting bilgisi eksik');
            }

            // Recognizer temizleme
            cleanupRecognizers();

            // Dil kontrolü
            const selectedLang = SUPPORTED_LANGUAGES.find(lang => lang.code === selectedLanguage);
            if (!selectedLang) {
                logError(new Error('Language validation failed'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'error',
                    details: { 
                        selectedLanguage,
                        availableLanguages: SUPPORTED_LANGUAGES.map(l => l.code)
                    }
                });
                throw new Error(`Desteklenmeyen dil: ${selectedLanguage}`);
            }

            // Speech Config yapılandırması
            logError(new Error('Creating speech config'), {
                functionName: 'initializeSpeechRecognition',
                status: 'info',
                phase: 'speech-config-start'
            });

            const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
            speechConfig.speechRecognitionLanguage = selectedLang.code;

            // iOS için özel ayarlar
            if (isIOS) {
                logError(new Error('Configuring iOS settings'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'info',
                    phase: 'ios-config'
                });

                logError(new Error('Creating audio config from default microphone'), {
                  functionName: 'initializeSpeechRecognition',
                  status: 'info',
                  phase: 'audio-config-start',
                  details: {
                      sdkVersion: sdk.version,
                      clientType: 'ios',
                      frameContext: 'sidePanel',
                      isIOS: true,
                      audioCapabilities: {
                          hasAudioContext: 'AudioContext' in window,
                          hasDefaultDevice: 'mediaDevices' in navigator && 'enumerateDevices' in navigator.mediaDevices
                      }
                  }
              });



                // AudioConfig konfigürasyonunu değiştirelim
                // const audioFormat = sdk.AudioStreamFormat.getWaveFormatPCM(44100, 16, 1);
                //audioConfig = sdk.AudioStreamFormat.getWaveFormatPCM(44100, 16, 1);
                audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

                logError(new Error('Audio config created successfully'), {
                  functionName: 'initializeSpeechRecognition',
                  status: 'success',
                  phase: 'audio-config-creation',
                  details: {
                      hasAudioConfig: !!audioConfig,
                      audioConfigProperties: {
                          properties: audioConfig.properties,
                          internalHandle: audioConfig.internalHandle,
                          deviceId: audioConfig.deviceId,
                          configType: 'defaultMicrophone'
                      }
                  }
              });
          

                speechConfig.setProperty(
                  sdk.PropertyId.Speech_LogFilename,
                  "speech_default.log"
              );
              
              speechConfig.enableAudioLogging = true;
              speechConfig.outputFormat = sdk.OutputFormat.Detailed;

              speechConfig.setProperty(
                  sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs,
                  "5000"
              );
              
              speechConfig.setProperty(
                  sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
                  "1500"
              );

              speechConfig.setProperty(
                  sdk.PropertyId.Speech_SegmentationSilenceTimeoutMs,
                  "2000"
              );      
              
              speechConfig.setProperty(
                sdk.PropertyId.SpeechServiceResponse_PostProcessingOption,
                "TrueText"
              );

              } 
            // Web/Desktop ayarları
            else {
                speechConfig.setProperty(
                    sdk.PropertyId.Speech_LogFilename,
                    "speech_default.log"
                );
                
                speechConfig.enableAudioLogging = true;
                speechConfig.outputFormat = sdk.OutputFormat.Detailed;

                speechConfig.setProperty(
                    sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs,
                    "5000"
                );
                
                speechConfig.setProperty(
                    sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
                    "1500"
                );

                speechConfig.setProperty(
                    sdk.PropertyId.Speech_SegmentationSilenceTimeoutMs,
                    "2000"
                );

                speechConfig.setProperty(
                  sdk.PropertyId.SpeechServiceResponse_PostProcessingOption,
                  "TrueText"
                );
            }

            // Genel ayarlar
            speechConfig.setProperty(
                sdk.PropertyId.SpeechServiceResponse_RequestSentenceBoundary,
                "true"
            );

            // Audio config oluşturma
            let audioConfig;
            try {
                logError(new Error('Creating audio configuration'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'info',
                    phase: 'audio-config-start',
                    details: { isIOS }
                });

                if (isIOS) {
                    // iOS için özel yapılandırma

                    logError(new Error('Creating audio config from default microphone'), {
                      functionName: 'initializeSpeechRecognition',
                      status: 'info',
                      phase: 'audio-config-start',
                      details: {
                          sdkVersion: sdk.version,
                          clientType: 'ios',
                          frameContext: 'sidePanel',
                          isIOS: true,
                          audioCapabilities: {
                              hasAudioContext: 'AudioContext' in window,
                              hasDefaultDevice: 'mediaDevices' in navigator && 'enumerateDevices' in navigator.mediaDevices
                          }
                      }
                  });

                  const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
        
                  // Genel timeout ayarları
                  speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "5000");
                  speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs, "1500");
                  speechConfig.setProperty(sdk.PropertyId.Speech_SegmentationSilenceTimeoutMs, "2000");

                  // Debug ve loglama ayarları
                  speechConfig.enableAudioLogging = true;
                  speechConfig.outputFormat = sdk.OutputFormat.Detailed;


                  audioConfig = sdk.AudioConfig.fromMicrophoneInput();
                  //audioConfig.setProperty("SPEECHSDK-AUDIO-INPUT-PROCESSING", "true");
            
                  // iOS için özel ses ayarları
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-STREAM", "true");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-VOLUME", "1.0");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-AUDIO-GAIN", "2.0");
                  
                  // iOS için ses parametreleri
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-SAMPLINGRATE", "16000");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-CHANNELS", "1");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-VAD-ENABLED", "true");
                  
                  // iOS için özel timeout değerleri
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-START-SILENCE-TIMEOUT-MS", "20000");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-END-SILENCE-TIMEOUT-MS", "10000");
                  speechConfig.setProperty("SPEECHSDK-SPEECH-CONFIG-SILENCE-THRESHOLD", "0.2");

                  // Audio config properties loglama
                  logError(new Error('IOS Audio config properties'), {
                      functionName: 'initializeSpeechRecognition',
                      status: 'info',
                      details: {
                          audioConfig: {
                              properties: audioConfig.properties,
                              format: speechConfig.outputFormat,
                              loggingEnabled: speechConfig.enableAudioLogging
                          }
                      }
                  });

                    logError(new Error('IOS Audio config created successfully'), {
                      functionName: 'initializeSpeechRecognition',
                      status: 'success',
                      phase: 'audio-config-creation',
                      details: {
                          hasAudioConfig: !!audioConfig,
                          audioConfigProperties: {
                              properties: audioConfig.properties,
                              internalHandle: audioConfig.internalHandle,
                              deviceId: audioConfig.deviceId,
                              configType: 'defaultMicrophone'
                          }
                      }
                  });

                  const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

                  recognizer.recognized = async (s, e) => {
                      logError(new Error('IOS Recognition event details'), {
                          functionName: 'recognizer.recognized', 
                          status: 'info',
                          details: {
                              audioLevel: recognizer.audioLevel,
                              audioState: recognizer.audioState,
                              results: e.result,
                              eventType: s.eventType,
                              recognitionPhase: 'content-recognition'
                          }
                      });

                      // ... mevcut recognized event handler kodu ...
                  };
              
                    
                    logError(new Error('iOS audio configuration created'), {
                        functionName: 'initializeSpeechRecognition',
                        status: 'success',
                        phase: 'ios-audio-config',
                        details: {
                            deviceId: 'default',
                            audioSettings: {
                                gain: speechConfig.getProperty("SPEECHSDK-AUDIO-INPUT-GAIN"),
                                channels: speechConfig.getProperty("SPEECHSDK-AUDIO-INPUT-CHANNELS"),
                                sampleRate: speechConfig.getProperty("SPEECHSDK-AUDIO-INPUT-SAMPLERATE")
                            }
                        }
                    });
                } else {

                  logError(new Error('Creating audio config from default microphone'), {
                    functionName: 'initializeSpeechRecognition',
                    status: 'info',
                    phase: 'audio-config-start',
                    details: {
                        sdkVersion: sdk.version,
                        clientType: 'ios',
                        frameContext: 'sidePanel',
                        isIOS: true,
                        audioCapabilities: {
                            hasAudioContext: 'AudioContext' in window,
                            hasDefaultDevice: 'mediaDevices' in navigator && 'enumerateDevices' in navigator.mediaDevices
                        }
                    }
                });
                    audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

                    logError(new Error('Audio config created successfully'), {
                      functionName: 'initializeSpeechRecognition',
                      status: 'success',
                      phase: 'audio-config-creation',
                      details: {
                          hasAudioConfig: !!audioConfig,
                          audioConfigProperties: {
                              properties: audioConfig.properties,
                              internalHandle: audioConfig.internalHandle,
                              deviceId: audioConfig.deviceId,
                              configType: 'defaultMicrophone'
                          }
                      }
                  });
              
                }
            } catch (audioError) {
                logError(audioError, {
                    functionName: 'initializeSpeechRecognition',
                    status: 'error',
                    phase: 'audio-config',
                    details: {
                        platform: isIOS ? 'iOS' : 'Web/Desktop',
                        error: audioError.message
                    }
                });

                logError(new Error('Default microphone audio config creation failed'), {
                  functionName: 'initializeSpeechRecognition',
                  status: 'error',
                  phase: 'audio-config-creation',
                  details: {
                      errorName: error.name,
                      errorCode: error.code,
                      errorMessage: error.message,
                      errorStack: error.stack,
                      sdkInfo: {
                          version: sdk.version,
                          features: sdk.Features ? Object.keys(sdk.Features) : [],
                          audioSourceTypes: sdk.AudioConfig ? Object.keys(sdk.AudioConfig) : []
                      },
                      systemInfo: {
                          platform: navigator.platform,
                          userAgent: navigator.userAgent,
                          vendor: navigator.vendor,
                          language: navigator.language
                      }
                  }
              });
                throw audioError;
            }

            // Recognizer oluşturma
            const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

            // iOS için event handler'lar
            if (isIOS) {
                recognizer.recognized = async (s, e) => {
                    logError(new Error('Recognized event triggered'), {
                        functionName: 'recognizer.recognized',
                        status: 'info',
                        details: { 
                            reason: e.result.reason,
                            resultText: e.result.text,
                            confidence: e.result.confidence,
                            rawResult: JSON.stringify(e.result)
                        }
                    });

                    try {
                        // RecognitionStatus'u kontrol edelim  
                        const jsonResult = JSON.parse(e.result.privJson || '{}');
                        
                        // Debug log ekleyelim
                        logError(new Error('Recognition status check'), {
                            functionName: 'recognizer.recognized',
                            status: 'info',
                            details: { 
                                status: jsonResult.RecognitionStatus,
                                text: jsonResult.DisplayText,
                                reason: sdk.ResultReason[e.result.reason],
                                duration: jsonResult.Duration,
                                offset: jsonResult.Offset
                            }
                        });

                        // Timeout kontrolü
                        if (jsonResult.RecognitionStatus === "InitialSilenceTimeout") {
                            logError(new Error('Initial silence timeout'), {
                                functionName: 'recognizer.recognized',
                                status: 'warning',
                                details: {
                                    timeoutDuration: jsonResult.Duration,
                                    timeoutOffset: jsonResult.Offset
                                }
                            });
                            return;
                        }

                          // Loglama
                          logError(new Error('Recognition event details'), {
                            functionName: 'recognizer.recognized',
                            status: 'info',
                            details: { 
                                audioLevel: recognizer.audioLevel,
                                audioState: recognizer.audioState,
                                results: e.result,
                                eventType: s.eventType
                            }
                        });

                        // Normal recognition işlemi
                        if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                            const transcript = e.result.text?.trim();
                            const confidence = e.result.confidence || 0.6;

                            logError(new Error('Speech content recognized'), {
                                functionName: 'recognizer.recognized', 
                                status: 'info',
                                details: {
                                    transcript,
                                    confidence,
                                    status: jsonResult.RecognitionStatus,
                                    recognitionPhase: 'content-recognition'
                                }
                            });

                            if (!isMeetingActive) {
                                stopListening();
                                return;
                            }

                            if (transcript && confidence > 0.2) {
                                logError(new Error('Processing recognition result'), {
                                    functionName: 'recognizer.recognized',
                                    status: 'info',
                                    details: { 
                                        transcript, 
                                        confidence,
                                        status: jsonResult.RecognitionStatus,
                                        processingPhase: 'sending-to-api'
                                    }
                                });

                                await processRecognitionResult(selectedLang, transcript, confidence);

                                logError(new Error('Recognition result processed'), {
                                    functionName: 'recognizer.recognized',
                                    status: 'success',
                                    details: {
                                        transcript,
                                        confidence,
                                        status: jsonResult.RecognitionStatus
                                    }
                                });
                            }
                        }

                    } catch (error) {
                        logError(error, {
                            functionName: 'recognizer.recognized',
                            status: 'error',
                            details: {
                                phase: 'recognition-processing',
                                rawResult: e.result,
                                error: error.message
                            }
                        });
                    }
                };

                recognizer.recognizing = (s, e) => {
                    if (e.result.text) {
                        logError(new Error('Recognition in progress'), {
                            functionName: 'recognizer.recognizing',
                            status: 'info',
                            details: {
                                interimText: e.result.text,
                                reason: sdk.ResultReason[e.result.reason]
                            }
                        });
                    }
                };

                recognizer.sessionStarted = (s, e) => {
                    logError(new Error('iOS session started'), {
                        functionName: 'recognizer.sessionStarted',
                        status: 'info'
                    });
                };

                recognizer.sessionStopped = (s, e) => {
                    logError(new Error('iOS session stopped'), {
                        functionName: 'recognizer.sessionStopped',
                        status: 'info'
                    });
                };
            } 
            // Web/Desktop için event handler'lar
            else {
                recognizer.recognized = async (s, e) => {
                    if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                        const transcript = e.result.text?.trim();
                        const confidence = e.result.confidence || 0.8;

                        if (!isMeetingActive) {
                            stopListening();
                            return;
                        }

                        if (transcript && confidence > 0.3) {
                            await processRecognitionResult(selectedLang, transcript, confidence);
                        }
                    }
                };
            }

            // Ortak event handler'lar
            recognizer.canceled = (s, e) => {
                if (e.reason === sdk.CancellationReason.Error) {
                    logError(new Error(e.errorDetails), {
                        functionName: 'recognizer.canceled',
                        status: 'error',
                        details: {
                            errorDetails: e.errorDetails,
                            platform: isIOS ? 'iOS' : 'Web/Desktop'
                        }
                    });
                    reject(new Error(`Recognition error: ${e.errorDetails}`));
                }
            };

            // PhraseList yapılandırması
            if (selectedLang.phraseList?.length > 0) {
                const phraseList = sdk.PhraseListGrammar.fromRecognizer(recognizer);
                selectedLang.phraseList.forEach(phrase => phraseList.addPhrase(phrase));
            }

            // Recognizer kaydetme
            recognizers.current[selectedLang.code] = recognizer;

            logError(new Error('Speech recognition initialization complete'), {
                functionName: 'initializeSpeechRecognition',
                status: 'success',
                details: {
                    platform: isIOS ? 'iOS' : 'Web/Desktop',
                    language: selectedLang.code,
                    clientType,
                    frameContext
                }
            });

            resolve();

        } catch (err) {
            logError(err, {
                functionName: 'initializeSpeechRecognition',
                status: 'error',
                details: {
                    clientType,
                    frameContext,
                    isIOS,
                    error: {
                        message: err.message,
                        stack: err.stack
                    }
                }
            });
            reject(err);
        }
    });
}, [selectedLanguage, processRecognitionResult, cleanupRecognizers, isMeetingActive, stopListening]);

  
  // Dinlemeyi başlat
  const startListening = useCallback(async () => {
    
    let clientType = null;

    try {
        // Başlangıç kontrolü
        if (isListening) {
            logError(new Error('Listening already active'), {
                functionName: 'startListening',
                status: 'warning',
                details: { isListening: true }
            });
            return;
        }
        
        // Teams başlatma ve context kontrolü
        await microsoftTeams.app.initialize();
        const context = await microsoftTeams.app.getContext();
        clientType = context?.app?.host?.clientType;
        // const clientType = context?.app?.host?.clientType;
        const isTeamsWeb = clientType === "web";
        const isIOS = clientType === "ios";
        
        logError(new Error('Teams initialization check'), {
            functionName: 'startListening',
            status: 'info',
            details: {
                clientType,
                isTeamsWeb,
                isIOS,
                userAgent: navigator.userAgent,
                frameContext: context?.page?.frameContext
            }
        });

        // Mikrofon izni kontrolü
        const hasMicPermission = await checkMicrophonePermission();
        if (!hasMicPermission) {
            logError(new Error('Microphone permission denied'), {
                functionName: 'startListening',
                status: 'error',
                details: {
                    micPermission: false,
                    clientType,
                    isIOS
                }
            });
            throw new Error('Mikrofon izni alınamadı');
        }

        // Teams client kontrolü
        if (!isTeamsClient) {
            logError(new Error('Not running in Teams client'), {
                functionName: 'startListening',
                status: 'error',
                details: {
                    isTeamsClient: false,
                    environment: window.location.href
                }
            });
            throw new Error('Microsoft Teams dışında çalıştırılamaz');
        }

        // Speech service kontrolü
        if (!speechServiceReady) {
            logError(new Error('Speech service not ready'), {
                functionName: 'startListening',
                status: 'error',
                details: {
                    speechServiceReady: false,
                    serviceRegion: SPEECH_REGION
                }
            });
            throw new Error('Speech servisi hazır değil');
        }

        await stopListening();

        // Meeting ID kontrolü
        if (!context?.meeting?.id) {
            logError(new Error('Meeting ID missing'), {
                functionName: 'startListening',
                status: 'error',
                details: {
                    context: context,
                    meetingInfo: currentMeetingDataRef.current
                }
            });
            throw new Error('Meeting ID bulunamadı');
        }

        const meetingData = {
            id: context.meeting.id,
            name: context.meeting.name || 'Teams Meeting'
        };

        // Toplantı için benzersiz şifreleme anahtarı ve IV oluştur
        //const encryptionKey = generateRandomString(32); // 32 karakter uzunluğunda rastgele anahtar
        //const encryptionIV = generateRandomString(16);  // 16 karakter uzunluğunda rastgele IV


        // Meeting API çağrısı
        try {
            const response = await apiCall('/api/meetings', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Client-Type': isTeamsWeb ? 'teams-web' : 'teams-desktop' 
                },
                body: JSON.stringify({
                    meetingId: meetingData.id,
                    meetingName: meetingData.name,
                    hostUserId: userInfo?.id,
                    hostUserName: userInfo?.displayName,
                    status: 'active',
            //        encryptionKey, // Şifreleme anahtarını gönder
            //        encryptionIV   // IV'yi gönder
                })
            });

            // Yanıtı kontrol et
            console.log('Toplantı API yanıtı:', response);

            if (!response.success) {
                logError(new Error('Meeting API call failed'), {
                    functionName: 'startListening',
                    status: 'error',
                    details: {
                        response,
                        meetingData,
                        userInfo
                    }
                });
                throw new Error('Meeting başlatılamadı');
            }

            // Şifreleme anahtarlarını local state'e kaydet
          //  setEncryptionData({
          //    key: encryptionKey,
          //    iv: encryptionIV
          //  });

            logError(new Error('Meeting API call successful'), {
                functionName: 'startListening',
                status: 'success',
                details: {
                    meetingId: meetingData.id,
                    response: response
                }
            });

        } catch (apiError) {
            logError(apiError, {
                functionName: 'startListening',
                status: 'error',
                details: {
                    phase: 'meeting-api-call',
                    meetingData,
                    userInfo
                }
            });
            throw apiError;
        }

        // State güncellemeleri
        currentMeetingDataRef.current = meetingData;
        isMeetingActiveRef.current = true;
        isTeamsMeetingInitialized.current = true;
        setMeetingInfo(meetingData);
        setIsMeetingActive(true);
        setIsMessagePollingActive(true);

        // Speech recognition başlatma
        try {
            await initializeSpeechRecognition();

            const currentRecognizer = recognizers.current[selectedLanguage];
            if (!currentRecognizer) {
                logError(new Error('Recognizer not found'), {
                    functionName: 'startListening',
                    status: 'error',
                    details: {
                        selectedLanguage,
                        availableRecognizers: Object.keys(recognizers.current)
                    }
                });
                throw new Error('Recognizer bulunamadı');
            }

            await new Promise((resolve, reject) => {
                currentRecognizer.startContinuousRecognitionAsync(
                    () => {
                        logError(new Error('Speech recognition started successfully'), {
                            functionName: 'startListening',
                            status: 'success',
                            details: {
                                language: selectedLanguage,
                                isIOS,
                                clientType
                            }
                        });
                        setIsListening(true);
                        resolve();
                    },
                    (error) => {
                        logError(error, {
                            functionName: 'startListening',
                            status: 'error',
                            details: {
                                phase: 'recognition-start',
                                language: selectedLanguage,
                                isIOS,
                                clientType
                            }
                        });
                        reject(error);
                    }
                );
            });

        } catch (recognitionError) {
            logError(recognitionError, {
                functionName: 'startListening',
                status: 'error',
                details: {
                    phase: 'speech-recognition-initialization',
                    selectedLanguage,
                    isIOS,
                    clientType
                }
            });
            throw recognitionError;
        }

    } catch (err) {
        console.error('Dinleme başlatma hatası:', err);
        setError(err.message);
        
        logError(err, {
            functionName: 'startListening',
            status: 'error',
            details: {
                finalError: true,
                isIOS: clientType === "ios",
                clientType,
                speechServiceReady,
                isTeamsClient,
                micPermission,
                error: {
                    message: err.message,
                    stack: err.stack,
                    name: err.name
                }
            }
        });
        
        await stopListening();
    }
}, [
    isListening,
    isTeamsClient,
    speechServiceReady,
    userInfo,
    selectedLanguage,
    checkMicrophonePermission,
    stopListening,
    initializeSpeechRecognition
]);

  // Rastgele string oluşturma yardımcı fonksiyonu
  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // Teams başlatma
  const initializeTeams = useCallback(async () => {
    if (isTeamsInitialized.current) {
      console.log('Teams zaten başlatılmış, atlanıyor...');
      return true;
    }
  
    try {
      console.log('Teams başlatılıyor...');
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();

      if (!context?.user) {
        throw new Error('Teams user information could not be found');
      }
  
      console.log('Teams kullanıcı bilgileri alındı:', context.user);
      
      const userInfoData = {
        displayName: context.user.displayName || '',
        email: context.user.email,
        id: context.user.id,
        userPrincipalName: context.user.userPrincipalName
      };
  
      setUserInfo(userInfoData);
      setUserName(context.user.userPrincipalName || context.user.displayName);
      isTeamsInitialized.current = true;
  
      // userInfo'nun set edilmesini bekle
      await new Promise(resolve => setTimeout(resolve, 100));
  
      return true;
    } catch (err) {
      console.error('Teams başlatma hatası:', err);
      isTeamsInitialized.current = false;
      return false;
    }
  }, []);

  useEffect(() => {
    const initializeTeams = async () => {
        try {
            // Teams başlatma
            await microsoftTeams.app.initialize();
            console.log('Teams başlatıldı');
            
            // Context kontrolü
            const teamsReady = await checkTeamsContext();
            if (!teamsReady) {
                throw new Error('Teams context alınamadı');
            }

            // Speech service kontrolü
            const isSpeechReady = await checkSpeechService();
            if (!isSpeechReady) {
                throw new Error('Speech service başlatılamadı');
            }

            // Mikrofon izni kontrolü
            const hasMicPermission = await checkMicrophonePermission();
            if (!hasMicPermission) {
                throw new Error('Mikrofon izni alınamadı');
            }

            setIsFullyLoaded(true);
            setIsInitializing(false);

        } catch (err) {
            console.error('Teams başlatma hatası:', err);
            setError(err.message);
            setIsInitializing(false);
        }
    };

    initializeTeams();
}, [checkTeamsContext, checkSpeechService, checkMicrophonePermission]);

// Meeting durumu değişikliklerini izle
useEffect(() => {
  console.log('Meeting durumu:', {
    isActive: isMeetingActive,
    meetingInfo,
    meetingRef: currentMeetingDataRef.current,
    isInitialized: isTeamsMeetingInitialized.current
  });
}, [isMeetingActive, meetingInfo]);

  useEffect(() => {
    console.log('Meeting durumu değişti:', {
      isActive: isMeetingActive,
      meetingInfo,
      meetingRef: currentMeetingDataRef.current
    });
  
    // Meeting aktif değilse ve dinleme devam ediyorsa durdur
    if (!isMeetingActive && isListening) {
      stopListening();
    }
  }, [isMeetingActive, isListening, stopListening, meetingInfo]);

  useEffect(() => {
    return () => {
      if (currentMeetingDataRef.current?.id) {
        stopListening();
      }
    };
  }, [stopListening]);
  
  // cleanupMeeting fonksiyonunu sadece component unmount olduğunda çağır
  useEffect(() => {
    return () => {
      if (isListening) {
        stopListening();
      }
    };
  }, [stopListening, isListening]);

  const handleReportClick = (message) => {
    setSelectedMessage(message);
    setReportModalOpen(true);
  };
  

  const handleReportSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (!reportReason || !reportDescription) {
        setError('Please fill in all fields');
        return;
      }
  
      // Tarihi doğru formatta hazırla
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');
  
      const response = await apiCall('/api/content-reports', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          meetingId: meetingInfo.id,
          userId: userInfo.id,
          userName: userInfo.displayName || userName,
          messageId: selectedMessage.id,
          reason: reportReason,
          description: reportDescription,
          reportedAt: formattedDate // Formatlanmış tarihi kullan
        })
      });
  
      if (response.success) {
        setReportModalOpen(false);
        setReportReason('');
        setReportDescription('');
        
        // Başarılı mesajı göster
        const successAlert = document.createElement('div');
        successAlert.className = 'alert alert-success';
        successAlert.innerHTML = `
          <i class="bi bi-check-circle-fill me-2"></i>
          Report submitted successfully. Thank you for helping us maintain content quality.
        `;
        
        document.querySelector('.messages-container').prepend(successAlert);
        setTimeout(() => successAlert.remove(), 3000);
      } else {
        throw new Error(response.error || 'Failed to submit report');
      }
    } catch (error) {
      console.error('Report submission error:', error);
      setError(`Failed to submit report: ${error.message}`);
    }
  };

  // Meeting temizleme
  // Ana başlatma effect'i
  useEffect(() => {
    let mounted = true;
  
    const initializeApp = async () => {
      setIsInitializing(true);
      console.log('Uygulama başlatılıyor...');
    
      const initTimeout = setTimeout(() => {
        microsoftTeams.app.notifyFailure({
          reason: microsoftTeams.app.FailedReason.Timeout,
          message: "Application initialization timed out"
        });
      }, 24000000);
    
      try {
        await microsoftTeams.app.initialize();
    
        // Teams context'ini al ve client type kontrolü yap
        const context = await microsoftTeams.app.getContext();
        const clientType = context?.app?.host?.clientType;
        const isMobileClient = clientType === "android" || clientType === "bios" || clientType === "mobile";
        
        if (isMobileClient) {
          console.log('Mobile platform tespit edildi:', clientType);
          // Mobile-specific özellikler için state güncelle
          setIsMobileClient(true);
        }
    
        const isSpeechReady = await checkSpeechService();
        if (!isSpeechReady) {
          throw new Error('The speech service could not be started.');
        }
    
        const teamsInitialized = await initializeTeams();
        if (!teamsInitialized) {
          throw new Error('Teams could not be started.');
        }
    
        if (!mounted) return;
    
        console.log('Teams context alındı:', context);
    
        if (!context?.user?.tenant?.id) {
          throw new Error('Teams tenant information could not be retrieved');
        }
    
        const subscriptionId = context.user.id || context.user.licenseType;
        if (!subscriptionId) {
          throw new Error('Teams user information could not be retrieved');
        }
    
        try {
          // Subscription kontrolü
          const subscriptionCheck = await fetch(`${API_BASE_URL}/api/subscriptionCheck`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              subscriptionId,
              tenantId: context.user.tenant.id,
              meetingId: context.meeting?.id
            })
          });
        
          const result = await subscriptionCheck.json();
          console.log('Subscription check result:', result);
          debugSubscriptionStatus(result);
        
          // Limit bilgisini state'e kaydet
          if (result.subscription) {
            setSubscriptionInfo(result.subscription);
            setIsLimitExceeded(!result.canUseMeeting);
          
            // Rapor limiti hesaplaması
            const reportsUsed = parseInt(result.subscription.reportsUsed, 10);
            const reportLimit = parseInt(result.subscription.reportLimit, 10);
            const remainingReports = Math.max(0, reportLimit - reportsUsed); // Negatif değerleri önle
          
            const reportLimitInfo = {
              reportsUsed: reportsUsed,
              reportLimit: reportLimit,
              remainingReports: remainingReports
            };
            
            console.log('Setting initial report info:', reportLimitInfo);
            
            setReportInfo(reportLimitInfo);
            setIsReportLimitExceeded(reportsUsed >= reportLimit);
          }
        
          // Make sure to handle the response based on canUseMeeting property
          if (!result.success) {
            setButtonsDisabled(true);
            setError(result.error || 'Subscription check failed');
          } else if (!result.canUseMeeting) {
            // Explicitly handle package limit case
            setButtonsDisabled(true);
            const limitMessage = `Package limit reached. Your ${result.subscription.packageName} package has 
              ${result.subscription.meetingLimit} meeting rights. 
              ${result.subscription.meetingsUsed} meetings have been used so far.`;
            
            setError(limitMessage);
          } else {
            // Normal case - user can use meetings
            setButtonsDisabled(false);
          }
    
          // Bu noktada subscription kontrolü tamamlandı, diğer işlemlere devam et
          if (context?.meeting?.id) {
            const meetingData = {
              id: context.meeting.id,
              name: context.meeting.name || 'Teams Meeting'
            };
    
            currentMeetingDataRef.current = meetingData;
            setMeetingInfo(meetingData);

            // Toplantı için benzersiz şifreleme anahtarı ve IV oluştur
            const encryptionKey = generateRandomString(32); // 32 karakter uzunluğunda rastgele anahtar
            const encryptionIV = generateRandomString(16);  // 16 karakter uzunluğunda rastgele IV
    
            try {
              await apiCall('/api/meetings', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  meetingId: meetingData.id,
                  meetingName: meetingData.name,
                  hostUserId: userInfo?.id,
                  hostUserName: userInfo?.displayName,
                  status: 'active',
                  encryptionKey, // Şifreleme anahtarını gönder
                  encryptionIV   // IV'yi gönder
                })
              });

              // Şifreleme anahtarlarını local state'e kaydet
              setEncryptionData({
                key: encryptionKey,
                iv: encryptionIV
              });
    
              if (userInfo?.id) {
                await apiCall('/api/user-preferences', {
                  method: 'POST',
                  body: JSON.stringify({
                    meetingId: meetingData.id,
                    userId: userInfo.id,
                    languageId: selectedLanguage
                  })
                });


              }
            } catch (apiError) {
              console.warn('API call warning (non-critical):', apiError);
              // API çağrısı hataları kritik değil, devam et
            }
          }
    
          // mic kontrolü yapılan yer:
          const hasMicPermission = await checkMicrophonePermission();
          if (!hasMicPermission) {
            // Kullanıcının seçili diline göre mesajı göster
            const userLanguage = selectedLanguage || 'tr-TR';
            setError(UI_TEXTS[userLanguage]?.micPermissionAlert || UI_TEXTS['tr-TR'].micPermissionAlert);
          }
    
          // Tüm kritik olmayan işlemler tamamlandı, uygulamayı başarılı olarak işaretle
          clearTimeout(initializationTimeout.current);
          clearTimeout(initTimeout);
          microsoftTeams.app.notifySuccess();
          setIsFullyLoaded(true);
          setIsInitializing(false);
          microsoftTeams.app.notifyAppLoaded();
    
          console.log('Application successfully started');
    
        } catch (subscriptionError) {
          // Subscription check hatasını logla ve göster, ancak uygulamayı yine de başlat
          console.error('Subscription check error:', subscriptionError);
          setError(`Subscription error: ${subscriptionError.message}`);
          
          // Subscription hatası kritik değil, uygulamayı başlatmaya devam et
          clearTimeout(initTimeout);
          microsoftTeams.app.notifySuccess();
          setIsFullyLoaded(true);
          setIsInitializing(false);
          microsoftTeams.app.notifyAppLoaded();
        }
    
      } catch (err) {
        console.error('Initialization error:', {
          error: err,
          stack: err.stack,
          message: err.message,
          type: err.constructor.name,
          location: err.stack?.split('\n')[1]
        });
    
        clearTimeout(initTimeout);
        
        if (initializationTimeout.current) {
          clearTimeout(initializationTimeout.current);
        }
    
        // Teams'e hatayı bildir
        let failureReason = microsoftTeams.app.FailedReason.Other;
        if (err.message.includes('authorization') || err.message.includes('authentication')) {
          failureReason = microsoftTeams.app.FailedReason.AuthFailed;
        } else if (err.message.includes('timeout')) {
          failureReason = microsoftTeams.app.FailedReason.Timeout;
        }
    
        microsoftTeams.app.notifyFailure({
          reason: failureReason,
          message: `Error: ${err.message}\nLine: ${err.stack?.split('\n')[1]}`
        });
    
        if (mounted) {
          await logError(err, {
            functionName: 'initializeApp',
            componentName: 'SpeechToTextComponent',
            severity: 'error',
            additionalData: {
              initializationPhase: true,
              componentState: {
                isListening,
                micPermission,
                isTeamsClient,
                speechServiceReady
              }
            }
          });
          setError(`Initialization error: ${err.message}`);
        }
      } finally {
        if (mounted) {
          setIsInitializing(false);
        }
      }
    };
  
    initializeApp();
  
    return () => {
      mounted = false;
      if (initializationTimeout.current) {
        clearTimeout(initializationTimeout.current);
      }
      if (isListening) {
        stopListening();
      }
    };
  }, [
    checkTeamsContext,
    checkSpeechService,
    initializeTeams,
    checkMicrophonePermission,
    stopListening,
    userInfo,
    selectedLanguage,
    isListening
  ]);

  useEffect(() => {
    console.log("Hata durumu değişti:", error);
  }, [error]);

/*  useEffect(() => {
    window.debugStates = {
      isListening,
      micPermission,
      isTeamsClient,
      speechServiceReady,
      buttonsDisabled,
      error
    };
    
    console.error('States Updated:', window.debugStates);
  }, [isListening, micPermission, isTeamsClient, speechServiceReady, buttonsDisabled, error]);
*/

  // Mesaj gösterme effect'i
  useEffect(() => {
    if (isMeetingActive && userInfo && isMessagePollingActive) {
      displayNextMessage();
      
      messagePollingInterval.current = setInterval(fetchNewMessages, 1000);
      
      return () => {
        if (messagePollingInterval.current) {
          clearInterval(messagePollingInterval.current);
        }
        if (displayTimeoutRef.current) {
          clearTimeout(displayTimeoutRef.current);
        }
      };
    }
  }, [isMeetingActive, userInfo, isMessagePollingActive, displayNextMessage, fetchNewMessages]);



// Rapor oluşturma fonksiyonu - hata toleranslı versiyon
const generateMeetingSummary = async () => {
  // Get the user's selected language or default to Turkish
  const userLanguage = selectedLanguage || 'tr-TR';
  
  // Tüm desteklenen diller için yükleme mesajları
  const loadingMessages = {
    'tr-TR': 'Rapor oluşturuluyor. Bu işlem bir kaç dakika sürebilir, lütfen bekleyin...',
    'en-US': 'Generating report. This may take a few minutes, please wait...',
    'fr-FR': 'Génération du rapport. Cela peut prendre quelques minutes, veuillez patienter...',
    'de-DE': 'Bericht wird erstellt. Dies kann einige Minuten dauern, bitte warten...',
    'es-ES': 'Generando informe. Esto puede tardar unos minutos, por favor espere...',
    'it-IT': 'Generazione del rapporto. Potrebbero essere necessari alcuni minuti, attendere prego...',
    'ru-RU': 'Создание отчета. Это может занять несколько минут, пожалуйста, подождите...',
    'zh-CN': '正在生成报告。这可能需要几分钟，请稍候...',
    'ja-JP': 'レポートを生成しています。これには数分かかる場合があります。お待ちください...',
    'ar-SA': 'جاري إنشاء التقرير. قد يستغرق هذا بضع دقائق، يرجى الانتظار...',
    'pt-PT': 'Gerando relatório. Isso pode levar alguns minutos, por favor aguarde...',
    'el-GR': 'Δημιουργία αναφοράς. Αυτό μπορεί να διαρκέσει μερικά λεπτά, παρακαλώ περιμένετε...',
    'he-IL': 'מייצר דוח. זה עשוי להימשך מספר דקות, אנא המתן...',
    'bg-BG': 'Създаване на отчет. Това може да отнеме няколко минути, моля изчакайте...',
    'cs-CZ': 'Generování zprávy. Může to trvat několik minut, prosím čekejte...',
    'da-DK': 'Genererer rapport. Dette kan tage et par minutter, vent venligst...',
    'fi-FI': 'Raporttia luodaan. Tämä voi kestää muutaman minuutin, odota hetki...',
    'hu-HU': 'Jelentés létrehozása. Ez eltarthat néhány percig, kérjük, várjon...',
    'ko-KR': '보고서 생성 중. 몇 분 정도 걸릴 수 있으니 기다려 주세요...',
    'ms-MY': 'Menjana laporan. Ini mungkin mengambil masa beberapa minit, sila tunggu...',
    'pl-PL': 'Generowanie raportu. Może to potrwać kilka minut, proszę czekać...',
    'ro-RO': 'Generarea raportului. Aceasta poate dura câteva minute, vă rugăm să așteptați...',
    'sv-SE': 'Genererar rapport. Detta kan ta några minuter, vänligen vänta...',
    'th-TH': 'กำลังสร้างรายงาน กรุณารอสักครู่ อาจใช้เวลาสักครู่...',
    'uk-UA': 'Створення звіту. Це може зайняти кілька хвилин, будь ласка, зачекайте...',
    'vi-VN': 'Đang tạo báo cáo. Điều này có thể mất vài phút, vui lòng đợi...'
  };
  
  // Rapor limiti uyarı mesajları
  const reportLimitMessages = {
    'tr-TR': 'Rapor limitiniz dolmuştur. Lütfen paketinizi yükseltin.',
    'en-US': 'Your report limit has been reached. Please upgrade your package.',
    'fr-FR': 'Votre limite de rapport est atteinte. Veuillez mettre à niveau votre forfait.',
    'de-DE': 'Ihr Berichtslimit wurde erreicht. Bitte aktualisieren Sie Ihr Paket.',
    'es-ES': 'Ha alcanzado su límite de informes. Por favor, actualice su paquete.',
    'it-IT': 'Il limite del report è stato raggiunto. Si prega di aggiornare il pacchetto.',
    'ru-RU': 'Достигнут лимит отчетов. Пожалуйста, обновите ваш пакет.',
    'zh-CN': '您已达到报告限制。请升级您的套餐。',
    'ja-JP': 'レポートの上限に達しました。パッケージをアップグレードしてください。',
    'ar-SA': 'لقد وصلت إلى حد التقارير. يرجى ترقية الباقة الخاصة بك.',
    'pt-PT': 'Atingiu o limite de relatórios. Por favor, atualize o seu pacote.',
    'el-GR': 'Έχετε φτάσει το όριο αναφορών. Παρακαλώ αναβαθμίστε το πακέτο σας.',
    'he-IL': 'הגעת למגבלת הדוחות. אנא שדרג את החבילה שלך.',
    'bg-BG': 'Достигнахте лимита на отчетите. Моля, надстройте пакета си.',
    'cs-CZ': 'Dosáhli jste limitu zpráv. Aktualizujte prosím svůj balíček.',
    'da-DK': 'Du har nået din rapportgrænse. Opgrader venligst din pakke.',
    'fi-FI': 'Raporttisi raja on täynnä. Päivitä pakettisi.',
    'hu-HU': 'Elérte a jelentések korlátját. Kérjük, frissítse csomagját.',
    'ko-KR': '보고서 한도에 도달했습니다. 패키지를 업그레이드하세요.',
    'ms-MY': 'Had laporan anda telah mencapai. Sila tingkatkan pakej anda.',
    'pl-PL': 'Osiągnąłeś limit raportów. Ulepsz swój pakiet.',
    'ro-RO': 'Ați atins limita de rapoarte. Vă rugăm să actualizați pachetul.',
    'sv-SE': 'Du har nått din rapportgräns. Vänligen uppgradera ditt paket.',
    'th-TH': 'คุณถึงขีดจำกัดรายงานแล้ว โปรดอัปเกรดแพ็กเกจของคุณ',
    'uk-UA': 'Ви досягли ліміту звітів. Будь ласка, оновіть ваш пакет.',
    'vi-VN': 'Bạn đã đạt đến giới hạn báo cáo. Vui lòng nâng cấp gói của bạn.'
  };

  // Seçilen dil için yükleme mesajını al, yoksa Türkçe mesajı kullan
  const displayLoadingMessage = loadingMessages[userLanguage] || loadingMessages['tr-TR'];
  
  // UI için yükleme göstergesi
  const loadingIndicator = document.createElement('div');
  loadingIndicator.className = 'alert alert-info';
  loadingIndicator.innerHTML = `
    <div class="d-flex align-items-center">
      <div class="spinner-border spinner-border-sm me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span>${displayLoadingMessage}</span>
    </div>
  `;
  
  try {
    setError(''); // Önceki hataları temizle
    
    // Rapor oluşturmadan önce limit kontrolü yap
    if (isReportLimitExceeded) {
      // Hata mesajını doğrudan oluştur
      const limitMessage = (() => {
        switch(userLanguage) {
          case 'tr-TR':
            return 'Rapor limitiniz dolmuştur. Lütfen paketinizi yükseltin.';
          case 'en-US':
            return 'Your report limit has been reached. Please upgrade your package.';
          // Diğer diller için mesajlar...
          default:
            return 'Report limit exceeded. Please upgrade your package.';
        }
      })();

      setError(limitMessage);
      return;
    }
    
    // Mesaj konteynırına yükleme göstergesini ekle
    const messagesContainer = document.querySelector('.messages-container');
    if (messagesContainer) {
      messagesContainer.prepend(loadingIndicator);
    }
    
    // Gerekli verilerin mevcut olduğunu kontrol et
    if (!meetingInfo?.id) {
      throw new Error('Toplantı bilgisi eksik. Lütfen daha sonra tekrar deneyin.');
    }

    const teamsContext = await microsoftTeams.app.getContext();
    const meetingName = teamsContext?.meeting?.name || 'CSC Voice AI - Meeting Report';
    
    // Rapor oluşturma izni varsa, sunucuya bildir (report-usage API)

    try {
      const reportUsageResponse = await apiCall('/api/report-usage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          meetingId: meetingInfo.id,
          tenantId: teamsContext.user.tenant.id
        })
      });
    
      if (!reportUsageResponse.success) {
        throw new Error(reportUsageResponse.error || 'Rapor izni alınamadı');
      }
    
      // Mevcut state'ten yeni değerleri hesapla
      const currentReportsUsed = reportInfo.reportsUsed + 1; // Mevcut kullanımı 1 artır
      const currentReportLimit = reportInfo.reportLimit;
      const currentRemainingReports = Math.max(0, reportInfo.remainingReports - 1); // Mevcut kalan rapordan 1 eksilt
    
      // Debug için log
      console.log('Rapor kullanımı değerleri:', {
        önceki: reportInfo,
        güncellenen: {
          reportsUsed: currentReportsUsed,
          reportLimit: currentReportLimit,
          remainingReports: currentRemainingReports
        }
      });
    
      // State'i güncelle
      setReportInfo({
        reportsUsed: currentReportsUsed,
        reportLimit: currentReportLimit,
        remainingReports: currentRemainingReports
      });
      
      // Limit kontrolü
      const isLimitExceeded = currentRemainingReports <= 0;
      setIsReportLimitExceeded(isLimitExceeded);
    
      // Eğer limit aşıldıysa hata fırlat
      if (isLimitExceeded) {
        throw new Error(reportLimitMessages[userLanguage] || reportLimitMessages['tr-TR']);
      }
    
    } catch (reportLimitError) {
      console.warn('Report usage tracking error:', reportLimitError);
      
      if (reportLimitError.message.includes('limit') || 
          reportLimitError.message.includes('Rapor limiti')) {
        setIsReportLimitExceeded(true);
        throw reportLimitError;
      }
    }
    
    // Kullanıcının seçili dilini al ve varsayılan dil olarak İngilizce kullan
    //const userLanguage = selectedLanguage || 'tr-TR'; // Varsayılan olarak Türkçe
    const translations = TRANSLATIONSREPORT[userLanguage] || TRANSLATIONSREPORT['tr-TR'];

    console.log('Rapor oluşturuluyor:', {
      meetingName,
      language: userLanguage,
      hasTranslations: !!translations,
      meetingId: meetingInfo.id,
      apiUrl: `${API_BASE_URL}/api/MeetingReport?meetingId=${meetingInfo.id}&language=${selectedLanguage}`
    });

    // Önce toplantı verilerini basit bir şekilde almayı deneyelim
    // API aşağıda tam raporu döndüremezse, basit bir rapor oluşturmak için veriler
    const simpleDataResponse = await fetch(`${API_BASE_URL}/api/messages?meetingId=${meetingInfo.id}&userId=${userInfo.id}&lastMessageId=0&getAll=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // Basit verileri kontrol et
    let simpleData = { messages: [] };
    if (simpleDataResponse.ok) {
      try {
        const simpleFetchedData = await simpleDataResponse.text();
        if (simpleFetchedData && simpleFetchedData.trim() !== '') {
          simpleData = JSON.parse(simpleFetchedData);
        }
      } catch (simpleError) {
        console.warn('Simple data fetch error:', simpleError);
      }
    }

    // Tam rapor için API çağrısı
    let reportData = null;
    let apiError = null;
    
    try {
      // Fetch işlemi için zaman aşımı ekle
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 24000000); // 4 dk zaman aşımı
      
      // API çağrısı
      const response = await fetch(
        `${API_BASE_URL}/api/MeetingReport?meetingId=${meetingInfo.id}&language=${selectedLanguage}`,
        { 
          signal: controller.signal,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      
      clearTimeout(timeoutId); // Zaman aşımını temizle
      
      // Ham yanıt durumunu logla
      console.log('API yanıt durumu:', response.status, response.statusText);
      
      // Yanıt durumunu kontrol et
      if (!response.ok) {
        throw new Error(`API hatası: ${response.status} ${response.statusText}`);
      }
      
      // Önce metin olarak yanıtı al ve incele
      const responseText = await response.text();
      console.log('API yanıt metni:', responseText.substring(0, 200) + '...');
      
      // Yanıtın boş olup olmadığını kontrol et
      if (!responseText || responseText.trim() === '') {
        throw new Error('API boş bir yanıt döndürdü');
      }
      
      // JSON'ı ayrıştırmayı dene
      try {
        reportData = JSON.parse(responseText);
        console.log('API verisi başarıyla ayrıştırıldı');
      } catch (jsonError) {
        console.error('JSON ayrıştırma hatası:', jsonError, 'yanıt:', responseText);
        throw new Error(`API'den geçersiz JSON yanıtı: ${jsonError.message}`);
      }

      // API başarı durumunu kontrol et
      if (!reportData.success) {
        throw new Error(reportData.error || 'API işlem hatası');
      }
    } catch (reportError) {
      console.error('Rapor API hatası:', reportError);
      apiError = reportError;
      // Burada hata fırlatmıyoruz - basit raporu kullanacağız
    }

    // Fallback - API hatası varsa basit bir rapor oluştur
    if (!reportData && simpleData.messages && simpleData.messages.length > 0) {
      console.log('Basit rapor oluşturuluyor (API hatası nedeniyle)');
      
      // Mesajlardan basit istatistikler oluştur
      const participants = {};
      let totalWords = 0;
      
      simpleData.messages.forEach(message => {
        if (!participants[message.userName]) {
          participants[message.userName] = {
            user_name: message.userName,
            message_count: 0,
            word_count: 0
          };
        }
        
        // Mesaj sayısını artır
        participants[message.userName].message_count++;
        
        // Kelime sayısını hesapla
        const wordCount = message.translations
          .find(t => t.languageId === selectedLanguage)?.text
          .split(/\s+/).length || 0;
          
        participants[message.userName].word_count += wordCount;
        totalWords += wordCount;
      });
      
      // İlk ve son mesaj saatlerini al
      const firstMessage = simpleData.messages[simpleData.messages.length - 1];
      const lastMessage = simpleData.messages[0];
      
      const startTime = new Date(firstMessage.createdAt);
      const endTime = new Date(lastMessage.createdAt);
      const durationMinutes = Math.round((endTime - startTime) / 60000);
      
      // Basit rapor verisi oluştur
      reportData = {
        success: true,
        data: {
          stats: {
            total_participants: Object.keys(participants).length,
            total_messages: simpleData.messages.length,
            total_words: totalWords,
            duration: durationMinutes
          },
          participants: Object.values(participants),
          summary: userLanguage === 'tr-TR' 
            ? `API'den özet alınamadı. Bu basit istatistiklerle oluşturulan otomatik rapordur. Toplantıda ${Object.keys(participants).length} katılımcı bulunmaktadır ve toplam ${simpleData.messages.length} mesaj paylaşılmıştır.`
            : `Could not get summary from API. This is an automatically generated report with basic statistics. There are ${Object.keys(participants).length} participants in the meeting and a total of ${simpleData.messages.length} messages have been shared.`,
          generatedAt: new Date().toISOString()
        }
      };
    }

    // Rapor verisi yoksa hata fırlat
    if (!reportData || !reportData.data) {
      throw new Error('Rapor verileri alınamadı. Lütfen daha sonra tekrar deneyin.');
    }

    const { data } = reportData;
    console.log('İşlenmiş rapor verisi:', data);

    // HTML rapor şablonu
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="${userLanguage.split('-')[0]}">
    <head>
      <meta charset="UTF-8">
      <title>${meetingName} - ${translations.title}</title>
        <style>
          body { 
            font-family: Arial, sans-serif; 
            margin: 40px; 
            line-height: 1.6;
            color: #333;
            background-color: #f8f9fa;
          }
          .header { 
            text-align: center; 
            margin-bottom: 30px;
            padding: 20px;
            background: #fff;
            border-radius: 8px;
            border-bottom: 3px solid #0078D4;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          }
          .header h1 {
            color: #0078D4;
            margin-bottom: 10px;
          }
          .header .meeting-meta {
            display: flex;
            justify-content: center;
            gap: 20px;
            font-size: 0.9em;
            margin-top: 10px;
          }
          .meeting-meta .meta-item {
            display: flex;
            align-items: center;
            gap: 5px;
          }
          .meeting-meta .meta-item i {
            color: #0078D4;
          }
          .header .error-note {
            color: #dc3545;
            padding: 10px;
            background-color: #f8d7da;
            border-radius: 4px;
            margin-top: 15px;
            font-size: 14px;
            display: ${apiError ? 'block' : 'none'};
          }
          .summary-section {
            margin: 20px 0;
            padding: 20px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          }
          .summary-section h2, 
          .summary-section h3 {
            color: #0078D4;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
            margin-top: 20px;
          }
          .summary-section ul {
            list-style-type: none;
            padding-left: 0;
          }
          .summary-section ul li {
            margin: 10px 0;
            padding-left: 20px;
            position: relative;
          }
          .summary-section ul li:before {
            content: "•";
            color: #0078D4;
            font-weight: bold;
            position: absolute;
            left: 0;
          }
          .stats { 
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 20px;
            margin: 20px 0;
          }
          .stat-box {
            background: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            text-align: center;
          }
          .stat-box h3 {
            color: #0078D4;
            margin-top: 0;
            margin-bottom: 10px;
            font-size: 16px;
          }
          .stat-box p {
            font-size: 24px;
            font-weight: bold;
            margin: 0;
            color: #2c3e50;
          }
          .table-container {
            margin: 20px 0;
            padding: 20px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          }
          table { 
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
          }
          th, td { 
            border: 1px solid #dee2e6;
            padding: 12px;
            text-align: left;
          }
          th { 
            background-color: #f8f9fa;
            font-weight: bold;
            color: #0078D4;
          }
          tr:nth-child(even) {
            background-color: #f8f9fa;
          }
          .footer {
            text-align: center;
            margin-top: 40px;
            padding: 20px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            color: #666;
          }
          @media print {
            body { background: white; }
            .summary-section, .table-container, .stat-box {
              box-shadow: none;
              border: 1px solid #eee;
            }
            .header .error-note {
              display: none;
            }
          }
          .mood-bars {
            padding: 10px 0;
          }
          
          .mood-bar {
            margin: 8px 0;
          }
          
          .progress {
            height: 20px;
            background-color: #f5f5f5;
            border-radius: 4px;
            overflow: hidden;
          }
          
          .progress-bar {
            height: 100%;
            color: white;
            text-align: center;
            line-height: 20px;
            transition: width 0.6s ease;
          }
          
          .reaction-list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin: 10px 0;
          }
          
          .reaction-item {
            background: #f8f9fa;
            padding: 5px 10px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            gap: 5px;
          }
          
          .keyword-cloud {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin: 15px 0;
          }
          
          .keyword-tag {
            background: #e9ecef;
            padding: 4px 12px;
            border-radius: 15px;
            font-size: 0.9em;
          }
    
          .action-items {
            margin: 15px 0;
          }
          
          .action-item {
            background: #f8f9fa;
            border-left: 3px solid #0078D4;
            padding: 10px 15px;
            margin: 10px 0;
            border-radius: 0 4px 4px 0;
          }
          
          .action-item .title {
            font-weight: bold;
            color: #0078D4;
          }
          
          .action-item .owner {
            font-size: 0.9em;
            color: #6c757d;
            margin-top: 5px;
          }
    
          .sentiment-chart {
            width: 100%;
            height: 250px;
            margin: 20px 0;
          }
    
          .engagement-analysis {
            padding: 15px;
            background: #f8f9fa;
            border-radius: 8px;
            margin: 10px 0;
          }
    
          .topics-section ul {
            padding-left: 20px;
          }
    
          .topics-section li {
            margin-bottom: 10px;
          }
    
          .conflict-analysis {
            background: #fff3cd;
            border-left: 3px solid #ffc107;
            padding: 10px 15px;
            margin: 10px 0;
            border-radius: 0 4px 4px 0;
          }
    
          .decisions-section h4 {
            color: #0078D4;
            margin: 15px 0 5px;
          }
    
          .consensus-groups {
            padding: 10px;
            background: #e9ecef;
            border-radius: 4px;
            margin: 10px 0;
          }
        </style>
      </head>
    <body>
      <div class="header">
        <h1>${meetingName}</h1>
        <p>${translations.title}</p>
        <div class="meeting-meta">
          <div class="meta-item">
            <i class="bi bi-calendar"></i>
            <span>${translations.generatedAt}: ${new Date().toLocaleString(userLanguage)}</span>
          </div>
          ${data.industry ? `
            <div class="meta-item">
              <i class="bi bi-building"></i>
              <span>
                ${getIndustrySectorInfo(data.industry, selectedLanguage).icon} 
                ${getIndustrySectorInfo(data.industry, selectedLanguage).name}
              </span>
            </div>
          ` : ''}
          ${data.department ? `
            <div class="meta-item">
              <i class="bi bi-people"></i>
              <span>
                ${getDepartmentInfo(data.department, selectedLanguage).icon} 
                ${getDepartmentInfo(data.department, selectedLanguage).name}
              </span>
            </div>
          ` : ''}
        </div>
        <div class="error-note">
          ${apiError ? (userLanguage === 'tr-TR' ? 
            `Rapor oluşturulurken bir hata oluştu: ${apiError.message}. Bazı detaylar sınırlı olabilir.` :
            `An error occurred while creating the report: ${apiError.message}. Some details may be limited.`) : ''}
        </div>
      </div>
    
      ${data.summary ? `
        <div class="summary-section">
          <h2>${translations.summary}</h2>
          ${data.summary.replace(/### /g, '<h3>').replace(/\n/g, '<br>')}
        </div>
      ` : ''}
    
      <div class="stats">
        <div class="stat-box">
          <h3>${translations.stats.totalParticipants}</h3>
          <p>${data.stats.total_participants || 0}</p>
        </div>
        <div class="stat-box">
          <h3>${translations.stats.totalMessages}</h3>
          <p>${data.stats.total_messages || 0}</p>
        </div>
        <div class="stat-box">
          <h3>${translations.stats.totalWords}</h3>
          <p>${data.stats.total_words || 0}</p>
        </div>
        <div class="stat-box">
          <h3>${translations.stats.duration}</h3>
          <p>${data.stats.duration ? `${data.stats.duration} ${translations.stats.minutes}` : '-'}</p>
        </div>
      </div>
    
      ${data.keywords?.length > 0 ? `
        <div class="summary-section">
          <h2>${selectedLanguage === 'tr-TR' ? 'Anahtar Kelimeler' : 'Keywords'}</h2>
          <div class="keyword-cloud">
            ${data.keywords.map(keyword => `<span class="keyword-tag">${keyword}</span>`).join('')}
          </div>
        </div>
      ` : ''}
    
      ${data.actionItems ? `
        <div class="summary-section">
          <h2>${selectedLanguage === 'tr-TR' ? 'Eylem Maddeleri' : 'Action Items'}</h2>
          <div class="action-items">
            ${data.actionItems.replace(/\n/g, '<br>')}
          </div>
        </div>
      ` : ''}
    
      ${data.topicAnalysis ? `
        <div class="summary-section topics-section">
          <h2>${selectedLanguage === 'tr-TR' ? 'Toplantı Konuları' : 'Meeting Topics'}</h2>
          <div>
            ${data.topicAnalysis.replace(/\n/g, '<br>')}
          </div>
        </div>
      ` : ''}
    
      ${data.topicRelevance ? `
        <div class="summary-section">
          <h2>${selectedLanguage === 'tr-TR' ? 'Konu İlgililiği Analizi' : 'Topic Relevance Analysis'}</h2>
          <div>
            ${data.topicRelevance.replace(/\n/g, '<br>')}
          </div>
        </div>
      ` : ''}
    
      ${data.conflictAnalysis ? `
        <div class="summary-section">
          <h2>${selectedLanguage === 'tr-TR' ? 'Tartışma Analizi' : 'Conflict Analysis'}</h2>
          <div class="conflict-analysis">
            ${data.conflictAnalysis.replace(/\n/g, '<br>')}
          </div>
        </div>
      ` : ''}
    
      ${data.decisionsAndConsensus ? `
        <div class="summary-section decisions-section">
          <h2>${selectedLanguage === 'tr-TR' ? 'Kararlar ve Fikir Birliği' : 'Decisions and Consensus'}</h2>
          <div>
            ${data.decisionsAndConsensus.replace(/\n/g, '<br>')}
          </div>
        </div>
      ` : ''}
    
      <div class="table-container">
        <h2>${translations.participants.title}</h2>
        ${data.participants && data.participants.length > 0 ? `
          <table>
            <thead>
              <tr>
                <th>${translations.participants.columns.name}</th>
                <th>${translations.participants.columns.messageCount}</th>
                <th>${translations.participants.columns.wordCount}</th>
                <th>${translations.participants.columns.participation}</th>
              </tr>
            </thead>
            <tbody>
              ${data.participants.map(p => `
                <tr>
                  <td>${p.user_name}</td>
                  <td>${p.message_count}</td>
                  <td>${p.word_count}</td>
                  <td>${Math.round((p.message_count / (data.stats.total_messages || 1)) * 100)}%</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        ` : `<p>${userLanguage === 'tr-TR' ? 'Katılımcı verisi bulunamadı.' : 'No participant data found.'}</p>`}
      </div>
    
      ${data.participants?.some(p => p.engagement) ? `
        <div class="summary-section">
          <h2>${selectedLanguage === 'tr-TR' ? 'Katılımcı Katılım Analizi' : 'Participant Engagement Analysis'}</h2>
          ${data.participants.filter(p => p.engagement).map(p => `
            <div class="engagement-analysis">
              <h3>${p.user_name}</h3>
              <div>${p.engagement}</div>
            </div>
          `).join('')}
        </div>
      ` : ''}
    
      ${data.participantSentiments ? `
        <div class="summary-section">
          <h2>${selectedLanguage === 'tr-TR' ? 'Katılımcı Duygu Durumları' : 'Participant Sentiment Analysis'}</h2>
          <div class="stats">
            ${Object.entries(data.participantSentiments).map(([name, sentiment]) => `
              <div class="stat-box">
                <h3>${name}</h3>
                <p style="font-size: 18px">
                  ${sentiment.trend === 'pozitif' || sentiment.trend === 'positive' ? '😀' : 
                    sentiment.trend === 'negatif' || sentiment.trend === 'negative' ? '😞' : '😐'}
                  ${sentiment.average}/10
                </p>
                <div style="font-size: 0.9em; margin-top: 10px;">${sentiment.description}</div>
              </div>
            `).join('')}
          </div>
        </div>
      ` : ''}
    
      ${data.mood ? `
        <div class="mood-section summary-section">
          <h2>${translations.mood.title}</h2>
          <div class="stats mood-stats">
            <div class="stat-box">
              <h3>${translations.mood.ambiance}</h3>
              <p>${data.mood.ambiance || (userLanguage === 'tr-TR' ? 'Nötr' : 'Neutral')}</p>
            </div>
            <div class="stat-box">
              <h3>${translations.mood.overall}</h3>
              <p>
                ${translations.mood.positive}: ${data.mood.overall?.positive || 0} |
                ${translations.mood.neutral}: ${data.mood.overall?.neutral || 0} |
                ${translations.mood.negative}: ${data.mood.overall?.negative || 0}
              </p>
            </div>
          </div>
          ${data.mood.reactions?.length > 0 ? `
            <div class="reactions">
              <h3>${translations.mood.reactions}</h3>
              <div class="reaction-list">
                ${Array.from(new Set(data.mood.reactions || [])).join(' ')}
              </div>
            </div>
          ` : ''}
        </div>
      ` : ''}
    
      <div class="footer">
        <p>${translations.footer.generatedBy}</p>
        <p>${new Date().getFullYear()} ${translations.footer.copyright}</p>
      </div>
    </body>
    </html>
    `;

    // Raporu indir
    const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${meetingName.replace(/[^a-z0-9]/gi, '_')}_${translations.title.replace(/\s+/g, '_')}_${new Date().toLocaleDateString(userLanguage)}.html`;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    console.log('Rapor başarıyla oluşturuldu');

    // Başarılı mesajları için çeviriler
    const successMessages = {
      'tr-TR': 'Rapor başarıyla oluşturuldu!',
      'en-US': 'Report successfully created!',
      'fr-FR': 'Rapport créé avec succès!',
      'de-DE': 'Bericht erfolgreich erstellt!',
      'es-ES': '¡Informe creado exitosamente!',
      'it-IT': 'Rapporto creato con successo!',
      'ru-RU': 'Отчет успешно создан!',
      'zh-CN': '报告创建成功！',
      'ja-JP': 'レポートが正常に作成されました！',
      'ar-SA': 'تم إنشاء التقرير بنجاح!',
      'pt-PT': 'Relatório criado com sucesso!',
      'el-GR': 'Η αναφορά δημιουργήθηκε με επιτυχία!',
      'he-IL': 'הדוח נוצר בהצלחה!',
      'bg-BG': 'Отчетът е създаден успешно!',
      'cs-CZ': 'Zpráva byla úspěšně vytvořena!',
      'da-DK': 'Rapport oprettet med succes!',
      'fi-FI': 'Raportti luotu onnistuneesti!',
      'hu-HU': 'A jelentés sikeresen létrehozva!',
      'ko-KR': '보고서가 성공적으로 생성되었습니다!',
      'ms-MY': 'Laporan berjaya dicipta!',
      'pl-PL': 'Raport utworzony pomyślnie!',
      'ro-RO': 'Raport creat cu succes!',
      'sv-SE': 'Rapport skapad framgångsrikt!',
      'th-TH': 'สร้างรายงานสำเร็จแล้ว!',
      'uk-UA': 'Звіт успішно створено!',
      'vi-VN': 'Đã tạo báo cáo thành công!'
    };

    // Seçilen dil için başarı mesajını al, yoksa İngilizce mesajı kullan
    const successMessage = document.createElement('div');
    successMessage.className = 'alert alert-success';
    successMessage.innerHTML = `
      <div class="d-flex align-items-center">
        <i class="bi bi-check-circle-fill me-2"></i>
        <span>${successMessages[userLanguage] || successMessages['tr-TR']}</span>
      </div>
    `;
    
    if (messagesContainer) {
      messagesContainer.replaceChild(successMessage, loadingIndicator);
      setTimeout(() => {
        try { messagesContainer.removeChild(successMessage); } catch (e) {}
      }, 5000);
    }

  } catch (error) {
    console.error('Rapor oluşturma hatası:', {
      error: error.message,
      stack: error.stack,
      name: error.name
    });
    
    // Yükleme göstergesini kaldır
    try {
      const messagesContainer = document.querySelector('.messages-container');
      if (messagesContainer && loadingIndicator.parentNode === messagesContainer) {
        messagesContainer.removeChild(loadingIndicator);
      }
    } catch (e) {}
    
    // Hata mesajları için çeviriler
    const errorMessages = {
      'tr-TR': `Rapor oluşturulamadı: ${error.message}`,
      'en-US': `Failed to create report: ${error.message}`,
      'fr-FR': `Échec de la création du rapport: ${error.message}`,
      'de-DE': `Fehler beim Erstellen des Berichts: ${error.message}`,
      'es-ES': `Error al crear el informe: ${error.message}`,
      'it-IT': `Impossibile creare il rapporto: ${error.message}`,
      'ru-RU': `Не удалось создать отчет: ${error.message}`,
      'zh-CN': `创建报告失败：${error.message}`,
      'ja-JP': `レポートの作成に失敗しました：${error.message}`,
      'ar-SA': `فشل في إنشاء التقرير: ${error.message}`,
      'pt-PT': `Falha ao criar relatório: ${error.message}`,
      'el-GR': `Αποτυχία δημιουργίας αναφοράς: ${error.message}`,
      'he-IL': `כשל ביצירת דוח: ${error.message}`,
      'bg-BG': `Грешка при създаване на отчет: ${error.message}`,
      'cs-CZ': `Chyba při vytváření zprávy: ${error.message}`,
      'da-DK': `Kunne ikke oprette rapport: ${error.message}`,
      'fi-FI': `Raportin luominen epäonnistui: ${error.message}`,
      'hu-HU': `Nem sikerült létrehozni a jelentést: ${error.message}`,
      'ko-KR': `보고서 생성 실패: ${error.message}`,
      'ms-MY': `Gagal membuat laporan: ${error.message}`,
      'pl-PL': `Nie udało się utworzyć raportu: ${error.message}`,
      'ro-RO': `Eroare la crearea raportului: ${error.message}`,
      'sv-SE': `Det gick inte att skapa rapporten: ${error.message}`,
      'th-TH': `ไม่สามารถสร้างรายงาน: ${error.message}`,
      'uk-UA': `Не вдалося створити звіт: ${error.message}`,
      'vi-VN': `Không thể tạo báo cáo: ${error.message}`
    };
    
    // Seçilen dil için hata mesajını al, yoksa İngilizce mesajı kullan
    setError(errorMessages[userLanguage] || errorMessages['en-US']);
    
    // Hata loglama
    try {
      await logError(error, {
        functionName: 'generateMeetingSummary',
        details: {
          meetingId: meetingInfo?.id,
          selectedLanguage
        }
      });
    } catch (logError) {
      console.error('Hata loglanırken hata oluştu:', logError);
    }
  }
};
          
useEffect(() => {
  console.log('ReportInfo state updated:', reportInfo);
  console.log('IsReportLimitExceeded state updated:', isReportLimitExceeded);
}, [reportInfo, isReportLimitExceeded]);



return (
  <div className="container-fluid min-vh-100 px-0" style={{
    backgroundColor: themeColors[theme].background,
    color: themeColors[theme].text
  }}>
    {isFullyLoaded ? (
      <>
        {/* Üst Footer */}
        <div className="fixed-top border-bottom" style={{
          backgroundColor: "#6264A7",
          borderBottom: `1px solid ${themeColors[theme].border}`,
          fontSize: '0.65rem',
          color: '#ffffff'
        }}>
          <div className="text-center py-1">
            <span className="text-white">
              CSC Voice AI - {getUITexts(selectedLanguage).realTimeMeeting}
            </span>
          </div>
        </div>
        
        <nav className="navbar py-1 mt-4" style={{
          backgroundColor: themeColors[theme].navBg,
          borderBottom: `1px solid ${themeColors[theme].border}`,
          boxShadow: `0 1px 2px ${themeColors[theme].shadow}`
        }}>
          <div className="container-fluid px-0">
            {/* Kullanıcı bilgisi */}
            <div className="w-100 mb-1 px-2">
              {userInfo && (
                <div className="d-flex align-items-center justify-content-between" style={{
                  width: '100%'
                }}>
                  <div className="d-flex align-items-center">
                    <div 
                      className="rounded-circle d-flex align-items-center justify-content-center me-1" 
                      style={{
                        width: '24px', 
                        height: '24px', 
                        fontSize: '0.7rem',
                        backgroundColor: "#0275d8",
                        color: '#ffffff'
                      }}
                    >
                      {userInfo.displayName?.charAt(0) || userName?.charAt(0) || "U"}
                    </div>
                    <div style={{ fontSize: '0.75rem' }}>
                      <div className="fw-bold" style={{
                        color: themeColors[theme].text
                      }}>
                        {userInfo.displayName || userName || "Unknown User"}
                      </div>
                    </div>
                  </div>
                  
                  {/* Report button added here */}
                  <button
                    onClick={() => handleReportClick({ id: meetingInfo?.id, userName: userInfo.displayName || userName })}
                    className="btn btn-sm px-2 py-0"
                    title="Report inappropriate content"
                    style={{
                      background: "#dc3545",
                      color: "#ffffff",
                      border: 'none',
                      fontSize: '0.65rem',
                      borderRadius: '3px'
                    }}
                  >
                    {getUITexts(selectedLanguage).reportButton}
                  </button>
                </div>
              )}
            </div>
  
            {/* Dil seçimi (Türkçe) */}
            <div className="w-100 mb-1 px-2">
              <div className="dropdown-select" style={{ maxWidth: '100%' }}>
                <select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  disabled={isListening}
                  className="form-select form-select-sm"
                  style={{ 
                    backgroundColor: themeColors[theme].cardBg,
                    color: themeColors[theme].text,
                    border: `1px solid ${themeColors[theme].border}`,
                    fontSize: '0.8rem',
                    height: 'calc(1.5em + .5rem)',
                    padding: '.15rem .5rem',
                    borderRadius: '4px'
                  }}
                >
                  {SUPPORTED_LANGUAGES.map(lang => (
                    <option key={lang.code} value={lang.code}>
                      {lang.icon} {lang.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
  
            {/* Sektör ve departman yanyana */}
            <div className="d-flex w-100 mb-1 gap-1 px-2">
              <div className="dropdown-select" style={{ flex: 1 }}>
                <select
                  value={selectedIndustry}
                  onChange={handleIndustryChange}
                  className="form-select form-select-sm"
                  style={{ 
                    backgroundColor: themeColors[theme].cardBg,
                    color: themeColors[theme].text,
                    border: `1px solid ${themeColors[theme].border}`,
                    fontSize: '0.8rem',
                    height: 'calc(1.5em + .5rem)',
                    padding: '.15rem .5rem',
                    borderRadius: '4px',
                    width: '100%'
                  }}
                >
                  {sectors.map(sector => (
                    <option key={sector.id} value={sector.id}>
                      {sector.icon} {sector.name}
                    </option>
                  ))}
                </select>
              </div>
  
              <div className="dropdown-select" style={{ flex: 1 }}>
                <select
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                  className="form-select form-select-sm"
                  style={{ 
                    backgroundColor: themeColors[theme].cardBg,
                    color: themeColors[theme].text,
                    border: `1px solid ${themeColors[theme].border}`,
                    fontSize: '0.8rem',
                    height: 'calc(1.5em + .5rem)',
                    padding: '.15rem .5rem',
                    borderRadius: '4px',
                    width: '100%'
                  }}
                >
                  {departments.map(dept => (
                    <option key={dept.id} value={dept.id}>
                      {dept.icon} {dept.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
  
            {/* Listen ve Stop butonlar yanyana */}
            <div className="d-flex w-100 mb-1 gap-1 px-2">
              <button
                onClick={startListening}
                disabled={isListening || !micPermission || !isTeamsClient || !speechServiceReady || buttonsDisabled}
                className="btn btn-primary btn-sm"
                style={{ 
                  backgroundColor: "#6264A7",
                  color: '#ffffff',
                  border: 'none', 
                  fontSize: '0.8rem', 
                  padding: '0.2rem 0.75rem',
                  borderRadius: '4px',
                  flex: 1
                }}
              >
                <i className="bi bi-mic-fill me-1"></i>
                {getUITexts(selectedLanguage).listenButton}
              </button>
              <button
                onClick={stopListening}
                disabled={!isListening || buttonsDisabled}
                className="btn btn-secondary btn-sm"
                style={{ 
                  backgroundColor: "#6c757d",
                  color: '#ffffff',
                  border: 'none', 
                  fontSize: '0.8rem', 
                  padding: '0.2rem 0.75rem',
                  borderRadius: '4px',
                  flex: 1
                }}
              >
                <i className="bi bi-stop-fill me-1"></i>
                {getUITexts(selectedLanguage).stopButton}
              </button>
            </div>

            {/* Ses kontrolü için yeni buton */}
            <div className="w-100 mb-1 px-2">
            <button
              onClick={() => setAudioEnabled(!audioEnabled)}
              className={`btn btn-sm w-100 ${audioEnabled ? 'btn-success' : 'btn-secondary'}`}
              style={{ 
                fontSize: '0.8rem', 
                padding: '0.2rem 0.75rem',
                backgroundColor: audioEnabled ? "#28a745" : "#6c757d"
              }}
            >
              <i className={`bi ${audioEnabled ? 'bi-volume-up-fill' : 'bi-volume-mute-fill'} me-1`}></i>
              {`${getUITexts(selectedLanguage).testFor} = ${
                audioEnabled 
                  ? getUITexts(selectedLanguage).voiceEnabled 
                  : getUITexts(selectedLanguage).voiceDisabled
              }`}
            </button>
              </div>

              {/* TTS durumu için gösterge */}
              {isSpeaking && (
                <div className="position-fixed bottom-0 end-0 m-3">
                  <div className="spinner-grow text-primary" role="status" style={{ width: '1rem', height: '1rem' }}>
                    <span className="visually-hidden">Speaking...</span>
                  </div>
                </div>
              )}
            
            {/* Create Report butonu */}
            <div className="w-100 mb-1 px-2">
            <button
              onClick={generateMeetingSummary}
              disabled={!meetingInfo || buttonsDisabled || isReportLimitExceeded}
              className="btn btn-primary btn-sm w-100"
              style={{ 
                backgroundColor: "#6264A7",
                color: '#ffffff',
                border: 'none', 
                fontSize: '0.8rem', 
                padding: '0.2rem 0.75rem',
                borderRadius: '4px'
              }}
            >
              <i className="bi bi-file-text me-1"></i>
              {getUITexts(selectedLanguage).createReportButton}
            </button>
            </div>

            {/* Toplantı Limiti Gösterimi */}
            <div className="w-100 mb-1 px-2">
              {subscriptionInfo && (
                <div 
                  className={`d-flex justify-content-between align-items-center p-1 ${isLimitExceeded ? 'bg-danger' : 'bg-success'}`} 
                  style={{
                    borderRadius: '4px',
                    color: '#ffffff',
                    fontSize: '0.8rem'
                  }}
                >
                  <span>
                    <i className={`bi ${isLimitExceeded ? 'bi-exclamation-triangle-fill' : 'bi-check-circle-fill'} me-1`}></i>
                    {getUITexts(selectedLanguage).remaining} {getUITexts(selectedLanguage).meetingLimit} : {subscriptionInfo.remainingMeetings < 0 ? 0 : subscriptionInfo.remainingMeetings}
                  </span>
                  <span className="badge bg-light text-dark">
                    {getUITexts(selectedLanguage).packages[subscriptionInfo.packageName.toLowerCase()] || subscriptionInfo.packageName}
                  </span>
                </div>
              )}

              {/* Limit aşılmışsa paket yükseltme linki göster */}
              {isLimitExceeded && subscriptionInfo && (
                <a 
                  href="https://www.cscvoice.ai/packages" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="btn btn-warning btn-sm w-100 mt-1"
                  style={{ 
                    fontSize: '0.75rem',
                    padding: '0.2rem 0.5rem'
                  }}
                >
                  <i className="bi bi-arrow-up-circle-fill me-1"></i>
                  {getUITexts(selectedLanguage).upgradePackage}
                </a>
              )}
            </div>

            {/* Rapor Limiti Gösterimi */}
            <div className="w-100 mb-1 px-2">
              {reportInfo && (
                <div 
                  className={`d-flex justify-content-between align-items-center p-1 ${
                    reportInfo.remainingReports <= 0 ? 'bg-danger' : 'bg-success'
                  }`} 
                  style={{
                    borderRadius: '4px',
                    color: '#ffffff',
                    fontSize: '0.8rem'
                  }}
                >
                  <span>
                    <i className={`bi ${
                      reportInfo.remainingReports <= 0
                        ? 'bi-exclamation-triangle-fill' 
                        : 'bi-check-circle-fill'
                    } me-1`}></i>
                    {getUITexts(selectedLanguage).remaining} {getUITexts(selectedLanguage).reportLimit}: {
                      reportInfo.remainingReports
                    }
                  </span>
                  <span className="badge bg-light text-dark">
                  {getUITexts(selectedLanguage).packages[subscriptionInfo.packageName.toLowerCase()] || subscriptionInfo.packageName}
                  </span>
                </div>
              )}

              {/* Limit aşıldıysa paket yükseltme linki göster */}
              {reportInfo && reportInfo.remainingReports <= 0 && (
                <a 
                  href="https://www.cscvoice.ai/packages" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="btn btn-warning btn-sm w-100 mt-1"
                  style={{ 
                    fontSize: '0.75rem',
                    padding: '0.2rem 0.5rem'
                  }}
                >
                  <i className="bi bi-arrow-up-circle-fill me-1"></i>
                  {getUITexts(selectedLanguage).upgradePackage}
                </a>
              )}
            </div>
          </div>
        </nav>

        {/* Konuşma çeviri bildirimi - navbar'dan hemen sonra */}
        {translatingUser && (
        <div 
          className="translation-status-bar" 
          style={{
            position: 'relative',
            width: '100%',
            backgroundColor: '#dc3545',
            color: '#ffffff',
            padding: '0.3rem 1rem',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            textAlign: 'center',
            transition: 'all 0.3s ease',
            transform: 'translateY(-1px)',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
            zIndex: 1000
          }}
        >
          <div className="d-flex align-items-center justify-content-center gap-2">
              <div className="spinner-grow spinner-grow-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <span>
                {currentSpeaker || translatingUser}{' '}
                {getUITexts(selectedLanguage)?.translatingMessage || 'konuşması çevriliyor...'}
              </span>
            </div>
          </div>
        )}
    
        <div className="container-fluid px-0 py-1">
        {error && (
          <div 
            className={`alert ${error.includes('Package limit reached') ? 'alert-warning' : 'alert-danger'} py-1 px-2 mb-2 mx-2`} 
            role="alert" 
            style={{ 
              fontSize: '0.75rem',
              backgroundColor: error.includes('Package limit reached') ? '#fff3cd' : '#f8d7da',
              borderColor: error.includes('Package limit reached') ? '#ffeeba' : '#f5c6cb',
              color: error.includes('Package limit reached') ? '#856404' : '#721c24',
              boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <i className={`bi ${error.includes('Package limit reached') ? 'bi-exclamation-triangle-fill' : 'bi-exclamation-triangle-fill'} me-1`}></i>
                {error}
              </div>
              
              {error.includes('Package limit reached') && (
                <a 
                  href="https://www.cscvoice.ai/packages" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="btn btn-sm ms-2 py-0 px-1"
                  style={{ 
                    fontSize: '0.7rem',
                    backgroundColor: '#ffc107',
                    color: '#212529',
                    fontWeight: 'bold',
                    border: 'none'
                  }}
                >
                  Upgrade Now
                </a>
              )}
            </div>
          </div>
        )}
    
          <div className="messages-container mb-4 px-2">
          <div className="text-center text-muted mb-1" style={{fontSize: '0.6rem', color: themeColors[theme].muted}}>
            {getUITexts(selectedLanguage).aiGenerated}
          </div>
            
            {displayedMessages.map((message, index) => {
              const colors = ['primary', 'success', 'info', 'warning', 'danger'];
              const colorClass = colors[index % colors.length];
              
              return (
                <div key={message.id} className={`card mb-1 border-${colorClass}`} style={{
                  backgroundColor: themeColors[theme].cardBg,
                  borderColor: themeColors[theme].border,
                  color: themeColors[theme].text,
                  borderRadius: '4px',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
                }}>
                  <div className="card-header py-1 px-1" style={{
                    backgroundColor: themeColors[theme].messageHeader,
                    borderBottom: `1px solid ${themeColors[theme].border}`
                  }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className={`rounded-circle bg-${colorClass} text-white d-flex align-items-center justify-content-center me-2`}
                            style={{width: '20px', height: '20px', fontSize: '0.65rem'}}>
                          {message.userName.charAt(0)}
                        </div>
                        <div style={{fontSize: '0.7rem'}}>
                          <div className="fw-bold">{message.userName}</div>
                          <div className="text-muted" style={{fontSize: '0.6rem', color: themeColors[theme].muted}}>
                            {SUPPORTED_LANGUAGES.find(l => l.code === message.sourceLanguageId)?.icon}
                            {' '}Message Time:
                            {new Date(message.createdAt).toLocaleTimeString('tr-TR', {
                              hour: '2-digit',
                              minute: '2-digit'
                            })}
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => handleReportClick(message)}
                        className="btn btn-link btn-sm text-muted p-0"
                        title="Report inappropriate content"
                        style={{
                          background: 'none',
                          border: 'none',
                          fontSize: '0.7rem'
                        }}
                      >
                        <i className="bi bi-flag"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body py-1 px-2">
                    {message.translations
                      .filter(t => t.languageId === selectedLanguage)
                      .map((translation, index) => (
                        <p key={index} className="mb-0" style={{fontSize: '0.85rem'}}>
                          {translation.text}
                        </p>
                      ))}
                  </div>
                </div>
              );
            })}
    
            {displayedMessages.length === 0 && !isInitializing && (
              <div className="text-center py-3">
              <div className="text-muted mb-1" style={{ fontSize: '1.75rem', color: themeColors[theme].muted }}>
                <i className="bi bi-chat-dots"></i>
              </div>
              <h6 className="mb-1" style={{ fontSize: '0.9rem' }}>{getUITexts(selectedLanguage).noMessages}</h6>
              <p className="text-muted mb-0" style={{ fontSize: '0.75rem', color: themeColors[theme].muted }}>
                {getUITexts(selectedLanguage).messagesWillAppear}
              </p>
            </div>
            )}
  
            <div className="fixed-bottom border-top" style={{
              backgroundColor: "#6264A7",
              borderTop: `1px solid ${themeColors[theme].border}`,
              fontSize: '0.65rem',
              color: '#ffffff'
            }}>
              <div className="text-center py-1">
                <a href="https://www.cscvoice.ai" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-white text-decoration-none">
                  © 2025 CSC Voice AI - www.cscvoice.ai - v1.0.18
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="text-center">
          <div className="spinner-border" role="status" style={{ width: '1.5rem', height: '1.5rem', color: "#0275d8" }}>
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-2" style={{ fontSize: '0.85rem' }}>CSC Voice AI is initializing for Teams Desktop Application...</p>
        </div>
      </div>
    )}
    
    {/* Rapor Modal Penceresi - daha zarif */}
    <div className={`modal ${reportModalOpen ? 'show' : ''}`} style={{display: reportModalOpen ? 'block' : 'none'}}>
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content" style={{
          backgroundColor: themeColors[theme].modalBg,
          color: themeColors[theme].text,
          border: `1px solid ${themeColors[theme].border}`,
          borderRadius: '4px'
        }}>
          <div className="modal-header py-2" style={{
            backgroundColor: themeColors[theme].messageHeader,
            borderBottom: `1px solid ${themeColors[theme].border}`
          }}>
            <h5 className="modal-title" style={{ fontSize: '0.9rem' }}>Report Inappropriate Content</h5>
            <button 
              style={{
                backgroundColor: 'transparent',
                color: themeColors[theme].text,
                border: 'none' 
              }} 
              type="button" 
              className="btn-close" 
              onClick={() => setReportModalOpen(false)}
            ></button>
          </div>
          <form onSubmit={handleReportSubmit}>
            <div className="modal-body py-2">
              <div className="mb-2">
                <label className="form-label" style={{ fontSize: '0.8rem' }}>Reason for Report</label>
                <select
                  style={{
                    backgroundColor: themeColors[theme].cardBg,
                    color: themeColors[theme].text,
                    border: `1px solid ${themeColors[theme].border}`,
                    fontSize: '0.8rem',
                    padding: '0.25rem 0.5rem',
                    height: 'calc(1.5em + .5rem + 2px)'
                  }}
                  value={reportReason}
                  onChange={(e) => setReportReason(e.target.value)}
                  className="form-select form-select-sm" 
                  required
                >
                  <option value="">Select a reason...</option>
                  <option value="inappropriate">Inappropriate Content</option>
                  <option value="offensive">Offensive Language</option>
                  <option value="inaccurate">Inaccurate Translation</option>
                  <option value="hate_speech">Hate Speech</option>
                  <option value="misinformation">Misinformation</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="mb-2">
                <label className="form-label" style={{ fontSize: '0.8rem' }}>Additional Details</label>
                <textarea 
                  value={reportDescription}
                  onChange={(e) => setReportDescription(e.target.value)}
                  className="form-control form-control-sm" 
                  rows="2" 
                  placeholder="Please provide more details about your report..."
                  required
                  style={{
                    backgroundColor: themeColors[theme].cardBg,
                    color: themeColors[theme].text,
                    border: `1px solid ${themeColors[theme].border}`,
                    fontSize: '0.8rem'
                  }}
                ></textarea>
              </div>
              <div className="text-muted" style={{ fontSize: '0.7rem', color: themeColors[theme].muted }}>
                <i className="bi bi-info-circle me-1"></i>
                Your report will be reviewed by our team and appropriate action will be taken.
              </div>
            </div>
            <div className="modal-footer py-1">
            <button 
              style={{
                backgroundColor: "#6c757d",
                color: '#ffffff',
                border: 'none',
                fontSize: '0.75rem',
                padding: '0.25rem 0.5rem'
              }}
              type="button" 
              className="btn btn-sm btn-secondary" 
              onClick={() => {
                setReportModalOpen(false);
                setSelectedMessage(null);
                setReportReason('');
                setReportDescription('');
              }}
            >
              {getUITexts(selectedLanguage).cancelButton}
            </button>
            <button 
              style={{
                backgroundColor: "#0275d8",
                color: '#ffffff',
                border: 'none',
                fontSize: '0.75rem',
                padding: '0.25rem 0.5rem'
              }} 
              type="submit" 
              className="btn btn-sm btn-primary"
            >
              {getUITexts(selectedLanguage).submitReportButton}
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    {reportModalOpen && <div className="modal-backdrop show"></div>}
  </div>
);
}
export default SpeechToTextComponent;